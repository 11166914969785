// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../../utils/Utils.bs.js";
import * as MaterialUi_Typography from "@jsiebern/bs-material-ui/src/MaterialUi_Typography.bs.js";
import * as Spinner$ReactTemplate from "../Spinner.bs.js";

function SearchResultContainer(Props) {
  var result = Props.result;
  var renderData = Props.renderData;
  var match = result[/* data */0];
  if (match !== undefined) {
    return Curry._1(renderData, Caml_option.valFromOption(match));
  } else if (result[/* loading */1]) {
    return React.createElement(Spinner$ReactTemplate.make, { });
  } else if (result[/* error */2] !== undefined) {
    return React.createElement("div", undefined, Caml_option.valFromOption(result[/* error */2]).message);
  } else {
    return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Not asked", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
  }
}

function SearchResultContainer$LoadMoreButton(Props) {
  var nextOffset = Props.nextOffset;
  var total = Props.total;
  var isFetching = Props.isFetching;
  var onClick = Props.onClick;
  var theme = Styles.useTheme();
  var hasMore = Caml_obj.caml_lessthan(nextOffset, total);
  if (hasMore) {
    return React.createElement(Core.Button, MaterialUi_Button.makeProps("Fetch more", Css.style(/* :: */[
                        Css.marginTop(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                        /* :: */[
                          Css.display(/* block */888960333),
                          /* :: */[
                            Css.margin2(Css.zero, Css.auto),
                            /* [] */0
                          ]
                        ]
                      ]), /* Secondary */67972948, undefined, isFetching, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Contained */-515484397, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onClick, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
  } else {
    return null;
  }
}

var LoadMoreButton = /* module */[/* make */SearchResultContainer$LoadMoreButton];

var make = SearchResultContainer;

export {
  make ,
  LoadMoreButton ,
  
}
/* Css Not a pure module */
