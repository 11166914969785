// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as ReactApollo from "react-apollo";
import * as Jss$ReactTemplate from "./bindings/Jss.bs.js";
import * as Root$ReactTemplate from "./Root.bs.js";
import * as ReactHooks from "@apollo/react-hooks";
import * as AppCore$ReactTemplate from "./models/AppCore.bs.js";
import * as Graphql$ReactTemplate from "./graphql/Graphql.bs.js";
import * as ReactNetlifyIdentity from "react-netlify-identity";
import * as ThemeProvider$ReactTemplate from "./ThemeProvider.bs.js";

((require('./styles.css')));

var apolloClient = Graphql$ReactTemplate.makeApolloClient(/* () */0);

ReactDOMRe.renderToElementWithId(React.createElement(AppCore$ReactTemplate.ElmishProvider[/* make */0], {
          children: React.createElement(ReactApollo.ApolloProvider, {
                client: apolloClient,
                children: React.createElement(ReactHooks.ApolloProvider, {
                      client: apolloClient,
                      children: React.createElement(ReactNetlifyIdentity.IdentityContextProvider, {
                            url: "https://podcasts.margareta.dev/",
                            children: React.createElement(ThemeProvider$ReactTemplate.make, {
                                  children: React.createElement(Jss$ReactTemplate.JssProvider[/* make */3], {
                                        children: React.createElement(Root$ReactTemplate.make, { })
                                      })
                                })
                          })
                    })
              })
        }), "root");

export {
  apolloClient ,
  
}
/*  Not a pure module */
