// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function init(param) {
  return /* record */[
          /* items : array */[],
          /* lastId */0
        ];
}

function remove(id, notifications) {
  var items = Belt_Array.keep(notifications[/* items */0], (function (item) {
          return item[/* id */0] !== id;
        }));
  return /* record */[
          /* items */items,
          /* lastId */notifications[/* lastId */1]
        ];
}

function add(data, notifications) {
  var nextId = notifications[/* lastId */1] + 1 | 0;
  var items = Belt_Array.concat(notifications[/* items */0], /* array */[/* record */[
          /* id */nextId,
          /* data */data
        ]]);
  var numberOfShownItems = items.length;
  var match = numberOfShownItems > 3;
  var itemsToShow = match ? Belt_Array.slice(items, numberOfShownItems - 3 | 0, 3) : items;
  return /* record */[
          /* items */itemsToShow,
          /* lastId */nextId
        ];
}

var maxNumberOfNotifications = 3;

export {
  maxNumberOfNotifications ,
  init ,
  remove ,
  add ,
  
}
/* No side effect */
