// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as AppCore$ReactTemplate from "../../models/AppCore.bs.js";
import * as Query$ReasonApolloHooks from "reason-apollo-hooks/src/Query.bs.js";
import * as Selectors$ReactTemplate from "../../models/Selectors.bs.js";
import * as EpisodeCard$ReactTemplate from "../EpisodeCard.bs.js";
import * as LibraryGrid$ReactTemplate from "../LibraryGrid.bs.js";
import * as PodcastCard$ReactTemplate from "../PodcastCard.bs.js";
import * as SearchGraphql$ReactTemplate from "../../graphql/SearchGraphql.bs.js";
import * as SearchResultContainer$ReactTemplate from "./SearchResultContainer.bs.js";

var SearchQuery = Query$ReasonApolloHooks.Make([
      SearchGraphql$ReactTemplate.Search[1],
      SearchGraphql$ReactTemplate.Search[2]
    ]);

function SearchResult(Props) {
  var match = Props.savedEpisodeIds;
  var savedEpisodeIds = match !== undefined ? match : /* array */[];
  var match$1 = Props.savedPodcastIds;
  var savedPodcastIds = match$1 !== undefined ? match$1 : /* array */[];
  var searchModel = Curry._1(AppCore$ReactTemplate.useSelector, Selectors$ReactTemplate.getSearchModel);
  var getSearchVariables = function ($staropt$star, param) {
    var nextOffset = $staropt$star !== undefined ? $staropt$star : 0;
    return SearchGraphql$ReactTemplate.makeSearchQuery(searchModel, nextOffset).variables;
  };
  var match$2 = Curry._8(SearchQuery[/* use */0], Caml_option.some(getSearchVariables(undefined, /* () */0)), undefined, true, undefined, undefined, undefined, undefined, /* () */0);
  var full = match$2[1];
  return React.createElement(SearchResultContainer$ReactTemplate.make, {
              result: full,
              renderData: (function (data) {
                  return React.createElement(React.Fragment, undefined, React.createElement(LibraryGrid$ReactTemplate.make, {
                                  children: Belt_Array.map(data.search[/* results */3], (function (result) {
                                          if (result[0] >= 1051488155) {
                                            var episode = result[1];
                                            return React.createElement(EpisodeCard$ReactTemplate.make, {
                                                        episode: episode,
                                                        isSaved: Belt_Array.some(savedEpisodeIds, (function (idObj) {
                                                                return idObj.episodeId === episode[/* id */0];
                                                              })),
                                                        key: episode[/* id */0]
                                                      });
                                          } else {
                                            var podcast = result[1];
                                            return React.createElement(PodcastCard$ReactTemplate.make, {
                                                        podcast: podcast,
                                                        isSaved: Belt_Array.some(savedPodcastIds, (function (idObj) {
                                                                return idObj.podcastId === podcast[/* id */0];
                                                              })),
                                                        key: podcast[/* id */0]
                                                      });
                                          }
                                        }))
                                }), React.createElement(SearchResultContainer$ReactTemplate.LoadMoreButton[/* make */0], {
                                  nextOffset: data.search[/* nextOffset */0],
                                  total: data.search[/* total */1],
                                  isFetching: full[/* networkStatus */5] === /* FetchMore */2,
                                  onClick: (function (param) {
                                      var nextOffset = data.search[/* nextOffset */0];
                                      Curry._3(full[/* fetchMore */4], Caml_option.some(getSearchVariables(nextOffset, /* () */0)), SearchGraphql$ReactTemplate.fetchMoreUpdateQuery, /* () */0);
                                      return /* () */0;
                                    })
                                }));
                })
            });
}

var make = SearchResult;

export {
  SearchQuery ,
  make ,
  
}
/* SearchQuery Not a pure module */
