// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";

function PageContainer(Props) {
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginTop(Css.px(Utils$ReactTemplate.spacingPx(4, theme))),
                    /* :: */[
                      Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                            Css.maxWidth(Css.px(800)),
                            /* :: */[
                              Css.marginLeft(Css.auto),
                              /* :: */[
                                Css.marginRight(Css.auto),
                                /* [] */0
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ])
            }, children);
}

var make = PageContainer;

export {
  make ,
  
}
/* Css Not a pure module */
