// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as Query$ReasonApolloHooks from "reason-apollo-hooks/src/Query.bs.js";
import * as Selectors$ReactTemplate from "../models/Selectors.bs.js";
import * as SearchForm$ReactTemplate from "../components/search/SearchForm.bs.js";
import * as SearchResult$ReactTemplate from "../components/search/SearchResult.bs.js";
import * as PageContainer$ReactTemplate from "../components/PageContainer.bs.js";
import * as LibraryQueries$ReactTemplate from "../graphql/LibraryQueries.bs.js";

var GetSavedIdsQuery = Query$ReasonApolloHooks.Make([
      LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[1],
      LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[2]
    ]);

function SearchPage$LoggedInSearchView(Props) {
  var userId = Props.userId;
  var match = Curry._8(GetSavedIdsQuery[/* use */0], Caml_option.some(LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[/* make */3](userId, /* () */0).variables), undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
  var match$1 = match[1][/* data */0];
  if (match$1 !== undefined) {
    var data = Caml_option.valFromOption(match$1);
    var savedEpisodeIds = data.my_episodes;
    var savedPodcastIds = data.my_podcasts;
    return React.createElement(SearchResult$ReactTemplate.make, {
                savedEpisodeIds: savedEpisodeIds,
                savedPodcastIds: savedPodcastIds
              });
  } else {
    return React.createElement(SearchResult$ReactTemplate.make, { });
  }
}

var LoggedInSearchView = /* module */[/* make */SearchPage$LoggedInSearchView];

var make = React.memo((function (Props) {
        var userId = Props.userId;
        var hasSearchTerm = Curry._1(AppCore$ReactTemplate.useSelector, Selectors$ReactTemplate.hasSearchTerm);
        return React.createElement(PageContainer$ReactTemplate.make, {
                    children: null
                  }, React.createElement(SearchForm$ReactTemplate.make, { }), hasSearchTerm ? (
                      userId !== undefined ? React.createElement(SearchPage$LoggedInSearchView, {
                              userId: userId
                            }) : React.createElement(SearchResult$ReactTemplate.make, { })
                    ) : null);
      }));

export {
  GetSavedIdsQuery ,
  LoggedInSearchView ,
  make ,
  
}
/* GetSavedIdsQuery Not a pure module */
