// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as Graphql$ReactTemplate from "./Graphql.bs.js";
import * as EpisodeStatus$ReactTemplate from "../types/EpisodeStatus.bs.js";

var ppx_printed_query = "mutation ($title: String!, $pubDate: String!, $podcastTitle: String!, $podcastDescription: String!, $podcastImage: String!, $publisher: String!, $podcastItunesId: String!, $podcastId: String!, $id: String!, $lengthSec: Int!, $itunesId: String, $genreIds: String!, $description: String!, $status: String!, $userId: String!, $tags: String!)  {\ninsert_episodes(objects: {title: $title, pubDate: $pubDate, id: $id, lengthSec: $lengthSec, itunesId: $itunesId, description: $description, podcast: {data: {id: $podcastId, title: $podcastTitle, description: $podcastDescription, publisher: $publisher, itunesId: $podcastItunesId, genreIds: $genreIds, image: $podcastImage}, on_conflict: {constraint: podcasts_pkey, update_columns: [genreIds]}}, myEpisodes: {data: {tags: $tags, status: $status, userId: $userId}, on_conflict: {constraint: my_episodes_pkey, update_columns: []}}}, on_conflict: {constraint: episodes_listennotes_id_key, update_columns: [title, description, itunesId, pubDate]})  {\nreturning  {\nmyEpisodes  {\nepisodeId  \n}\n\n}\n\n}\n\n}\n";

function parse(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "insert_episodes");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeNull(value$1);
      if (match$2 !== undefined) {
        tmp = undefined;
      } else {
        var match$3 = Js_json.decodeObject(value$1);
        var tmp$1;
        if (match$3 !== undefined) {
          var match$4 = Js_dict.get(Caml_option.valFromOption(match$3), "returning");
          var tmp$2;
          if (match$4 !== undefined) {
            var value$2 = Caml_option.valFromOption(match$4);
            var match$5 = Js_json.decodeArray(value$2);
            tmp$2 = match$5 !== undefined ? match$5.map((function (value) {
                      var match = Js_json.decodeObject(value);
                      if (match !== undefined) {
                        var match$1 = Js_dict.get(Caml_option.valFromOption(match), "myEpisodes");
                        var tmp;
                        if (match$1 !== undefined) {
                          var value$1 = Caml_option.valFromOption(match$1);
                          var match$2 = Js_json.decodeArray(value$1);
                          tmp = match$2 !== undefined ? match$2.map((function (value) {
                                    var match = Js_json.decodeObject(value);
                                    if (match !== undefined) {
                                      var match$1 = Js_dict.get(Caml_option.valFromOption(match), "episodeId");
                                      var tmp;
                                      if (match$1 !== undefined) {
                                        var value$1 = Caml_option.valFromOption(match$1);
                                        var match$2 = Js_json.decodeString(value$1);
                                        tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
                                      } else {
                                        tmp = Js_exn.raiseError("graphql_ppx: Field episodeId on type my_episodes is missing");
                                      }
                                      return {
                                              episodeId: tmp
                                            };
                                    } else {
                                      return Js_exn.raiseError("graphql_ppx: Object is not a value");
                                    }
                                  })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$1));
                        } else {
                          tmp = Js_exn.raiseError("graphql_ppx: Field myEpisodes on type episodes is missing");
                        }
                        return {
                                myEpisodes: tmp
                              };
                      } else {
                        return Js_exn.raiseError("graphql_ppx: Object is not a value");
                      }
                    })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$2));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field returning on type episodes_mutation_response is missing");
          }
          tmp$1 = {
            returning: tmp$2
          };
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Object is not a value");
        }
        tmp = Caml_option.some(tmp$1);
      }
    } else {
      tmp = undefined;
    }
    return {
            insert_episodes: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make(title, pubDate, podcastTitle, podcastDescription, podcastImage, publisher, podcastItunesId, podcastId, id, lengthSec, itunesId, genreIds, description, status, userId, tags, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "title",
                  title
                ],
                /* tuple */[
                  "pubDate",
                  pubDate
                ],
                /* tuple */[
                  "podcastTitle",
                  podcastTitle
                ],
                /* tuple */[
                  "podcastDescription",
                  podcastDescription
                ],
                /* tuple */[
                  "podcastImage",
                  podcastImage
                ],
                /* tuple */[
                  "publisher",
                  publisher
                ],
                /* tuple */[
                  "podcastItunesId",
                  podcastItunesId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ],
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "lengthSec",
                  lengthSec
                ],
                /* tuple */[
                  "itunesId",
                  itunesId !== undefined ? itunesId : null
                ],
                /* tuple */[
                  "genreIds",
                  genreIds
                ],
                /* tuple */[
                  "description",
                  description
                ],
                /* tuple */[
                  "status",
                  status
                ],
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "tags",
                  tags
                ]
              ]),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var title = variables.title;
  var pubDate = variables.pubDate;
  var podcastTitle = variables.podcastTitle;
  var podcastDescription = variables.podcastDescription;
  var podcastImage = variables.podcastImage;
  var publisher = variables.publisher;
  var podcastItunesId = variables.podcastItunesId;
  var podcastId = variables.podcastId;
  var id = variables.id;
  var lengthSec = variables.lengthSec;
  var itunesId = variables.itunesId;
  var genreIds = variables.genreIds;
  var description = variables.description;
  var status = variables.status;
  var userId = variables.userId;
  var tags = variables.tags;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "title",
                  title
                ],
                /* tuple */[
                  "pubDate",
                  pubDate
                ],
                /* tuple */[
                  "podcastTitle",
                  podcastTitle
                ],
                /* tuple */[
                  "podcastDescription",
                  podcastDescription
                ],
                /* tuple */[
                  "podcastImage",
                  podcastImage
                ],
                /* tuple */[
                  "publisher",
                  publisher
                ],
                /* tuple */[
                  "podcastItunesId",
                  podcastItunesId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ],
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "lengthSec",
                  lengthSec
                ],
                /* tuple */[
                  "itunesId",
                  itunesId !== undefined ? itunesId : null
                ],
                /* tuple */[
                  "genreIds",
                  genreIds
                ],
                /* tuple */[
                  "description",
                  description
                ],
                /* tuple */[
                  "status",
                  status
                ],
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "tags",
                  tags
                ]
              ]),
          parse: parse
        };
}

function ret_type(f) {
  return /* module */[];
}

var MT_Ret = /* module */[];

var SaveEpisode = /* module */[
  /* ppx_printed_query */ppx_printed_query,
  /* query */ppx_printed_query,
  /* parse */parse,
  /* make */make,
  /* makeWithVariables */makeWithVariables,
  /* ret_type */ret_type,
  /* MT_Ret */MT_Ret
];

function makeSaveEpisodeMutation(userId, episode, myEpisode, episodeInfo) {
  return make(episode[/* title */1], episode[/* pubDate */4], episode[/* podcastTitle */3], episodeInfo[/* podcastDescription */1], episodeInfo[/* podcastImage */2], episode[/* publisher */9], String(episode[/* podcastItunesId */6]), episode[/* podcastId */7], episode[/* id */0], episode[/* lengthSec */5], episodeInfo[/* itunesId */0], Utils$ReactTemplate.toApiGenres(episode[/* genreIds */8]), episode[/* description */2], EpisodeStatus$ReactTemplate.encode(myEpisode[/* status */0]), userId, myEpisode[/* tags */1], /* () */0);
}

var ppx_printed_query$1 = "query ($podcastItunesId: String!, $podcastId: String!, $episodeName: String!)  {\nitunesEpisode(podcastId: $podcastItunesId, episodeName: $episodeName)  {\nid  \n}\n\ngetPodcastById(podcastId: $podcastId)  {\ndescription  \nimage  \n}\n\n}\n";

function parse$1(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var value$1 = Caml_option.valFromOption(match);
    var match$1 = Js_dict.get(value$1, "itunesEpisode");
    var tmp;
    if (match$1 !== undefined) {
      var match$2 = Js_json.decodeObject(Caml_option.valFromOption(match$1));
      if (match$2 !== undefined) {
        var match$3 = Js_dict.get(Caml_option.valFromOption(match$2), "id");
        var tmp$1;
        if (match$3 !== undefined) {
          var value$2 = Caml_option.valFromOption(match$3);
          var match$4 = Js_json.decodeNull(value$2);
          if (match$4 !== undefined) {
            tmp$1 = undefined;
          } else {
            var match$5 = Js_json.decodeString(value$2);
            tmp$1 = match$5 !== undefined ? match$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
          }
        } else {
          tmp$1 = undefined;
        }
        tmp = {
          id: tmp$1
        };
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Object is not a value");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field itunesEpisode on type query_root is missing");
    }
    var match$6 = Js_dict.get(value$1, "getPodcastById");
    var tmp$2;
    if (match$6 !== undefined) {
      var value$3 = Caml_option.valFromOption(match$6);
      var match$7 = Js_json.decodeNull(value$3);
      if (match$7 !== undefined) {
        tmp$2 = undefined;
      } else {
        var match$8 = Js_json.decodeObject(value$3);
        var tmp$3;
        if (match$8 !== undefined) {
          var value$4 = Caml_option.valFromOption(match$8);
          var match$9 = Js_dict.get(value$4, "description");
          var tmp$4;
          if (match$9 !== undefined) {
            var value$5 = Caml_option.valFromOption(match$9);
            var match$10 = Js_json.decodeString(value$5);
            tmp$4 = match$10 !== undefined ? match$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
          } else {
            tmp$4 = Js_exn.raiseError("graphql_ppx: Field description on type Podcast is missing");
          }
          var match$11 = Js_dict.get(value$4, "image");
          var tmp$5;
          if (match$11 !== undefined) {
            var value$6 = Caml_option.valFromOption(match$11);
            var match$12 = Js_json.decodeString(value$6);
            tmp$5 = match$12 !== undefined ? match$12 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
          } else {
            tmp$5 = Js_exn.raiseError("graphql_ppx: Field image on type Podcast is missing");
          }
          tmp$3 = {
            description: tmp$4,
            image: tmp$5
          };
        } else {
          tmp$3 = Js_exn.raiseError("graphql_ppx: Object is not a value");
        }
        tmp$2 = Caml_option.some(tmp$3);
      }
    } else {
      tmp$2 = undefined;
    }
    return {
            itunesEpisode: tmp,
            getPodcastById: tmp$2
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$1(podcastItunesId, podcastId, episodeName, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "podcastItunesId",
                  podcastItunesId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ],
                /* tuple */[
                  "episodeName",
                  episodeName
                ]
              ]),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var podcastItunesId = variables.podcastItunesId;
  var podcastId = variables.podcastId;
  var episodeName = variables.episodeName;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "podcastItunesId",
                  podcastItunesId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ],
                /* tuple */[
                  "episodeName",
                  episodeName
                ]
              ]),
          parse: parse$1
        };
}

function ret_type$1(f) {
  return /* module */[];
}

var MT_Ret$1 = /* module */[];

var GetEpisodeInsertInfo = /* module */[
  /* ppx_printed_query */ppx_printed_query$1,
  /* query */ppx_printed_query$1,
  /* parse */parse$1,
  /* make */make$1,
  /* makeWithVariables */makeWithVariables$1,
  /* ret_type */ret_type$1,
  /* MT_Ret */MT_Ret$1
];

function getEpisodeInsertInfo(episode) {
  return Graphql$ReactTemplate.sendQuery(make$1(String(episode[/* podcastItunesId */6]), episode[/* podcastId */7], episode[/* title */1], /* () */0)).then((function (response) {
                  var itunesId = response.itunesEpisode.id;
                  var podcastDescription = Belt_Option.mapWithDefault(response.getPodcastById, "", (function (info) {
                          return info.description;
                        }));
                  var podcastImage = Belt_Option.mapWithDefault(response.getPodcastById, "", (function (info) {
                          return info.image;
                        }));
                  return Promise.resolve(/* record */[
                              /* itunesId */itunesId,
                              /* podcastDescription */podcastDescription,
                              /* podcastImage */podcastImage
                            ]);
                })).catch((function (param) {
                return Promise.resolve(/* record */[
                            /* itunesId */undefined,
                            /* podcastDescription */"",
                            /* podcastImage */""
                          ]);
              }));
}

var ppx_printed_query$2 = "mutation ($userId: String!, $episodeId: String!)  {\ndelete_my_episodes(where: {userId: {_eq: $userId}, episodeId: {_eq: $episodeId}})  {\nreturning  {\nepisodeId  \n}\n\n}\n\n}\n";

function parse$2(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "delete_my_episodes");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeNull(value$1);
      if (match$2 !== undefined) {
        tmp = undefined;
      } else {
        var match$3 = Js_json.decodeObject(value$1);
        var tmp$1;
        if (match$3 !== undefined) {
          var match$4 = Js_dict.get(Caml_option.valFromOption(match$3), "returning");
          var tmp$2;
          if (match$4 !== undefined) {
            var value$2 = Caml_option.valFromOption(match$4);
            var match$5 = Js_json.decodeArray(value$2);
            tmp$2 = match$5 !== undefined ? match$5.map((function (value) {
                      var match = Js_json.decodeObject(value);
                      if (match !== undefined) {
                        var match$1 = Js_dict.get(Caml_option.valFromOption(match), "episodeId");
                        var tmp;
                        if (match$1 !== undefined) {
                          var value$1 = Caml_option.valFromOption(match$1);
                          var match$2 = Js_json.decodeString(value$1);
                          tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
                        } else {
                          tmp = Js_exn.raiseError("graphql_ppx: Field episodeId on type my_episodes is missing");
                        }
                        return {
                                episodeId: tmp
                              };
                      } else {
                        return Js_exn.raiseError("graphql_ppx: Object is not a value");
                      }
                    })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$2));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field returning on type my_episodes_mutation_response is missing");
          }
          tmp$1 = {
            returning: tmp$2
          };
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Object is not a value");
        }
        tmp = Caml_option.some(tmp$1);
      }
    } else {
      tmp = undefined;
    }
    return {
            delete_my_episodes: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$2(userId, episodeId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "episodeId",
                  episodeId
                ]
              ]),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var userId = variables.userId;
  var episodeId = variables.episodeId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "episodeId",
                  episodeId
                ]
              ]),
          parse: parse$2
        };
}

function ret_type$2(f) {
  return /* module */[];
}

var MT_Ret$2 = /* module */[];

var RemoveEpisode = /* module */[
  /* ppx_printed_query */ppx_printed_query$2,
  /* query */ppx_printed_query$2,
  /* parse */parse$2,
  /* make */make$2,
  /* makeWithVariables */makeWithVariables$2,
  /* ret_type */ret_type$2,
  /* MT_Ret */MT_Ret$2
];

var ppx_printed_query$3 = "mutation ($userId: String!, $tags: String!, $image: String!, $id: String!, $title: String!, $description: String!, $publisher: String!, $itunesId: String!, $genreIds: String!)  {\ninsert_my_podcasts(objects: {userId: $userId, tags: $tags, podcast: {data: {description: $description, genreIds: $genreIds, image: $image, itunesId: $itunesId, title: $title, publisher: $publisher, id: $id}, on_conflict: {constraint: podcasts_pkey, update_columns: [itunesId]}}})  {\nreturning  {\npodcastId  \n}\n\n}\n\n}\n";

function parse$3(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "insert_my_podcasts");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeNull(value$1);
      if (match$2 !== undefined) {
        tmp = undefined;
      } else {
        var match$3 = Js_json.decodeObject(value$1);
        var tmp$1;
        if (match$3 !== undefined) {
          var match$4 = Js_dict.get(Caml_option.valFromOption(match$3), "returning");
          var tmp$2;
          if (match$4 !== undefined) {
            var value$2 = Caml_option.valFromOption(match$4);
            var match$5 = Js_json.decodeArray(value$2);
            tmp$2 = match$5 !== undefined ? match$5.map((function (value) {
                      var match = Js_json.decodeObject(value);
                      if (match !== undefined) {
                        var match$1 = Js_dict.get(Caml_option.valFromOption(match), "podcastId");
                        var tmp;
                        if (match$1 !== undefined) {
                          var value$1 = Caml_option.valFromOption(match$1);
                          var match$2 = Js_json.decodeString(value$1);
                          tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
                        } else {
                          tmp = Js_exn.raiseError("graphql_ppx: Field podcastId on type my_podcasts is missing");
                        }
                        return {
                                podcastId: tmp
                              };
                      } else {
                        return Js_exn.raiseError("graphql_ppx: Object is not a value");
                      }
                    })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$2));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field returning on type my_podcasts_mutation_response is missing");
          }
          tmp$1 = {
            returning: tmp$2
          };
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Object is not a value");
        }
        tmp = Caml_option.some(tmp$1);
      }
    } else {
      tmp = undefined;
    }
    return {
            insert_my_podcasts: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$3(userId, tags, image, id, title, description, publisher, itunesId, genreIds, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "tags",
                  tags
                ],
                /* tuple */[
                  "image",
                  image
                ],
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "title",
                  title
                ],
                /* tuple */[
                  "description",
                  description
                ],
                /* tuple */[
                  "publisher",
                  publisher
                ],
                /* tuple */[
                  "itunesId",
                  itunesId
                ],
                /* tuple */[
                  "genreIds",
                  genreIds
                ]
              ]),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var userId = variables.userId;
  var tags = variables.tags;
  var image = variables.image;
  var id = variables.id;
  var title = variables.title;
  var description = variables.description;
  var publisher = variables.publisher;
  var itunesId = variables.itunesId;
  var genreIds = variables.genreIds;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "tags",
                  tags
                ],
                /* tuple */[
                  "image",
                  image
                ],
                /* tuple */[
                  "id",
                  id
                ],
                /* tuple */[
                  "title",
                  title
                ],
                /* tuple */[
                  "description",
                  description
                ],
                /* tuple */[
                  "publisher",
                  publisher
                ],
                /* tuple */[
                  "itunesId",
                  itunesId
                ],
                /* tuple */[
                  "genreIds",
                  genreIds
                ]
              ]),
          parse: parse$3
        };
}

function ret_type$3(f) {
  return /* module */[];
}

var MT_Ret$3 = /* module */[];

var SavePodcast = /* module */[
  /* ppx_printed_query */ppx_printed_query$3,
  /* query */ppx_printed_query$3,
  /* parse */parse$3,
  /* make */make$3,
  /* makeWithVariables */makeWithVariables$3,
  /* ret_type */ret_type$3,
  /* MT_Ret */MT_Ret$3
];

function makeSavePodcastMutation(podcast, userId) {
  return make$3(userId, "", podcast[/* image */8], podcast[/* id */0], podcast[/* title */2], podcast[/* description */1], podcast[/* publisher */3], String(podcast[/* podcastItunesId */4]), Utils$ReactTemplate.toApiGenres(podcast[/* genreIds */6]), /* () */0);
}

var ppx_printed_query$4 = "mutation ($userId: String!, $podcastId: String!)  {\ndelete_my_podcasts(where: {userId: {_eq: $userId}, podcastId: {_eq: $podcastId}})  {\nreturning  {\npodcastId  \n}\n\n}\n\n}\n";

function parse$4(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "delete_my_podcasts");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeNull(value$1);
      if (match$2 !== undefined) {
        tmp = undefined;
      } else {
        var match$3 = Js_json.decodeObject(value$1);
        var tmp$1;
        if (match$3 !== undefined) {
          var match$4 = Js_dict.get(Caml_option.valFromOption(match$3), "returning");
          var tmp$2;
          if (match$4 !== undefined) {
            var value$2 = Caml_option.valFromOption(match$4);
            var match$5 = Js_json.decodeArray(value$2);
            tmp$2 = match$5 !== undefined ? match$5.map((function (value) {
                      var match = Js_json.decodeObject(value);
                      if (match !== undefined) {
                        var match$1 = Js_dict.get(Caml_option.valFromOption(match), "podcastId");
                        var tmp;
                        if (match$1 !== undefined) {
                          var value$1 = Caml_option.valFromOption(match$1);
                          var match$2 = Js_json.decodeString(value$1);
                          tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
                        } else {
                          tmp = Js_exn.raiseError("graphql_ppx: Field podcastId on type my_podcasts is missing");
                        }
                        return {
                                podcastId: tmp
                              };
                      } else {
                        return Js_exn.raiseError("graphql_ppx: Object is not a value");
                      }
                    })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$2));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field returning on type my_podcasts_mutation_response is missing");
          }
          tmp$1 = {
            returning: tmp$2
          };
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Object is not a value");
        }
        tmp = Caml_option.some(tmp$1);
      }
    } else {
      tmp = undefined;
    }
    return {
            delete_my_podcasts: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$4(userId, podcastId, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ]
              ]),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var userId = variables.userId;
  var podcastId = variables.podcastId;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ]
              ]),
          parse: parse$4
        };
}

function ret_type$4(f) {
  return /* module */[];
}

var MT_Ret$4 = /* module */[];

var RemovePodcast = /* module */[
  /* ppx_printed_query */ppx_printed_query$4,
  /* query */ppx_printed_query$4,
  /* parse */parse$4,
  /* make */make$4,
  /* makeWithVariables */makeWithVariables$4,
  /* ret_type */ret_type$4,
  /* MT_Ret */MT_Ret$4
];

export {
  SaveEpisode ,
  makeSaveEpisodeMutation ,
  GetEpisodeInsertInfo ,
  getEpisodeInsertInfo ,
  RemoveEpisode ,
  SavePodcast ,
  makeSavePodcastMutation ,
  RemovePodcast ,
  
}
/* Utils-ReactTemplate Not a pure module */
