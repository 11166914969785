// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE


function decode(s) {
  switch (s) {
    case "Listened" : 
        return /* Listened */2;
    case "NotListened" : 
        return /* NotListened */0;
    case "Started" : 
        return /* Started */1;
    default:
      return /* NotListened */0;
  }
}

function encode(s) {
  switch (s) {
    case 0 : 
        return "NotListened";
    case 1 : 
        return "Started";
    case 2 : 
        return "Listened";
    
  }
}

export {
  decode ,
  encode ,
  
}
/* No side effect */
