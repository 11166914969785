// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";

function ActionButton(Props) {
  var match = Props.disabled;
  var disabled = match !== undefined ? match : false;
  var onClick = Props.onClick;
  var action = Props.action;
  if (action) {
    return React.createElement(Core.Button, MaterialUi_Button.makeProps("Remove", undefined, /* Primary */-791844958, undefined, disabled, undefined, undefined, undefined, undefined, undefined, /* Small */311976103, undefined, /* Outlined */-28821822, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onClick, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
  } else {
    return React.createElement(Core.Button, MaterialUi_Button.makeProps("Save", undefined, /* Primary */-791844958, undefined, disabled, undefined, undefined, undefined, undefined, undefined, /* Small */311976103, undefined, /* Contained */-515484397, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onClick, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
  }
}

var make = ActionButton;

export {
  make ,
  
}
/* react Not a pure module */
