// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function toSearchTypeVariant(searchType) {
  if (searchType) {
    return /* PODCAST */-392104284;
  } else {
    return /* EPISODE */-248570981;
  }
}

function toSearchInput(query, offset) {
  return {
          searchTerm: query[/* searchTerm */0],
          language: query[/* language */2],
          genreIds: query[/* genreIds */4],
          offset: offset,
          podcastId: query[/* podcastId */3],
          searchType: query[/* searchType */1] ? /* PODCAST */-392104284 : /* EPISODE */-248570981,
          excludePodcastId: undefined
        };
}

var ppx_printed_query = "query ($input: SearchInput!)  {\nsearch(input: $input)  {\nnextOffset  \ntotal  \ncount  \nresults  {\n__typename\n...on Podcast   {\nid  \ndescription  \ntitle  \npublisher  \npodcastItunesId  \nlatestPubDate  \ngenreIds  \ntotalEpisodes  \nimage  \n}\n\n...on Episode   {\nid  \npodcastTitle  \ntitle  \npodcastItunesId  \nlengthSec  \npodcastId  \ndescription  \npubDate  \ngenreIds  \npublisher  \nimage  \n}\n\n}\n\n}\n\n}\n";

function parse(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "search");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeObject(value$1);
      if (match$2 !== undefined) {
        var value$2 = Caml_option.valFromOption(match$2);
        var match$3 = Js_dict.get(value$2, "nextOffset");
        var field_nextOffset;
        if (match$3 !== undefined) {
          var value$3 = Caml_option.valFromOption(match$3);
          var match$4 = Js_json.decodeNumber(value$3);
          field_nextOffset = match$4 !== undefined ? match$4 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$3));
        } else {
          field_nextOffset = Js_exn.raiseError("graphql_ppx: Field nextOffset on type SearchResults is missing");
        }
        var match$5 = Js_dict.get(value$2, "total");
        var field_total;
        if (match$5 !== undefined) {
          var value$4 = Caml_option.valFromOption(match$5);
          var match$6 = Js_json.decodeNumber(value$4);
          field_total = match$6 !== undefined ? match$6 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$4));
        } else {
          field_total = Js_exn.raiseError("graphql_ppx: Field total on type SearchResults is missing");
        }
        var match$7 = Js_dict.get(value$2, "count");
        var field_count;
        if (match$7 !== undefined) {
          var value$5 = Caml_option.valFromOption(match$7);
          var match$8 = Js_json.decodeNumber(value$5);
          field_count = match$8 !== undefined ? match$8 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$5));
        } else {
          field_count = Js_exn.raiseError("graphql_ppx: Field count on type SearchResults is missing");
        }
        var match$9 = Js_dict.get(value$2, "results");
        var field_results;
        if (match$9 !== undefined) {
          var value$6 = Caml_option.valFromOption(match$9);
          var match$10 = Js_json.decodeArray(value$6);
          field_results = match$10 !== undefined ? match$10.map((function (value) {
                    var match = Js_json.decodeObject(value);
                    if (match !== undefined) {
                      var match$1 = Js_dict.get(Caml_option.valFromOption(match), "__typename");
                      if (match$1 !== undefined) {
                        var match$2 = Js_json.decodeString(Caml_option.valFromOption(match$1));
                        if (match$2 !== undefined) {
                          var typename = match$2;
                          switch (typename) {
                            case "Episode" : 
                                var match$3 = Js_json.decodeObject(value);
                                var tmp;
                                if (match$3 !== undefined) {
                                  var value$1 = Caml_option.valFromOption(match$3);
                                  var match$4 = Js_dict.get(value$1, "id");
                                  var field_id;
                                  if (match$4 !== undefined) {
                                    var value$2 = Caml_option.valFromOption(match$4);
                                    var match$5 = Js_json.decodeString(value$2);
                                    field_id = match$5 !== undefined ? match$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                                  } else {
                                    field_id = Js_exn.raiseError("graphql_ppx: Field id on type Episode is missing");
                                  }
                                  var match$6 = Js_dict.get(value$1, "podcastTitle");
                                  var field_podcastTitle;
                                  if (match$6 !== undefined) {
                                    var value$3 = Caml_option.valFromOption(match$6);
                                    var match$7 = Js_json.decodeString(value$3);
                                    field_podcastTitle = match$7 !== undefined ? match$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                                  } else {
                                    field_podcastTitle = Js_exn.raiseError("graphql_ppx: Field podcastTitle on type Episode is missing");
                                  }
                                  var match$8 = Js_dict.get(value$1, "title");
                                  var field_title;
                                  if (match$8 !== undefined) {
                                    var value$4 = Caml_option.valFromOption(match$8);
                                    var match$9 = Js_json.decodeString(value$4);
                                    field_title = match$9 !== undefined ? match$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                                  } else {
                                    field_title = Js_exn.raiseError("graphql_ppx: Field title on type Episode is missing");
                                  }
                                  var match$10 = Js_dict.get(value$1, "podcastItunesId");
                                  var field_podcastItunesId;
                                  if (match$10 !== undefined) {
                                    var value$5 = Caml_option.valFromOption(match$10);
                                    var match$11 = Js_json.decodeNumber(value$5);
                                    field_podcastItunesId = match$11 !== undefined ? match$11 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$5));
                                  } else {
                                    field_podcastItunesId = Js_exn.raiseError("graphql_ppx: Field podcastItunesId on type Episode is missing");
                                  }
                                  var match$12 = Js_dict.get(value$1, "lengthSec");
                                  var field_lengthSec;
                                  if (match$12 !== undefined) {
                                    var value$6 = Caml_option.valFromOption(match$12);
                                    var match$13 = Js_json.decodeNumber(value$6);
                                    field_lengthSec = match$13 !== undefined ? match$13 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$6));
                                  } else {
                                    field_lengthSec = Js_exn.raiseError("graphql_ppx: Field lengthSec on type Episode is missing");
                                  }
                                  var match$14 = Js_dict.get(value$1, "podcastId");
                                  var field_podcastId;
                                  if (match$14 !== undefined) {
                                    var value$7 = Caml_option.valFromOption(match$14);
                                    var match$15 = Js_json.decodeString(value$7);
                                    field_podcastId = match$15 !== undefined ? match$15 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                                  } else {
                                    field_podcastId = Js_exn.raiseError("graphql_ppx: Field podcastId on type Episode is missing");
                                  }
                                  var match$16 = Js_dict.get(value$1, "description");
                                  var field_description;
                                  if (match$16 !== undefined) {
                                    var value$8 = Caml_option.valFromOption(match$16);
                                    var match$17 = Js_json.decodeString(value$8);
                                    field_description = match$17 !== undefined ? match$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
                                  } else {
                                    field_description = Js_exn.raiseError("graphql_ppx: Field description on type Episode is missing");
                                  }
                                  var match$18 = Js_dict.get(value$1, "pubDate");
                                  var field_pubDate;
                                  if (match$18 !== undefined) {
                                    var value$9 = Caml_option.valFromOption(match$18);
                                    var match$19 = Js_json.decodeString(value$9);
                                    field_pubDate = match$19 !== undefined ? match$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                                  } else {
                                    field_pubDate = Js_exn.raiseError("graphql_ppx: Field pubDate on type Episode is missing");
                                  }
                                  var match$20 = Js_dict.get(value$1, "genreIds");
                                  var field_genreIds;
                                  if (match$20 !== undefined) {
                                    var value$10 = Caml_option.valFromOption(match$20);
                                    var match$21 = Js_json.decodeArray(value$10);
                                    field_genreIds = match$21 !== undefined ? match$21.map((function (value) {
                                              var match = Js_json.decodeNumber(value);
                                              if (match !== undefined) {
                                                return match | 0;
                                              } else {
                                                return Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value));
                                              }
                                            })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$10));
                                  } else {
                                    field_genreIds = Js_exn.raiseError("graphql_ppx: Field genreIds on type Episode is missing");
                                  }
                                  var match$22 = Js_dict.get(value$1, "publisher");
                                  var field_publisher;
                                  if (match$22 !== undefined) {
                                    var value$11 = Caml_option.valFromOption(match$22);
                                    var match$23 = Js_json.decodeString(value$11);
                                    field_publisher = match$23 !== undefined ? match$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$11));
                                  } else {
                                    field_publisher = Js_exn.raiseError("graphql_ppx: Field publisher on type Episode is missing");
                                  }
                                  var match$24 = Js_dict.get(value$1, "image");
                                  var field_image;
                                  if (match$24 !== undefined) {
                                    var value$12 = Caml_option.valFromOption(match$24);
                                    var match$25 = Js_json.decodeString(value$12);
                                    field_image = match$25 !== undefined ? match$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                                  } else {
                                    field_image = Js_exn.raiseError("graphql_ppx: Field image on type Episode is missing");
                                  }
                                  tmp = /* record */[
                                    /* id */field_id,
                                    /* title */field_title,
                                    /* description */field_description,
                                    /* podcastTitle */field_podcastTitle,
                                    /* pubDate */field_pubDate,
                                    /* lengthSec */field_lengthSec,
                                    /* podcastItunesId */field_podcastItunesId,
                                    /* podcastId */field_podcastId,
                                    /* genreIds */field_genreIds,
                                    /* publisher */field_publisher,
                                    /* image */field_image
                                  ];
                                } else {
                                  tmp = Js_exn.raiseError("graphql_ppx: Expected object of type Episode, got " + JSON.stringify(value));
                                }
                                return /* `Episode */[
                                        1051488155,
                                        tmp
                                      ];
                            case "Podcast" : 
                                var match$26 = Js_json.decodeObject(value);
                                var tmp$1;
                                if (match$26 !== undefined) {
                                  var value$13 = Caml_option.valFromOption(match$26);
                                  var match$27 = Js_dict.get(value$13, "id");
                                  var field_id$1;
                                  if (match$27 !== undefined) {
                                    var value$14 = Caml_option.valFromOption(match$27);
                                    var match$28 = Js_json.decodeString(value$14);
                                    field_id$1 = match$28 !== undefined ? match$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$14));
                                  } else {
                                    field_id$1 = Js_exn.raiseError("graphql_ppx: Field id on type Podcast is missing");
                                  }
                                  var match$29 = Js_dict.get(value$13, "description");
                                  var field_description$1;
                                  if (match$29 !== undefined) {
                                    var value$15 = Caml_option.valFromOption(match$29);
                                    var match$30 = Js_json.decodeString(value$15);
                                    field_description$1 = match$30 !== undefined ? match$30 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                                  } else {
                                    field_description$1 = Js_exn.raiseError("graphql_ppx: Field description on type Podcast is missing");
                                  }
                                  var match$31 = Js_dict.get(value$13, "title");
                                  var field_title$1;
                                  if (match$31 !== undefined) {
                                    var value$16 = Caml_option.valFromOption(match$31);
                                    var match$32 = Js_json.decodeString(value$16);
                                    field_title$1 = match$32 !== undefined ? match$32 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
                                  } else {
                                    field_title$1 = Js_exn.raiseError("graphql_ppx: Field title on type Podcast is missing");
                                  }
                                  var match$33 = Js_dict.get(value$13, "publisher");
                                  var field_publisher$1;
                                  if (match$33 !== undefined) {
                                    var value$17 = Caml_option.valFromOption(match$33);
                                    var match$34 = Js_json.decodeString(value$17);
                                    field_publisher$1 = match$34 !== undefined ? match$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                                  } else {
                                    field_publisher$1 = Js_exn.raiseError("graphql_ppx: Field publisher on type Podcast is missing");
                                  }
                                  var match$35 = Js_dict.get(value$13, "podcastItunesId");
                                  var field_podcastItunesId$1;
                                  if (match$35 !== undefined) {
                                    var value$18 = Caml_option.valFromOption(match$35);
                                    var match$36 = Js_json.decodeNumber(value$18);
                                    field_podcastItunesId$1 = match$36 !== undefined ? match$36 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$18));
                                  } else {
                                    field_podcastItunesId$1 = Js_exn.raiseError("graphql_ppx: Field podcastItunesId on type Podcast is missing");
                                  }
                                  var match$37 = Js_dict.get(value$13, "latestPubDate");
                                  var field_latestPubDate;
                                  if (match$37 !== undefined) {
                                    var value$19 = Caml_option.valFromOption(match$37);
                                    var match$38 = Js_json.decodeString(value$19);
                                    field_latestPubDate = match$38 !== undefined ? match$38 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
                                  } else {
                                    field_latestPubDate = Js_exn.raiseError("graphql_ppx: Field latestPubDate on type Podcast is missing");
                                  }
                                  var match$39 = Js_dict.get(value$13, "genreIds");
                                  var field_genreIds$1;
                                  if (match$39 !== undefined) {
                                    var value$20 = Caml_option.valFromOption(match$39);
                                    var match$40 = Js_json.decodeArray(value$20);
                                    field_genreIds$1 = match$40 !== undefined ? match$40.map((function (value) {
                                              var match = Js_json.decodeNumber(value);
                                              if (match !== undefined) {
                                                return match | 0;
                                              } else {
                                                return Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value));
                                              }
                                            })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$20));
                                  } else {
                                    field_genreIds$1 = Js_exn.raiseError("graphql_ppx: Field genreIds on type Podcast is missing");
                                  }
                                  var match$41 = Js_dict.get(value$13, "totalEpisodes");
                                  var field_totalEpisodes;
                                  if (match$41 !== undefined) {
                                    var value$21 = Caml_option.valFromOption(match$41);
                                    var match$42 = Js_json.decodeNumber(value$21);
                                    field_totalEpisodes = match$42 !== undefined ? match$42 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$21));
                                  } else {
                                    field_totalEpisodes = Js_exn.raiseError("graphql_ppx: Field totalEpisodes on type Podcast is missing");
                                  }
                                  var match$43 = Js_dict.get(value$13, "image");
                                  var field_image$1;
                                  if (match$43 !== undefined) {
                                    var value$22 = Caml_option.valFromOption(match$43);
                                    var match$44 = Js_json.decodeString(value$22);
                                    field_image$1 = match$44 !== undefined ? match$44 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
                                  } else {
                                    field_image$1 = Js_exn.raiseError("graphql_ppx: Field image on type Podcast is missing");
                                  }
                                  tmp$1 = /* record */[
                                    /* id */field_id$1,
                                    /* description */field_description$1,
                                    /* title */field_title$1,
                                    /* publisher */field_publisher$1,
                                    /* podcastItunesId */field_podcastItunesId$1,
                                    /* latestPubDate */field_latestPubDate,
                                    /* genreIds */field_genreIds$1,
                                    /* totalEpisodes */field_totalEpisodes,
                                    /* image */field_image$1
                                  ];
                                } else {
                                  tmp$1 = Js_exn.raiseError("graphql_ppx: Expected object of type Podcast, got " + JSON.stringify(value));
                                }
                                return /* `Podcast */[
                                        907954852,
                                        tmp$1
                                      ];
                            default:
                              return Js_exn.raiseError("graphql_ppx: Union SearchResult returned unknown type " + typename);
                          }
                        } else {
                          return Js_exn.raiseError("graphql_ppx: Union SearchResult has a __typename field that is not a string");
                        }
                      } else {
                        return Js_exn.raiseError("graphql_ppx: Union SearchResult is missing the __typename field");
                      }
                    } else {
                      return Js_exn.raiseError("graphql_ppx: Expected union SearchResult to be an object, got " + JSON.stringify(value));
                    }
                  })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$6));
        } else {
          field_results = Js_exn.raiseError("graphql_ppx: Field results on type SearchResults is missing");
        }
        tmp = /* record */[
          /* nextOffset */field_nextOffset,
          /* total */field_total,
          /* count */field_count,
          /* results */field_results
        ];
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Expected object of type SearchResults, got " + JSON.stringify(value$1));
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field search on type query_root is missing");
    }
    return {
            search: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function json_of_SEARCH_TYPE(value) {
  if (value >= -248570981) {
    return "EPISODE";
  } else {
    return "PODCAST";
  }
}

function json_of_SearchInput(value) {
  var v = value.excludePodcastId;
  var v$1 = value.genreIds;
  var tmp;
  if (v$1 !== undefined) {
    var v$2 = v$1;
    tmp = v$2.map((function (v) {
            return v;
          }));
  } else {
    tmp = null;
  }
  var v$3 = value.language;
  var v$4 = value.podcastId;
  return Js_dict.fromArray(/* array */[
              /* tuple */[
                "excludePodcastId",
                v !== undefined ? v : null
              ],
              /* tuple */[
                "genreIds",
                tmp
              ],
              /* tuple */[
                "language",
                v$3 !== undefined ? v$3 : null
              ],
              /* tuple */[
                "offset",
                value.offset
              ],
              /* tuple */[
                "podcastId",
                v$4 !== undefined ? v$4 : null
              ],
              /* tuple */[
                "searchTerm",
                value.searchTerm
              ],
              /* tuple */[
                "searchType",
                json_of_SEARCH_TYPE(value.searchType)
              ]
            ]);
}

function make(input, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "input",
                  json_of_SearchInput(input)
                ]]),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "input",
                  json_of_SearchInput(input)
                ]]),
          parse: parse
        };
}

function ret_type(f) {
  return /* module */[];
}

var MT_Ret = /* module */[];

var Search = /* module */[
  /* ppx_printed_query */ppx_printed_query,
  /* query */ppx_printed_query,
  /* parse */parse,
  /* json_of_SEARCH_TYPE */json_of_SEARCH_TYPE,
  /* json_of_SearchInput */json_of_SearchInput,
  /* make */make,
  /* makeWithVariables */makeWithVariables,
  /* ret_type */ret_type,
  /* MT_Ret */MT_Ret
];

function makeSearchQuery(query, offset) {
  return make(toSearchInput(query, offset), /* () */0);
}

function fetchMoreUpdateQuery(prevResult, options) {
  var mergeResults = (
    function (prevResult, fetchMoreResult) {
      if (!fetchMoreResult) return prevResult;

      const results = prevResult.search.results.concat(
        fetchMoreResult.search.results
      );

      return {
        ...fetchMoreResult,
        search: { ...fetchMoreResult.search, results }
      };
    }
   );
  return Curry._2(mergeResults, prevResult, Caml_option.undefined_to_opt(options.fetchMoreResult));
}

export {
  toSearchTypeVariant ,
  toSearchInput ,
  Search ,
  makeSearchQuery ,
  fetchMoreUpdateQuery ,
  
}
/* No side effect */
