// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Card from "@jsiebern/bs-material-ui/src/MaterialUi_Card.bs.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as MaterialUi_Typography from "@jsiebern/bs-material-ui/src/MaterialUi_Typography.bs.js";
import * as MaterialUi_CardActions from "@jsiebern/bs-material-ui/src/MaterialUi_CardActions.bs.js";
import * as MaterialUi_CardContent from "@jsiebern/bs-material-ui/src/MaterialUi_CardContent.bs.js";
import * as CardMedia$ReactTemplate from "./CardMedia.bs.js";
import * as MediaHooks$ReactTemplate from "../hooks/MediaHooks.bs.js";
import * as FilterJs from "react-feather/dist/icons/filter.js";
import * as BookmarkJs from "react-feather/dist/icons/bookmark.js";

function str(prim) {
  return prim;
}

function cardActions(theme) {
  return Css.style(/* :: */[
              Css.padding(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
              /* :: */[
                Css.paddingTop(Css.px(0)),
                /* :: */[
                  Css.justifyContent(/* flexEnd */924268066),
                  /* [] */0
                ]
              ]
            ]);
}

function cardSavedIcon(theme) {
  return Css.style(/* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.right(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
                /* :: */[
                  Css.top(Css.px(Caml_int32.imul(-1, Utils$ReactTemplate.spacingPx(1, theme)))),
                  /* [] */0
                ]
              ]
            ]);
}

var breakWord = Css.style(/* :: */[
      Css.overflow(Css.hidden),
      /* :: */[
        Css.unsafe("wordBreak", "break-word"),
        /* [] */0
      ]
    ]);

var Styles$1 = /* module */[
  /* cardActions */cardActions,
  /* cardSavedIcon */cardSavedIcon,
  /* breakWord */breakWord
];

function Cards$CardActions(Props) {
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement(Core.CardActions, MaterialUi_CardActions.makeProps(Caml_option.some(children), cardActions(theme), undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var CardActions = /* module */[/* make */Cards$CardActions];

function Cards$SearchCard(Props) {
  var isSaved = Props.isSaved;
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement(Core.Card, MaterialUi_Card.makeProps(Css.style(/* :: */[
                      Css.position(/* relative */903134412),
                      /* :: */[
                        Css.overflow(/* visible */589592690),
                        /* [] */0
                      ]
                    ]), undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), isSaved ? React.createElement(BookmarkJs.default, {
                    color: "#29335C",
                    fill: "#669BBC",
                    className: cardSavedIcon(theme)
                  }) : null, children);
}

var SearchCard = /* module */[/* make */Cards$SearchCard];

function Cards$Title(Props) {
  var children = Props.children;
  return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(children), Cn.make(/* :: */[
                      Css.style(/* :: */[
                            Css.lineHeight(/* `abs */[
                                  4845682,
                                  1.3
                                ]),
                            /* [] */0
                          ]),
                      /* :: */[
                        breakWord,
                        /* [] */0
                      ]
                    ]), undefined, undefined, undefined, true, undefined, undefined, /* H6 */16110, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var Title = /* module */[/* make */Cards$Title];

function Cards$Publisher(Props) {
  var publisher = Props.publisher;
  var match = Props.className;
  var className = match !== undefined ? match : "";
  return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some("By " + publisher), Cn.make(/* :: */[
                      Css.style(/* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  500
                                ]),
                            /* :: */[
                              Css.lineHeight(/* `abs */[
                                    4845682,
                                    1.3
                                  ]),
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        breakWord,
                        /* :: */[
                          className,
                          /* [] */0
                        ]
                      ]
                    ]), /* TextSecondary */-507693849, undefined, undefined, true, undefined, undefined, /* Subtitle1 */386038009, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var Publisher = /* module */[/* make */Cards$Publisher];

function Cards$EpisodePodcastTitle(Props) {
  var title = Props.title;
  var match = Props.className;
  var className = match !== undefined ? match : "";
  return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(title), Cn.make(/* :: */[
                      Css.style(/* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  500
                                ]),
                            /* :: */[
                              Css.lineHeight(/* `abs */[
                                    4845682,
                                    1.3
                                  ]),
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        breakWord,
                        /* :: */[
                          className,
                          /* [] */0
                        ]
                      ]
                    ]), /* TextSecondary */-507693849, undefined, undefined, true, undefined, undefined, /* Subtitle1 */386038009, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var EpisodePodcastTitle = /* module */[/* make */Cards$EpisodePodcastTitle];

function Cards$PodcastTitle(Props) {
  var title = Props.title;
  return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(title), Cn.make(/* :: */[
                      Css.style(/* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  500
                                ]),
                            /* :: */[
                              Css.lineHeight(/* `abs */[
                                    4845682,
                                    1.3
                                  ]),
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        breakWord,
                        /* [] */0
                      ]
                    ]), undefined, undefined, undefined, true, undefined, undefined, /* Subtitle1 */386038009, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var PodcastTitle = /* module */[/* make */Cards$PodcastTitle];

function Cards$Description(Props) {
  var description = Props.description;
  return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(React.createElement("div", {
                          dangerouslySetInnerHTML: {
                            __html: Utils$ReactTemplate.truncateDescription(description)
                          }
                        })), Css.style(/* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* [] */0
                    ]), /* TextSecondary */-507693849, /* `String */[
                  -976970511,
                  "div"
                ], undefined, undefined, undefined, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var Description = /* module */[/* make */Cards$Description];

function Cards$PodcastFilterButton(Props) {
  var onFilter = Props.onFilter;
  return React.createElement(Core.Button, MaterialUi_Button.makeProps(Caml_option.some(null), Css.style(/* :: */[
                      Css.alignSelf(/* flexStart */662439529),
                      /* :: */[
                        Css.padding(Css.px(0)),
                        /* :: */[
                          Css.marginBottom(Css.px(5)),
                          /* [] */0
                        ]
                      ]
                    ]), /* Secondary */67972948, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Small */311976103, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                    return Curry._1(onFilter, /* () */0);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(FilterJs.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(21)),
                        /* [] */0
                      ])
                }), React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Filter", Css.style(/* :: */[
                          Css.textTransform(/* none */-922086728),
                          /* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  600
                                ]),
                            /* :: */[
                              Css.marginLeft(Css.px(5)),
                              /* [] */0
                            ]
                          ]
                        ]), undefined, /* `String */[
                      -976970511,
                      "span"
                    ], undefined, undefined, undefined, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, undefined, /* () */0)));
}

var PodcastFilterButton = /* module */[/* make */Cards$PodcastFilterButton];

function Cards$CardMediaContainer(Props) {
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                        /* :: */[
                          Css.paddingTop(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
                          /* :: */[
                            Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                                  Css.marginBottom(Css.px(0)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var CardMediaContainer = /* module */[/* make */Cards$CardMediaContainer];

function Cards$PodcastCardContent(Props) {
  var title = Props.title;
  var image = Props.image;
  var description = Props.description;
  var onFilter = Props.onFilter;
  var info = Props.info;
  var isDesktop = MediaHooks$ReactTemplate.useIsDesktop(/* () */0);
  var match = !isDesktop;
  var match$1 = !isDesktop;
  return React.createElement(Core.CardContent, MaterialUi_CardContent.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, /* () */0), React.createElement(Cards$Title, {
                  children: title
                }), isDesktop ? info : null, React.createElement(Cards$CardMediaContainer, {
                  children: null
                }, React.createElement(CardMedia$ReactTemplate.make, {
                      image: image
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.flexDirection(Css.column),
                              /* :: */[
                                Css.justifyContent(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, match ? info : null, Belt_Option.mapWithDefault(onFilter, null, (function (filter) {
                            return React.createElement(Cards$PodcastFilterButton, {
                                        onFilter: filter
                                      });
                          })), isDesktop ? React.createElement(Cards$Description, {
                            description: description
                          }) : null)), match$1 ? React.createElement(Cards$Description, {
                    description: description
                  }) : null);
}

var PodcastCardContent = /* module */[/* make */Cards$PodcastCardContent];

function Cards$EpisodeCardContent(Props) {
  var title = Props.title;
  var image = Props.image;
  var description = Props.description;
  var lengthSec = Props.lengthSec;
  var pubDate = Props.pubDate;
  var podcastTitle = Props.podcastTitle;
  var addedDate = Props.addedDate;
  var isDesktop = MediaHooks$ReactTemplate.useIsDesktop(/* () */0);
  var addedDateElement = Belt_Option.mapWithDefault(addedDate, null, (function (date) {
          return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some("Added: " + Utils$ReactTemplate.formatDate(date)), undefined, undefined, undefined, undefined, true, undefined, undefined, /* Subtitle2 */386038010, undefined, undefined, undefined, undefined, undefined, /* () */0));
        }));
  var match = !isDesktop;
  var match$1 = !isDesktop;
  var match$2 = !isDesktop;
  return React.createElement(Core.CardContent, MaterialUi_CardContent.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, /* () */0), React.createElement(Cards$Title, {
                  children: title
                }), isDesktop ? React.createElement(React.Fragment, undefined, React.createElement(Cards$Publisher, {
                        publisher: podcastTitle
                      }), addedDateElement) : null, React.createElement(Cards$CardMediaContainer, {
                  children: null
                }, React.createElement(CardMedia$ReactTemplate.make, {
                      image: image
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.flexDirection(Css.column),
                              /* :: */[
                                Css.justifyContent(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, match ? React.createElement(Cards$Publisher, {
                            publisher: podcastTitle
                          }) : null, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(String(lengthSec / 60 | 0) + (" mins, published " + Utils$ReactTemplate.formatListennotesDate(pubDate))), undefined, undefined, undefined, undefined, true, undefined, undefined, /* Subtitle2 */386038010, undefined, undefined, undefined, undefined, undefined, /* () */0)), match$1 ? addedDateElement : null, isDesktop ? React.createElement(Cards$Description, {
                            description: description
                          }) : null)), match$2 ? React.createElement(Cards$Description, {
                    description: description
                  }) : null);
}

var EpisodeCardContent = /* module */[/* make */Cards$EpisodeCardContent];

export {
  str ,
  Styles$1 as Styles,
  CardActions ,
  SearchCard ,
  Title ,
  Publisher ,
  EpisodePodcastTitle ,
  PodcastTitle ,
  Description ,
  PodcastFilterButton ,
  CardMediaContainer ,
  PodcastCardContent ,
  EpisodeCardContent ,
  
}
/* breakWord Not a pure module */
