// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as MaterialUi_CardMedia from "@jsiebern/bs-material-ui/src/MaterialUi_CardMedia.bs.js";

function CardMedia(Props) {
  var image = Props.image;
  var theme = Styles.useTheme();
  var match = image !== "";
  if (match) {
    return React.createElement(Core.CardMedia, MaterialUi_CardMedia.makeProps(Css.style(/* :: */[
                        Css.width(Css.px(80)),
                        /* :: */[
                          Css.height(Css.px(80)),
                          /* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* :: */[
                              Css.flexShrink(0.0),
                              /* :: */[
                                Css.marginRight(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                                /* :: */[
                                  Css.boxShadow(Css.Shadow[/* box */0](Css.px(0), Css.px(0), Css.px(20), undefined, undefined, /* `rgba */[
                                            -878128972,
                                            /* tuple */[
                                              0,
                                              0,
                                              0,
                                              0.1
                                            ]
                                          ])),
                                  /* :: */[
                                    Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                                          Css.width(Css.px(100)),
                                          /* :: */[
                                            Css.height(Css.px(100)),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]), undefined, image, undefined, undefined, undefined, undefined, undefined, /* () */0));
  } else {
    return null;
  }
}

var make = CardMedia;

export {
  make ,
  
}
/* Css Not a pure module */
