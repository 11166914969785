// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Chip from "@jsiebern/bs-material-ui/src/MaterialUi_Chip.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../../utils/Utils.bs.js";
import * as AppCore$ReactTemplate from "../../models/AppCore.bs.js";
import * as MaterialUi_FormControl from "@jsiebern/bs-material-ui/src/MaterialUi_FormControl.bs.js";
import * as Selectors$ReactTemplate from "../../models/Selectors.bs.js";
import * as ContentTabs$ReactTemplate from "../ContentTabs.bs.js";
import * as SearchInput$ReactTemplate from "../SearchInput.bs.js";

function str(prim) {
  return prim;
}

function searchContainer(theme) {
  return Css.style(/* :: */[
              Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(3, theme))),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.flexDirection(/* row */5693978),
                  /* [] */0
                ]
              ]
            ]);
}

var searchFormControl = Css.style(/* :: */[
      Css.maxWidth(Css.px(600)),
      /* :: */[
        Css.margin2(Css.zero, Css.auto),
        /* [] */0
      ]
    ]);

var searchInput = Css.style(/* :: */[
      Css.fontSize(Css.px(26)),
      /* [] */0
    ]);

var Styles$1 = /* module */[
  /* searchContainer */searchContainer,
  /* searchFormControl */searchFormControl,
  /* searchInput */searchInput
];

function SearchForm(Props) {
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var searchType = Curry._1(AppCore$ReactTemplate.useSelector, Selectors$ReactTemplate.getSearchType);
  var searchPodcast = Curry._1(AppCore$ReactTemplate.useSelector, Selectors$ReactTemplate.getSearchPodcast);
  var theme = Styles.useTheme();
  return React.createElement(React.Fragment, undefined, React.createElement(Core.FormControl, MaterialUi_FormControl.makeProps(Caml_option.some(React.createElement(SearchInput$ReactTemplate.make, {
                              classes: /* :: */[
                                /* Input */Block.__(8, [searchInput]),
                                /* :: */[
                                  /* FormControl */Block.__(1, [searchFormControl]),
                                  /* [] */0
                                ]
                              ]
                            })), searchContainer(theme), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), searchPodcast !== undefined && !searchType ? React.createElement(Core.Chip, MaterialUi_Chip.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(searchPodcast[/* title */1]), undefined, (function (param) {
                          return Curry._1(dispatch, /* ClearEpisodeQueryPodcast */1);
                        }), undefined, undefined, /* Medium */861718677, undefined, undefined, undefined, /* :: */[
                        /* Label */Block.__(22, [Css.style(/* :: */[
                                  Css.maxWidth(Css.px(300)),
                                  /* :: */[
                                    Css.textOverflow(Css.ellipsis),
                                    /* :: */[
                                      Css.display(Css.block),
                                      /* :: */[
                                        Css.overflow(Css.hidden),
                                        /* :: */[
                                          Css.whiteSpace(/* nowrap */867913355),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ])]),
                        /* [] */0
                      ], undefined, /* () */0)) : null, React.createElement(ContentTabs$ReactTemplate.make, {
                  activeTab: searchType,
                  onTabChange: (function (contentType) {
                      return Curry._1(dispatch, /* SetContentType */Block.__(4, [contentType]));
                    })
                }));
}

var make = SearchForm;

export {
  str ,
  Styles$1 as Styles,
  make ,
  
}
/* searchFormControl Not a pure module */
