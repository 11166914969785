// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Url$ReactTemplate from "../utils/Url.bs.js";
import * as ContentType$ReactTemplate from "./ContentType.bs.js";

function tToJs(param) {
  return {
          searchTerm: param[/* searchTerm */0],
          searchType: param[/* searchType */1],
          language: param[/* language */2],
          podcastId: param[/* podcastId */3],
          genreIds: param[/* genreIds */4]
        };
}

function tFromJs(param) {
  return /* record */[
          /* searchTerm */param.searchTerm,
          /* searchType */param.searchType,
          /* language */param.language,
          /* podcastId */param.podcastId,
          /* genreIds */param.genreIds
        ];
}

function make($staropt$star, param) {
  var searchTerm = $staropt$star !== undefined ? $staropt$star : "";
  return /* record */[
          /* searchTerm */searchTerm,
          /* searchType : Episode */0,
          /* language */"English",
          /* podcastId */undefined,
          /* genreIds */undefined
        ];
}

function valueFromDict(key, dict) {
  var match = Js_dict.get(dict, key);
  if (match !== undefined) {
    var match$1 = match;
    if (match$1.tag) {
      var array = match$1[0];
      if (array.length > 0) {
        return array[0];
      } else {
        return "";
      }
    } else {
      return match$1[0];
    }
  } else {
    return "";
  }
}

function parse(queryString) {
  var dict = Url$ReactTemplate.Query[/* parse */1](queryString);
  var searchTerm = valueFromDict("q", dict);
  var searchType = ContentType$ReactTemplate.fromString(valueFromDict("content", dict));
  return /* record */[
          /* searchTerm */searchTerm,
          /* searchType */searchType,
          /* language */"English",
          /* podcastId */undefined,
          /* genreIds */undefined
        ];
}

function stringify(query) {
  return Url$ReactTemplate.Params[/* add */0]("content", ContentType$ReactTemplate.toString(query[/* searchType */1]), Url$ReactTemplate.Params[/* add */0]("q", query[/* searchTerm */0], /* array */[]));
}

var Url = /* module */[
  /* valueFromDict */valueFromDict,
  /* parse */parse,
  /* stringify */stringify
];

function sortToParam(sort) {
  return String(sort ? 1 : 0);
}

function searchFieldToParam(field) {
  switch (field) {
    case 0 : 
        return "title";
    case 1 : 
        return "description";
    case 2 : 
        return "author";
    case 3 : 
        return "audio";
    
  }
}

export {
  tToJs ,
  tFromJs ,
  make ,
  Url ,
  sortToParam ,
  searchFieldToParam ,
  
}
/* No side effect */
