// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as MuiLink_Extended$ReactTemplate from "../bindings/MuiLink_Extended.bs.js";

function shouldPreventDefault(e) {
  if (e.metaKey) {
    return false;
  } else {
    return !e.ctrlKey;
  }
}

function RouterLink(Props) {
  var href = Props.href;
  var className = Props.className;
  var variant = Props.variant;
  var underline = Props.underline;
  var component = Props.component;
  var children = Props.children;
  var color = Props.color;
  var handleClick = function ($$event) {
    if (!$$event.defaultPrevented && shouldPreventDefault($$event)) {
      $$event.preventDefault();
      return ReasonReactRouter.push(href);
    } else {
      return 0;
    }
  };
  return React.createElement(Core.Link, MuiLink_Extended$ReactTemplate.makeProps(Caml_option.some(children), className, color, component, undefined, undefined, undefined, underline, variant, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleClick, href, /* () */0));
}

var make = RouterLink;

export {
  shouldPreventDefault ,
  make ,
  
}
/* react Not a pure module */
