// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as AppBar$ReactTemplate from "./components/AppBar.bs.js";
import * as MaterialUi_Container from "@jsiebern/bs-material-ui/src/MaterialUi_Container.bs.js";
import * as AppCore$ReactTemplate from "./models/AppCore.bs.js";
import * as Routing$ReactTemplate from "./utils/Routing.bs.js";
import * as MaterialUi_CssBaseline from "@jsiebern/bs-material-ui/src/MaterialUi_CssBaseline.bs.js";
import * as SearchPage$ReactTemplate from "./pages/SearchPage.bs.js";
import * as UserIdentity$ReactTemplate from "./UserIdentity.bs.js";
import * as MyLibraryPage$ReactTemplate from "./pages/MyLibraryPage.bs.js";
import * as IdentityDialog$ReactTemplate from "./components/identity/IdentityDialog.bs.js";
import * as MyEpisodesPage$ReactTemplate from "./pages/MyEpisodesPage.bs.js";
import * as RootNotifications$ReactTemplate from "./RootNotifications.bs.js";

function str(prim) {
  return prim;
}

function getShowIdentityModal(model) {
  return model[/* showIdentityModal */3];
}

function Root(Props) {
  var showDialog = Curry._1(AppCore$ReactTemplate.useSelector, getShowIdentityModal);
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var route = Routing$ReactTemplate.useAppUrl(/* () */0);
  var identity = Curry._1(UserIdentity$ReactTemplate.Context[/* useIdentityContext */0], /* () */0);
  var id = Belt_Option.map(identity[/* user */0], (function (u) {
          return u[/* id */2];
        }));
  var pageToShow = React.useMemo((function () {
          if (typeof route === "number") {
            switch (route) {
              case 0 : 
                  if (id !== undefined) {
                    return React.createElement(MyLibraryPage$ReactTemplate.make, {
                                userId: id
                              });
                  } else {
                    return React.createElement("div", undefined);
                  }
              case 1 : 
              case 2 : 
                  return React.createElement("div", undefined);
              
            }
          } else if (route.tag) {
            return React.createElement(SearchPage$ReactTemplate.make, {
                        userId: id
                      });
          } else if (id !== undefined) {
            return React.createElement(MyEpisodesPage$ReactTemplate.make, {
                        podcastId: route[0],
                        userId: id
                      });
          } else {
            return React.createElement("div", undefined);
          }
        }), /* tuple */[
        route,
        id
      ]);
  var closeDialog = function (param) {
    return Curry._1(dispatch, /* SetShowIdentityModal */Block.__(2, [false]));
  };
  var handleLogin = function (param) {
    Curry._1(dispatch, /* SetShowIdentityModal */Block.__(2, [false]));
    return Routing$ReactTemplate.pushRoute(/* MyLibrary */0);
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Core.CssBaseline, MaterialUi_CssBaseline.makeProps(undefined, undefined, undefined, /* () */0)), React.createElement(IdentityDialog$ReactTemplate.make, {
                  open_: showDialog,
                  onLogin: handleLogin,
                  onClose: closeDialog
                }), React.createElement(AppBar$ReactTemplate.make, {
                  isLoggedIn: identity[/* isLoggedIn */1],
                  activePage: route
                }), React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(pageToShow), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(RootNotifications$ReactTemplate.make, { }));
}

var make = Root;

export {
  str ,
  getShowIdentityModal ,
  make ,
  
}
/* react Not a pure module */
