// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE


function getSearchTerm(model) {
  return model[/* search */0][/* searchTerm */0];
}

function getSearchType(model) {
  return model[/* search */0][/* searchType */1];
}

function getSearchPodcast(model) {
  return model[/* searchPodcast */1];
}

function getSearchModel(model) {
  return model[/* search */0];
}

function hasSearchTerm(model) {
  return model[/* search */0][/* searchTerm */0].length > 0;
}

export {
  getSearchTerm ,
  getSearchType ,
  getSearchPodcast ,
  getSearchModel ,
  hasSearchTerm ,
  
}
/* No side effect */
