// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as Routing$ReactTemplate from "../utils/Routing.bs.js";
import * as RouterLink$ReactTemplate from "./RouterLink.bs.js";

var btn = Css.style(/* :: */[
      Css.minWidth(/* auto */-1065951377),
      /* [] */0
    ]);

function hideMobile(theme) {
  return Css.style(/* :: */[
              Css.display(/* none */-922086728),
              /* :: */[
                Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                      Css.display(/* inlineFlex */53323314),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]);
}

function iconBtn(theme) {
  return Css.style(/* :: */[
              Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                    Css.marginRight(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
                    /* [] */0
                  ]),
              /* [] */0
            ]);
}

var Styles$1 = /* module */[
  /* btn */btn,
  /* hideMobile */hideMobile,
  /* iconBtn */iconBtn
];

function IconLink(Props) {
  var match = Props.className;
  var className = match !== undefined ? match : "";
  var route = Props.route;
  var text = Props.text;
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement(RouterLink$ReactTemplate.make, {
              href: Routing$ReactTemplate.getUrlFromRoute(route),
              className: className,
              children: React.createElement(Core.Button, MaterialUi_Button.makeProps(Caml_option.some(null), btn, /* Inherit */-72987685, /* `String */[
                        -976970511,
                        "span"
                      ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), children, React.createElement("span", {
                        className: hideMobile(theme)
                      }, text))
            });
}

var make = IconLink;

export {
  Styles$1 as Styles,
  make ,
  
}
/* btn Not a pure module */
