// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Jss from "jss";
import * as React from "react";
import * as Styles from "@material-ui/styles";

var StylesProvider = /* module */[];

var preset = Styles.jssPreset();

var jssCustomPreset = ({  ...preset, insertionPoint: "jss-insertion-point" } );

var jss = Jss.create(jssCustomPreset);

function Jss$JssProvider(Props) {
  var children = Props.children;
  return React.createElement(Styles.StylesProvider, {
              jss: jss,
              children: children
            });
}

var JssProvider = /* module */[
  /* preset */preset,
  /* jssCustomPreset */jssCustomPreset,
  /* jss */jss,
  /* make */Jss$JssProvider
];

export {
  StylesProvider ,
  JssProvider ,
  
}
/* preset Not a pure module */
