// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Cards$ReactTemplate from "./Cards.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as ActionButton$ReactTemplate from "./ActionButton.bs.js";
import * as UserIdentity$ReactTemplate from "../UserIdentity.bs.js";
import * as UseSaveEpisode$ReactTemplate from "../hooks/UseSaveEpisode.bs.js";
import * as UseRemoveEpisode$ReactTemplate from "../hooks/UseRemoveEpisode.bs.js";
import * as EpisodeItunesLink$ReactTemplate from "./EpisodeItunesLink.bs.js";

function str(prim) {
  return prim;
}

function EpisodeCard$EpisodeLoggedInButton(Props) {
  var userId = Props.userId;
  var episode = Props.episode;
  var isSaved = Props.isSaved;
  var match = UseSaveEpisode$ReactTemplate.useSaveEpisode(userId, episode);
  var onSave = match[0];
  var match$1 = UseRemoveEpisode$ReactTemplate.useRemoveEpisode(userId, episode[/* id */0], undefined, /* () */0);
  var onRemove = match$1[0];
  if (isSaved) {
    return React.createElement(ActionButton$ReactTemplate.make, {
                disabled: match$1[1] === /* Loading */0,
                onClick: (function (param) {
                    Curry._1(onRemove, /* () */0);
                    return /* () */0;
                  }),
                action: /* Remove */1
              });
  } else {
    return React.createElement(ActionButton$ReactTemplate.make, {
                disabled: match[1] === /* Loading */0,
                onClick: (function (param) {
                    Curry._1(onSave, /* () */0);
                    return /* () */0;
                  }),
                action: /* Save */0
              });
  }
}

var EpisodeLoggedInButton = /* module */[/* make */EpisodeCard$EpisodeLoggedInButton];

function EpisodeCard(Props) {
  var episode = Props.episode;
  var isSaved = Props.isSaved;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var identity = Curry._1(UserIdentity$ReactTemplate.Context[/* useIdentityContext */0], /* () */0);
  var user = UserIdentity$ReactTemplate.getLoggedInUser(identity);
  return React.createElement(Cards$ReactTemplate.SearchCard[/* make */0], {
              isSaved: isSaved,
              children: null
            }, React.createElement(Cards$ReactTemplate.EpisodeCardContent[/* make */0], {
                  title: episode[/* title */1],
                  image: episode[/* image */10],
                  description: episode[/* description */2],
                  lengthSec: episode[/* lengthSec */5],
                  pubDate: episode[/* pubDate */4],
                  podcastTitle: episode[/* podcastTitle */3]
                }), React.createElement(Cards$ReactTemplate.CardActions[/* make */0], {
                  children: null
                }, React.createElement(EpisodeItunesLink$ReactTemplate.make, {
                      podcastItunesId: episode[/* podcastItunesId */6],
                      episodeName: episode[/* title */1]
                    }), user ? React.createElement(EpisodeCard$EpisodeLoggedInButton, {
                        userId: user[0],
                        episode: episode,
                        isSaved: isSaved
                      }) : React.createElement(ActionButton$ReactTemplate.make, {
                        onClick: (function (param) {
                            Curry._1(dispatch, /* OnUnauthorizedAccess */0);
                            return /* () */0;
                          }),
                        action: /* Save */0
                      })));
}

var make = EpisodeCard;

export {
  str ,
  EpisodeLoggedInButton ,
  make ,
  
}
/* react Not a pure module */
