// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as Url$ReactTemplate from "./Url.bs.js";
import * as SearchQuery$ReactTemplate from "../types/SearchQuery.bs.js";

function urlToRoute(url) {
  var match = url[/* path */0];
  var exit = 0;
  if (match) {
    switch (match[0]) {
      case "my-library" : 
          var match$1 = match[1];
          if (match$1) {
            var match$2 = match$1[1];
            if (match$2 && match$2[0] === "episodes" && !match$2[1]) {
              return /* MyEpisodes */Block.__(0, [match$1[0]]);
            } else {
              return /* NotFound */2;
            }
          } else {
            return /* MyLibrary */0;
          }
      case "search" : 
          if (match[1]) {
            return /* NotFound */2;
          } else {
            exit = 1;
          }
          break;
      default:
        return /* NotFound */2;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var search = url[/* search */2];
    if (search === "") {
      return /* Search */Block.__(1, [SearchQuery$ReactTemplate.make(undefined, /* () */0)]);
    } else {
      return /* Search */Block.__(1, [SearchQuery$ReactTemplate.Url[/* parse */1](search)]);
    }
  }
  
}

function useAppUrl(param) {
  return urlToRoute(ReasonReactRouter.useUrl(undefined, /* () */0));
}

function getUrlFromRoute(route) {
  if (typeof route === "number") {
    switch (route) {
      case 0 : 
          return "/my-library";
      case 1 : 
          return "/search";
      case 2 : 
          return "/";
      
    }
  } else if (route.tag) {
    var params = SearchQuery$ReactTemplate.Url[/* stringify */2](route[0]);
    return Url$ReactTemplate.Params[/* buildUrl */3]("/search", params);
  } else {
    return "my-library/" + (String(route[0]) + "/my-episodes");
  }
}

function pushRoute(route) {
  return ReasonReactRouter.replace(getUrlFromRoute(route));
}

export {
  urlToRoute ,
  useAppUrl ,
  getUrlFromRoute ,
  pushRoute ,
  
}
/* ReasonReactRouter Not a pure module */
