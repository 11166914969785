// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Spinner$ReactTemplate from "../components/Spinner.bs.js";
import * as PageTitle$ReactTemplate from "../components/PageTitle.bs.js";
import * as Query$ReasonApolloHooks from "reason-apollo-hooks/src/Query.bs.js";
import * as ContentTabs$ReactTemplate from "../components/ContentTabs.bs.js";
import * as LibraryGrid$ReactTemplate from "../components/LibraryGrid.bs.js";
import * as LibraryTypes$ReactTemplate from "../types/LibraryTypes.bs.js";
import * as MyEpisodeCard$ReactTemplate from "../components/MyEpisodeCard.bs.js";
import * as MyPodcastCard$ReactTemplate from "../components/MyPodcastCard.bs.js";
import * as PageContainer$ReactTemplate from "../components/PageContainer.bs.js";
import * as LibraryQueries$ReactTemplate from "../graphql/LibraryQueries.bs.js";

function str(prim) {
  return prim;
}

var GetAllPodcastsQuery = Query$ReasonApolloHooks.Make([
      LibraryQueries$ReactTemplate.GetAllPodcasts[1],
      LibraryQueries$ReactTemplate.GetAllPodcasts[2]
    ]);

var GetAllEpisodesQuery = Query$ReasonApolloHooks.Make([
      LibraryQueries$ReactTemplate.GetAllEpisodes[1],
      LibraryQueries$ReactTemplate.GetAllEpisodes[2]
    ]);

function MyLibraryPage(Props) {
  var userId = Props.userId;
  var match = React.useState((function () {
          return /* Episode */0;
        }));
  var setActiveType = match[1];
  var activeType = match[0];
  var getAllPodcats = LibraryQueries$ReactTemplate.GetAllPodcasts[/* make */3](userId, /* () */0);
  var match$1 = Curry._8(GetAllPodcastsQuery[/* use */0], Caml_option.some(getAllPodcats.variables), undefined, undefined, /* NetworkOnly */2, undefined, undefined, undefined, /* () */0);
  var podcastsResponse = match$1[0];
  var getAllEpisodes = LibraryQueries$ReactTemplate.GetAllEpisodes[/* make */3](userId, /* () */0);
  var match$2 = Curry._8(GetAllEpisodesQuery[/* use */0], Caml_option.some(getAllEpisodes.variables), undefined, undefined, /* NetworkOnly */2, undefined, undefined, undefined, /* () */0);
  var episodesResponse = match$2[0];
  var tmp;
  tmp = activeType ? (
      typeof podcastsResponse === "number" ? (
          podcastsResponse === 0 ? React.createElement(Spinner$ReactTemplate.make, { }) : null
        ) : (
          podcastsResponse.tag ? React.createElement("div", undefined, "Error") : React.createElement(LibraryGrid$ReactTemplate.make, {
                  children: Belt_Array.map(Belt_Array.map(podcastsResponse[0].my_podcasts, LibraryTypes$ReactTemplate.toMyPodcast), (function (podcast) {
                          return React.createElement(MyPodcastCard$ReactTemplate.make, {
                                      id: podcast[/* id */0],
                                      description: podcast[/* description */2],
                                      title: podcast[/* title */1],
                                      publisher: podcast[/* publisher */4],
                                      itunesId: podcast[/* itunesId */3],
                                      image: podcast[/* image */5],
                                      userId: userId,
                                      addedDate: podcast[/* addedDate */6],
                                      key: podcast[/* id */0]
                                    });
                        }))
                })
        )
    ) : (
      typeof episodesResponse === "number" ? (
          episodesResponse === 0 ? React.createElement(Spinner$ReactTemplate.make, { }) : null
        ) : (
          episodesResponse.tag ? React.createElement("div", undefined, "Error") : React.createElement(LibraryGrid$ReactTemplate.make, {
                  children: Belt_Array.map(episodesResponse[0].my_episodes, (function (data) {
                          return React.createElement(MyEpisodeCard$ReactTemplate.make, {
                                      episode: LibraryTypes$ReactTemplate.toMyEpisode(data),
                                      userId: userId,
                                      podcastItunesId: data.episode.podcast.itunesId,
                                      key: data.episode.id
                                    });
                        }))
                })
        )
    );
  return React.createElement(PageContainer$ReactTemplate.make, {
              children: null
            }, React.createElement(PageTitle$ReactTemplate.make, {
                  title: "My library"
                }), React.createElement(ContentTabs$ReactTemplate.make, {
                  activeTab: activeType,
                  onTabChange: (function (contentType) {
                      return Curry._1(setActiveType, (function (param) {
                                    return contentType;
                                  }));
                    })
                }), tmp);
}

var make = MyLibraryPage;

export {
  str ,
  GetAllPodcastsQuery ,
  GetAllEpisodesQuery ,
  make ,
  
}
/* GetAllPodcastsQuery Not a pure module */
