// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as MaterialUi_Snackbar from "@jsiebern/bs-material-ui/src/MaterialUi_Snackbar.bs.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as MaterialUi_IconButton from "@jsiebern/bs-material-ui/src/MaterialUi_IconButton.bs.js";
import * as MaterialUi_Color_Green from "@jsiebern/bs-material-ui/src/MaterialUi_Color_Green.bs.js";
import * as MaterialUi_SnackbarContent from "@jsiebern/bs-material-ui/src/MaterialUi_SnackbarContent.bs.js";
import * as XJs from "react-feather/dist/icons/x.js";

var successNotification = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Utils$ReactTemplate.fromMuiColor(MaterialUi_Color_Green.c700))),
      /* [] */0
    ]);

function getErrorColor(theme) {
  return theme.palette.error.main;
}

function errorNotification(theme) {
  return Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Utils$ReactTemplate.fromMuiColor(theme.palette.error.main))),
              /* [] */0
            ]);
}

function snackbarRoot(theme) {
  return Css.style(/* :: */[
              Css.paddingLeft(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
              /* :: */[
                Css.paddingRight(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
                /* [] */0
              ]
            ]);
}

var snackbarContent = Css.style(/* :: */[
      Css.flexGrow(0.0),
      /* [] */0
    ]);

function typeToClassName(type_, theme) {
  switch (type_) {
    case 0 : 
        return "primary";
    case 1 : 
        return "secondary";
    case 2 : 
        return successNotification;
    case 3 : 
        return errorNotification(theme);
    case 4 : 
        return "warning";
    case 5 : 
        return "info";
    case 6 : 
        return "light";
    case 7 : 
        return "dark";
    
  }
}

var Styles$1 = /* module */[
  /* successNotification */successNotification,
  /* getErrorColor */getErrorColor,
  /* errorNotification */errorNotification,
  /* snackbarRoot */snackbarRoot,
  /* snackbarContent */snackbarContent,
  /* typeToClassName */typeToClassName
];

function $$Notification(Props) {
  var type_ = Props.type_;
  var id = Props.id;
  var style = Props.style;
  var children = Props.children;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var match = React.useState((function () {
          return true;
        }));
  var setOpen = match[1];
  var handleClose = function (param, reason) {
    if (reason !== "clickaway") {
      return Curry._1(setOpen, (function (param) {
                    return false;
                  }));
    } else {
      return 0;
    }
  };
  var theme = Styles.useTheme();
  return React.createElement(Core.Snackbar, MaterialUi_Snackbar.makeProps(undefined, Caml_option.some(MaterialUi_Snackbar.AnchorOrigin[/* make */0](/* Right */-57574468, /* Top */4202101)), /* `Int */[
                  3654863,
                  1500
                ], Caml_option.some(React.createElement(Core.SnackbarContent, MaterialUi_SnackbarContent.makeProps(Caml_option.some(React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(XJs.default, { })), undefined, /* Inherit */-72987685, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                            return Curry._1(setOpen, (function (param) {
                                                          return false;
                                                        }));
                                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), typeToClassName(type_, theme), Caml_option.some(children), undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                              /* Root */Block.__(0, [snackbarContent]),
                              /* [] */0
                            ], undefined, /* () */0))), snackbarRoot(theme), undefined, undefined, undefined, undefined, undefined, handleClose, undefined, undefined, undefined, undefined, (function (param) {
                    return Curry._1(dispatch, /* RemoveNotification */Block.__(1, [id]));
                  }), undefined, undefined, undefined, match[0], undefined, undefined, undefined, undefined, undefined, undefined, style, /* () */0));
}

var autoHideDuration = 1500;

var make = $$Notification;

export {
  Styles$1 as Styles,
  autoHideDuration ,
  make ,
  
}
/* successNotification Not a pure module */
