// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../../utils/Utils.bs.js";
import * as MaterialUi_TextField from "@jsiebern/bs-material-ui/src/MaterialUi_TextField.bs.js";
import * as MaterialUi_FormControl from "@jsiebern/bs-material-ui/src/MaterialUi_FormControl.bs.js";
import * as UserLink$ReactTemplate from "./UserLink.bs.js";
import * as StateHooks$ReactTemplate from "../../hooks/StateHooks.bs.js";
import * as ErrorMessage$ReactTemplate from "./ErrorMessage.bs.js";
import * as UserFormData$ReactTemplate from "./UserFormData.bs.js";
import * as UserIdentity$ReactTemplate from "../../UserIdentity.bs.js";
import * as IdentityDialogStyles$ReactTemplate from "./IdentityDialogStyles.bs.js";

function str(prim) {
  return prim;
}

function Login(Props) {
  var gotoForgotPassword = Props.gotoForgotPassword;
  var onLogin = Props.onLogin;
  var theme = Styles.useTheme();
  var match = StateHooks$ReactTemplate.useReducerSafe(UserFormData$ReactTemplate.reducer, UserFormData$ReactTemplate.initState);
  var dispatch = match[1];
  var state = match[0];
  var identity = Curry._1(UserIdentity$ReactTemplate.Context[/* useIdentityContext */0], /* () */0);
  var status = state[/* status */3];
  var password = state[/* password */1];
  var email = state[/* email */0];
  return React.createElement("form", {
              onSubmit: (function (e) {
                  e.preventDefault();
                  Curry._1(dispatch, /* SubmitRequest */0);
                  Curry._4(identity[/* loginUser */3], email, password, true, /* () */0).then((function (param) {
                            Curry._1(dispatch, /* SubmitSuccess */1);
                            return Promise.resolve(Curry._1(onLogin, /* () */0));
                          })).catch((function (error) {
                          return Promise.resolve(Curry._1(dispatch, /* SubmitError */Block.__(3, [error.message])));
                        }));
                  return /* () */0;
                })
            }, React.createElement(Core.FormControl, MaterialUi_FormControl.makeProps(Caml_option.some(React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, true, undefined, undefined, undefined, status === /* Submitting */1, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Email Address", undefined, undefined, "email", undefined, (function (e) {
                                    var value = Utils$ReactTemplate.getInputValue(e);
                                    return Curry._1(dispatch, /* SetEmail */Block.__(0, [value]));
                                  }), undefined, undefined, true, undefined, undefined, undefined, undefined, "email", /* `String */[
                                  -976970511,
                                  email
                                ], undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                      /* Root */Block.__(0, [IdentityDialogStyles$ReactTemplate.Styles[/* formElement */1](theme)]),
                      /* [] */0
                    ], undefined, /* () */0)), React.createElement(Core.FormControl, MaterialUi_FormControl.makeProps(Caml_option.some(React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, undefined, status === /* Submitting */1, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Password", undefined, undefined, "password", undefined, (function (e) {
                                    var value = Utils$ReactTemplate.getInputValue(e);
                                    return Curry._1(dispatch, /* SetPassword */Block.__(1, [value]));
                                  }), undefined, undefined, true, undefined, undefined, undefined, undefined, "password", /* `String */[
                                  -976970511,
                                  password
                                ], undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                      /* Root */Block.__(0, [IdentityDialogStyles$ReactTemplate.Styles[/* formElement */1](theme)]),
                      /* [] */0
                    ], undefined, /* () */0)), React.createElement(Core.FormControl, MaterialUi_FormControl.makeProps(Caml_option.some(React.createElement(Core.Button, MaterialUi_Button.makeProps("Log in", undefined, /* Primary */-791844958, undefined, status === /* Submitting */1, undefined, undefined, undefined, true, undefined, undefined, "submit", /* Contained */-515484397, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), IdentityDialogStyles$ReactTemplate.Styles[/* submitButton */0](theme), undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                      /* Root */Block.__(0, [IdentityDialogStyles$ReactTemplate.Styles[/* formElement */1](theme)]),
                      /* [] */0
                    ], undefined, /* () */0)), typeof status === "number" ? null : React.createElement(ErrorMessage$ReactTemplate.make, {
                    message: status[0]
                  }), React.createElement(UserLink$ReactTemplate.make, {
                  onClick: gotoForgotPassword,
                  children: "Forgot password"
                }));
}

var make = Login;

export {
  str ,
  make ,
  
}
/* react Not a pure module */
