// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Menu from "@jsiebern/bs-material-ui/src/MaterialUi_Menu.bs.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Divider from "@jsiebern/bs-material-ui/src/MaterialUi_Divider.bs.js";
import * as MaterialUi_MenuItem from "@jsiebern/bs-material-ui/src/MaterialUi_MenuItem.bs.js";
import * as MaterialUi_Typography from "@jsiebern/bs-material-ui/src/MaterialUi_Typography.bs.js";
import * as Routing$ReactTemplate from "../../utils/Routing.bs.js";
import * as MaterialUi_ListItemIcon from "@jsiebern/bs-material-ui/src/MaterialUi_ListItemIcon.bs.js";
import * as MaterialUi_ListItemText from "@jsiebern/bs-material-ui/src/MaterialUi_ListItemText.bs.js";
import * as RouterLink$ReactTemplate from "../RouterLink.bs.js";
import * as StateHooks$ReactTemplate from "../../hooks/StateHooks.bs.js";
import * as UserIdentity$ReactTemplate from "../../UserIdentity.bs.js";
import * as LogOutJs from "react-feather/dist/icons/log-out.js";
import * as BookmarkJs from "react-feather/dist/icons/bookmark.js";

function UserMenu(Props) {
  var anchorEl = Props.anchorEl;
  var onLogout = Props.onLogout;
  var onClose = Props.onClose;
  var identity = Curry._1(UserIdentity$ReactTemplate.Context[/* useIdentityContext */0], /* () */0);
  var match = StateHooks$ReactTemplate.useStateSafe((function (param) {
          return /* NotAsked */0;
        }));
  var setStatus = match[1];
  var userName = Belt_Option.map(Belt_Option.flatMap(identity[/* user */0], (function (user) {
              return user[/* metaData */1];
            })), (function (data) {
          return data[/* fullName */0];
        }));
  var handleLogout = function (param) {
    Curry._1(setStatus, (function (param) {
            return /* Submitting */1;
          }));
    Curry._1(identity[/* logoutUser */4], /* () */0).then((function (param) {
              Curry._1(setStatus, (function (param) {
                      return /* Success */2;
                    }));
              return Promise.resolve(Curry._1(onLogout, /* () */0));
            })).catch((function (error) {
            return Promise.resolve(Curry._1(setStatus, (function (param) {
                              return /* Error */[error.message];
                            })));
          }));
    return /* () */0;
  };
  return React.createElement(Core.Menu, MaterialUi_Menu.makeProps(Caml_option.some(anchorEl), undefined, Caml_option.some(null), undefined, undefined, onClose, undefined, undefined, undefined, undefined, undefined, undefined, Belt_Option.isSome(anchorEl), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_Menu.AnchorOrigin[/* make */0](/* `Enum */[
                          770676513,
                          /* Center */980392437
                        ], /* `Enum */[
                          770676513,
                          /* Bottom */437082891
                        ])), undefined, undefined, undefined, undefined, Caml_option.some(undefined), undefined, undefined, Caml_option.some(MaterialUi_Menu.TransformOrigin[/* make */0](/* `Enum */[
                          770676513,
                          /* Center */980392437
                        ], /* `Enum */[
                          770676513,
                          /* Top */4202101
                        ])), undefined, undefined, undefined, undefined, /* () */0), userName !== undefined ? React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(null), Css.style(/* :: */[
                            Css.flexWrap(/* wrap */-822134326),
                            /* [] */0
                          ]), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Logged in as:", undefined, undefined, /* `String */[
                            -976970511,
                            "div"
                          ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(userName), Css.style(/* :: */[
                                Css.fontWeight(/* `num */[
                                      5496390,
                                      500
                                    ]),
                                /* :: */[
                                  Css.width(Css.pct(100.0)),
                                  /* [] */0
                                ]
                              ]), undefined, /* `String */[
                            -976970511,
                            "div"
                          ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))) : null, React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, /* FullWidth */-382069289, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(null), undefined, undefined, undefined, match[0] === /* Submitting */1, undefined, undefined, undefined, undefined, undefined, undefined, handleLogout, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(Core.ListItemIcon, MaterialUi_ListItemIcon.makeProps(Caml_option.some(React.createElement(LogOutJs.default, { })), undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.ListItemText, MaterialUi_ListItemText.makeProps(undefined, undefined, undefined, undefined, "Log out", undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, /* FullWidth */-382069289, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(RouterLink$ReactTemplate.make, {
                  href: Routing$ReactTemplate.getUrlFromRoute(/* MyLibrary */0),
                  children: React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(Core.ListItemIcon, MaterialUi_ListItemIcon.makeProps(Caml_option.some(React.createElement(BookmarkJs.default, { })), undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.ListItemText, MaterialUi_ListItemText.makeProps(undefined, undefined, undefined, undefined, "My Library", undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)))
                }));
}

var make = UserMenu;

export {
  make ,
  
}
/* Css Not a pure module */
