// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Tab from "@jsiebern/bs-material-ui/src/MaterialUi_Tab.bs.js";
import * as MaterialUi_Tabs from "@jsiebern/bs-material-ui/src/MaterialUi_Tabs.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";

function ContentTabs(Props) {
  var activeTab = Props.activeTab;
  var onTabChange = Props.onTabChange;
  var theme = Styles.useTheme();
  var contentTabCss = Css.style(/* :: */[
        Css.flexGrow(1.0),
        /* :: */[
          Css.flexShrink(1.0),
          /* :: */[
            Css.flexBasis(/* zero */-789508312),
            /* :: */[
              Css.maxWidth(/* none */-922086728),
              /* :: */[
                Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                      Css.flex(/* `num */[
                            5496390,
                            0.0
                          ]),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]);
  var match = activeTab === /* Episode */0;
  return React.createElement(Core.Tabs, MaterialUi_Tabs.makeProps(undefined, true, Caml_option.some(null), Css.style(/* :: */[
                      Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                      /* [] */0
                    ]), undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, /* Primary */-791844958, match ? 0 : 1, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(Core.Tab, MaterialUi_Tab.makeProps(undefined, contentTabCss, undefined, undefined, undefined, undefined, undefined, undefined, "Episodes", undefined, (function (param) {
                        return Curry._1(onTabChange, /* Episode */0);
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.Tab, MaterialUi_Tab.makeProps(undefined, contentTabCss, undefined, undefined, undefined, undefined, undefined, undefined, "Podcasts", undefined, (function (param) {
                        return Curry._1(onTabChange, /* Podcast */1);
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)));
}

var make = ContentTabs;

export {
  make ,
  
}
/* Css Not a pure module */
