// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE


function reducer(state, action) {
  if (typeof action === "number") {
    if (action === 0) {
      return /* record */[
              /* email */state[/* email */0],
              /* password */state[/* password */1],
              /* fullName */state[/* fullName */2],
              /* status : Submitting */1
            ];
    } else {
      return /* record */[
              /* email */state[/* email */0],
              /* password */state[/* password */1],
              /* fullName */state[/* fullName */2],
              /* status : Success */2
            ];
    }
  } else {
    switch (action.tag | 0) {
      case 0 : 
          return /* record */[
                  /* email */action[0],
                  /* password */state[/* password */1],
                  /* fullName */state[/* fullName */2],
                  /* status */state[/* status */3]
                ];
      case 1 : 
          return /* record */[
                  /* email */state[/* email */0],
                  /* password */action[0],
                  /* fullName */state[/* fullName */2],
                  /* status */state[/* status */3]
                ];
      case 2 : 
          return /* record */[
                  /* email */state[/* email */0],
                  /* password */state[/* password */1],
                  /* fullName */action[0],
                  /* status */state[/* status */3]
                ];
      case 3 : 
          return /* record */[
                  /* email */state[/* email */0],
                  /* password */state[/* password */1],
                  /* fullName */state[/* fullName */2],
                  /* status : Error */[action[0]]
                ];
      
    }
  }
}

var initState = /* record */[
  /* email */"",
  /* password */"",
  /* fullName */"",
  /* status : NotAsked */0
];

export {
  initState ,
  reducer ,
  
}
/* No side effect */
