// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Tab from "@jsiebern/bs-material-ui/src/MaterialUi_Tab.bs.js";
import * as MaterialUi_Tabs from "@jsiebern/bs-material-ui/src/MaterialUi_Tabs.bs.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Dialog from "@jsiebern/bs-material-ui/src/MaterialUi_Dialog.bs.js";
import * as Styles from "@material-ui/styles";
import * as Login$ReactTemplate from "./Login.bs.js";
import * as Utils$ReactTemplate from "../../utils/Utils.bs.js";
import * as Signup$ReactTemplate from "./Signup.bs.js";
import * as MaterialUi_IconButton from "@jsiebern/bs-material-ui/src/MaterialUi_IconButton.bs.js";
import * as MaterialUi_DialogTitle from "@jsiebern/bs-material-ui/src/MaterialUi_DialogTitle.bs.js";
import * as Providers$ReactTemplate from "./Providers.bs.js";
import * as MaterialUi_DialogContent from "@jsiebern/bs-material-ui/src/MaterialUi_DialogContent.bs.js";
import * as UserIdentity$ReactTemplate from "../../UserIdentity.bs.js";
import * as ForgotPassword$ReactTemplate from "./ForgotPassword.bs.js";
import * as XJs from "react-feather/dist/icons/x.js";

function str(prim) {
  return prim;
}

function dialog(theme) {
  return Css.style(/* :: */[
              Css.width(Css.vw(90.0)),
              /* :: */[
                Css.margin2(Css.vh(10.0), /* auto */-1065951377),
                /* :: */[
                  Css.important(Css.maxWidth(Css.px(400))),
                  /* :: */[
                    Css.padding(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                    /* :: */[
                      Css.paddingTop(Css.px(Utils$ReactTemplate.spacingPx(4, theme))),
                      /* :: */[
                        Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                              Css.width(Css.vw(50.0)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function tab(theme) {
  return Css.style(/* :: */[
              Css.minHeight(Css.px(Caml_int32.imul(Utils$ReactTemplate.spacingPx(1, theme), 3))),
              /* [] */0
            ]);
}

var title = Css.style(/* :: */[
      Css.paddingLeft(Css.px(0)),
      /* [] */0
    ]);

function tabRoot(theme) {
  return Css.style(/* :: */[
              Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
              /* :: */[
                Css.minHeight(Css.px(Caml_int32.imul(Utils$ReactTemplate.spacingPx(1, theme), 3))),
                /* [] */0
              ]
            ]);
}

function closeButton(theme) {
  return Css.style(/* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.right(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
                /* :: */[
                  Css.top(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
                  /* [] */0
                ]
              ]
            ]);
}

var Styles$1 = /* module */[
  /* dialog */dialog,
  /* tab */tab,
  /* title */title,
  /* tabRoot */tabRoot,
  /* closeButton */closeButton
];

function IdentityDialog(Props) {
  var open_ = Props.open_;
  var onLogin = Props.onLogin;
  var onClose = Props.onClose;
  var theme = Styles.useTheme();
  var match = React.useState((function () {
          return /* Login */0;
        }));
  var setActiveView = match[1];
  var activeView = match[0];
  var identity = Curry._1(UserIdentity$ReactTemplate.Context[/* useIdentityContext */0], /* () */0);
  React.useLayoutEffect((function () {
          if (open_) {
            Curry._1(setActiveView, (function (param) {
                    return /* Login */0;
                  }));
          }
          return undefined;
        }), /* array */[open_]);
  var hasProviders = identity[/* settings */12][/* providers */2].length !== 0;
  var tmp;
  if (activeView >= 2) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.DialogTitle, MaterialUi_DialogTitle.makeProps("Recover password", title, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(ForgotPassword$ReactTemplate.make, {
              gotoLogin: (function (param) {
                  return Curry._1(setActiveView, (function (param) {
                                return /* Login */0;
                              }));
                })
            }));
  } else {
    var match$1 = activeView === /* Login */0;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Tabs, MaterialUi_Tabs.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, /* Primary */-791844958, undefined, undefined, undefined, undefined, undefined, /* Primary */-791844958, activeView, /* FullWidth */-382069289, undefined, undefined, /* :: */[
                  /* Root */Block.__(0, [tabRoot(theme)]),
                  /* [] */0
                ], undefined, /* () */0), React.createElement(Core.Tab, MaterialUi_Tab.makeProps(undefined, tab(theme), undefined, undefined, undefined, undefined, undefined, undefined, "Log in", undefined, (function (param) {
                        return Curry._1(setActiveView, (function (param) {
                                      return /* Login */0;
                                    }));
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.Tab, MaterialUi_Tab.makeProps(undefined, tab(theme), undefined, undefined, undefined, undefined, undefined, undefined, "Sign up", undefined, (function (param) {
                        return Curry._1(setActiveView, (function (param) {
                                      return /* Signup */1;
                                    }));
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), match$1 ? React.createElement(Login$ReactTemplate.make, {
                gotoForgotPassword: (function (param) {
                    return Curry._1(setActiveView, (function (param) {
                                  return /* ForgotPassword */2;
                                }));
                  }),
                onLogin: onLogin
              }) : React.createElement(Signup$ReactTemplate.make, { }), hasProviders ? React.createElement(Providers$ReactTemplate.make, { }) : null);
  }
  return React.createElement(Core.Dialog, MaterialUi_Dialog.makeProps(undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                    return Curry._1(onClose, /* () */0);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, open_, undefined, undefined, /* Body */737453762, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                  /* PaperScrollBody */Block.__(6, [dialog(theme)]),
                  /* [] */0
                ], undefined, /* () */0), React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(XJs.default, { })), closeButton(theme), /* Inherit */-72987685, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                        return Curry._1(onClose, /* () */0);
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(Core.DialogContent, MaterialUi_DialogContent.makeProps(Caml_option.some(tmp), undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)));
}

var make = IdentityDialog;

export {
  str ,
  Styles$1 as Styles,
  make ,
  
}
/* title Not a pure module */
