// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as AppCore$ReactTemplate from "./models/AppCore.bs.js";
import * as Notification$ReactTemplate from "./components/Notification.bs.js";

function getNotifications(model) {
  return model[/* notifications */2][/* items */0];
}

function RootNotifications(Props) {
  var notifications = Curry._1(AppCore$ReactTemplate.useSelector, getNotifications);
  var containerRef = React.useRef(null);
  var getTopScroll = function (param) {
    var match = (document.scrollingElement.scrollTop | 0) < 10;
    if (match) {
      return 75;
    } else {
      return 20;
    }
  };
  var makeStyle = function (ind) {
    var match = ind === 0;
    return {
            top: match ? String(getTopScroll(/* () */0)) + "px" : String(Caml_int32.imul(ind, 65) + getTopScroll(/* () */0) | 0) + "px"
          };
  };
  return React.createElement("div", {
              ref: containerRef
            }, Belt_Array.mapWithIndex(notifications, (function (ind, item) {
                    return React.createElement(Notification$ReactTemplate.make, {
                                type_: item[/* data */1][/* type_ */1],
                                id: item[/* id */0],
                                style: makeStyle(ind),
                                children: item[/* data */1][/* text */0],
                                key: String(item[/* id */0])
                              });
                  })));
}

var make = RootNotifications;

export {
  getNotifications ,
  make ,
  
}
/* react Not a pure module */
