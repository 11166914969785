// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as LibraryCache$ReactTemplate from "../graphql/LibraryCache.bs.js";
import * as Mutation$ReasonApolloHooks from "reason-apollo-hooks/src/Mutation.bs.js";
import * as LibraryMutations$ReactTemplate from "../graphql/LibraryMutations.bs.js";

var SavePodcastMutation = Mutation$ReasonApolloHooks.Make([
      LibraryMutations$ReactTemplate.SavePodcast[1],
      LibraryMutations$ReactTemplate.SavePodcast[2]
    ]);

function useSavePodcast(userId, podcast) {
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var match = Curry._6(SavePodcastMutation[/* use */0], undefined, undefined, undefined, undefined, (function (client, mutationResult) {
          Belt_Option.map(mutationResult.data, (function (result) {
                  return LibraryCache$ReactTemplate.addPodcastToCache(userId, client, result);
                }));
          return /* () */0;
        }), /* () */0);
  var savePodcast = match[0];
  var handleSave = function (param) {
    var mutation = LibraryMutations$ReactTemplate.makeSavePodcastMutation(podcast, userId);
    return Curry._5(savePodcast, Caml_option.some(mutation.variables), undefined, undefined, undefined, /* () */0).then((function (param) {
                    return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                        /* text */"Saved to library",
                                        /* type_ : Success */2
                                      ]])));
                  })).catch((function (param) {
                  return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                      /* text */"Failed to save",
                                      /* type_ : Error */3
                                    ]])));
                }));
  };
  return /* tuple */[
          handleSave,
          match[1]
        ];
}

export {
  SavePodcastMutation ,
  useSavePodcast ,
  
}
/* SavePodcastMutation Not a pure module */
