// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Js_mapperRt from "bs-platform/lib/es6/js_mapperRt.js";
import * as MaterialUi_Helpers from "@jsiebern/bs-material-ui/src/MaterialUi_Helpers.bs.js";

var jsMapperConstantArray = /* array */[
  /* tuple */[
    -1020341643,
    "textPrimary"
  ],
  /* tuple */[
    -791844958,
    "primary"
  ],
  /* tuple */[
    -507693849,
    "textSecondary"
  ],
  /* tuple */[
    -384499551,
    "default"
  ],
  /* tuple */[
    -72987685,
    "inherit"
  ],
  /* tuple */[
    67972948,
    "secondary"
  ],
  /* tuple */[
    106380200,
    "error"
  ]
];

function colorToJs(param) {
  return Js_mapperRt.binarySearch(7, param, jsMapperConstantArray);
}

function colorFromJs(param) {
  return Js_mapperRt.revSearch(7, jsMapperConstantArray, param);
}

var jsMapperConstantArray$1 = /* array */[
  /* tuple */[
    -76840209,
    "always"
  ],
  /* tuple */[
    870530776,
    "none"
  ],
  /* tuple */[
    1049777564,
    "hover"
  ]
];

function underlineToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$1);
}

function underlineFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$1, param);
}

var jsMapperConstantArray$2 = /* array */[
  /* tuple */[
    -788068560,
    "justify"
  ],
  /* tuple */[
    -72987685,
    "inherit"
  ],
  /* tuple */[
    -57574468,
    "right"
  ],
  /* tuple */[
    847852583,
    "left"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function alignToJs(param) {
  return Js_mapperRt.binarySearch(5, param, jsMapperConstantArray$2);
}

function alignFromJs(param) {
  return Js_mapperRt.revSearch(5, jsMapperConstantArray$2, param);
}

var jsMapperConstantArray$3 = /* array */[
  /* tuple */[
    -936778451,
    "block"
  ],
  /* tuple */[
    -841728391,
    "inline"
  ],
  /* tuple */[
    418396260,
    "initial"
  ]
];

function displayToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$3);
}

function displayFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$3, param);
}

function to_string(param) {
  switch (param.tag | 0) {
    case 0 : 
        return "root";
    case 1 : 
        return "underlineNone";
    case 2 : 
        return "underlineHover";
    case 3 : 
        return "underlineAlways";
    case 4 : 
        return "button";
    case 5 : 
        return "focusVisible";
    
  }
}

function to_obj(listOfClasses) {
  return Belt_List.reduce(listOfClasses, { }, (function (obj, classType) {
                obj[to_string(classType)] = classType[0];
                return obj;
              }));
}

var Classes = /* module */[
  /* to_string */to_string,
  /* to_obj */to_obj
];

function makeProps(children, className, color, component, onBlur, onFocus, _TypographyClasses, underline, variant, key, ref_, align, display, gutterBottom, noWrap, paragraph, theme, variantMapping, classes, style, onClick, href, param) {
  var tmp = { };
  if (children !== undefined) {
    tmp.children = Caml_option.valFromOption(children);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  var tmp$1 = Belt_Option.map(color, colorToJs);
  if (tmp$1 !== undefined) {
    tmp.color = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(component, MaterialUi_Helpers.unwrapValue);
  if (tmp$2 !== undefined) {
    tmp.component = Caml_option.valFromOption(tmp$2);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (_TypographyClasses !== undefined) {
    tmp.TypographyClasses = Caml_option.valFromOption(_TypographyClasses);
  }
  var tmp$3 = Belt_Option.map(underline, underlineToJs);
  if (tmp$3 !== undefined) {
    tmp.underline = Caml_option.valFromOption(tmp$3);
  }
  if (variant !== undefined) {
    tmp.variant = Caml_option.valFromOption(variant);
  }
  if (key !== undefined) {
    tmp.key = Caml_option.valFromOption(key);
  }
  if (ref_ !== undefined) {
    tmp._ref = Caml_option.valFromOption(ref_);
  }
  var tmp$4 = Belt_Option.map(align, alignToJs);
  if (tmp$4 !== undefined) {
    tmp.align = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = Belt_Option.map(display, displayToJs);
  if (tmp$5 !== undefined) {
    tmp.display = Caml_option.valFromOption(tmp$5);
  }
  if (gutterBottom !== undefined) {
    tmp.gutterBottom = Caml_option.valFromOption(gutterBottom);
  }
  if (noWrap !== undefined) {
    tmp.noWrap = Caml_option.valFromOption(noWrap);
  }
  if (paragraph !== undefined) {
    tmp.paragraph = Caml_option.valFromOption(paragraph);
  }
  if (theme !== undefined) {
    tmp.theme = Caml_option.valFromOption(theme);
  }
  if (variantMapping !== undefined) {
    tmp.variantMapping = Caml_option.valFromOption(variantMapping);
  }
  var tmp$6 = Belt_Option.map(classes, to_obj);
  if (tmp$6 !== undefined) {
    tmp.classes = Caml_option.valFromOption(tmp$6);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (href !== undefined) {
    tmp.href = Caml_option.valFromOption(href);
  }
  return tmp;
}

export {
  colorToJs ,
  colorFromJs ,
  underlineToJs ,
  underlineFromJs ,
  alignToJs ,
  alignFromJs ,
  displayToJs ,
  displayFromJs ,
  Classes ,
  makeProps ,
  
}
/* No side effect */
