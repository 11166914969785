// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Graphql$ReactTemplate from "./Graphql.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var ppx_printed_query = "mutation ($listennotesId: String!, $itunesId: String!)  {\nupdate_episodes(where: {listennotesId: {_eq: $listennotesId}}, _set: {itunesId: $itunesId})  {\naffected_rows  \n}\n\n}\n";

function parse(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "update_episodes");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeNull(value$1);
      if (match$2 !== undefined) {
        tmp = undefined;
      } else {
        var match$3 = Js_json.decodeObject(value$1);
        var tmp$1;
        if (match$3 !== undefined) {
          var match$4 = Js_dict.get(Caml_option.valFromOption(match$3), "affected_rows");
          var tmp$2;
          if (match$4 !== undefined) {
            var value$2 = Caml_option.valFromOption(match$4);
            var match$5 = Js_json.decodeNumber(value$2);
            tmp$2 = match$5 !== undefined ? match$5 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$2));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field affected_rows on type episodes_mutation_response is missing");
          }
          tmp$1 = {
            affected_rows: tmp$2
          };
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Object is not a value");
        }
        tmp = Caml_option.some(tmp$1);
      }
    } else {
      tmp = undefined;
    }
    return {
            update_episodes: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make(listennotesId, itunesId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "listennotesId",
                  listennotesId
                ],
                /* tuple */[
                  "itunesId",
                  itunesId
                ]
              ]),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var listennotesId = variables.listennotesId;
  var itunesId = variables.itunesId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "listennotesId",
                  listennotesId
                ],
                /* tuple */[
                  "itunesId",
                  itunesId
                ]
              ]),
          parse: parse
        };
}

function ret_type(f) {
  return /* module */[];
}

var MT_Ret = /* module */[];

var UpdateItunesId = /* module */[
  /* ppx_printed_query */ppx_printed_query,
  /* query */ppx_printed_query,
  /* parse */parse,
  /* make */make,
  /* makeWithVariables */makeWithVariables,
  /* ret_type */ret_type,
  /* MT_Ret */MT_Ret
];

var ppx_printed_query$1 = "query ($podcastItunesId: String!, $episodeName: String!)  {\nitunesEpisode(podcastId: $podcastItunesId, episodeName: $episodeName)  {\nid  \n}\n\n}\n";

function parse$1(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "itunesEpisode");
    var tmp;
    if (match$1 !== undefined) {
      var match$2 = Js_json.decodeObject(Caml_option.valFromOption(match$1));
      if (match$2 !== undefined) {
        var match$3 = Js_dict.get(Caml_option.valFromOption(match$2), "id");
        var tmp$1;
        if (match$3 !== undefined) {
          var value$1 = Caml_option.valFromOption(match$3);
          var match$4 = Js_json.decodeNull(value$1);
          if (match$4 !== undefined) {
            tmp$1 = undefined;
          } else {
            var match$5 = Js_json.decodeString(value$1);
            tmp$1 = match$5 !== undefined ? match$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
          }
        } else {
          tmp$1 = undefined;
        }
        tmp = {
          id: tmp$1
        };
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Object is not a value");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field itunesEpisode on type query_root is missing");
    }
    return {
            itunesEpisode: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$1(podcastItunesId, episodeName, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "podcastItunesId",
                  podcastItunesId
                ],
                /* tuple */[
                  "episodeName",
                  episodeName
                ]
              ]),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var podcastItunesId = variables.podcastItunesId;
  var episodeName = variables.episodeName;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "podcastItunesId",
                  podcastItunesId
                ],
                /* tuple */[
                  "episodeName",
                  episodeName
                ]
              ]),
          parse: parse$1
        };
}

function ret_type$1(f) {
  return /* module */[];
}

var MT_Ret$1 = /* module */[];

var GetEpisodeItunesId = /* module */[
  /* ppx_printed_query */ppx_printed_query$1,
  /* query */ppx_printed_query$1,
  /* parse */parse$1,
  /* make */make$1,
  /* makeWithVariables */makeWithVariables$1,
  /* ret_type */ret_type$1,
  /* MT_Ret */MT_Ret$1
];

function getItunesId(podcastItunesId, episodeName) {
  return Graphql$ReactTemplate.sendQuery(make$1(podcastItunesId, episodeName, /* () */0)).then((function (response) {
                var match = response.itunesEpisode.id;
                if (match !== undefined) {
                  return Promise.resolve(match);
                } else {
                  return Promise.reject(Caml_builtin_exceptions.not_found);
                }
              }));
}

function updateItunesId(podcastItunesId, episodeId, episodeName) {
  return getItunesId(podcastItunesId, episodeName).then((function (itunesId) {
                return Graphql$ReactTemplate.sendQuery(make(episodeId, itunesId, /* () */0)).then((function (response) {
                              var match = response.update_episodes;
                              if (match !== undefined) {
                                return Promise.resolve(Caml_option.valFromOption(match).affected_rows);
                              } else {
                                return Promise.resolve(0);
                              }
                            }));
              }));
}

export {
  UpdateItunesId ,
  GetEpisodeItunesId ,
  getItunesId ,
  updateItunesId ,
  
}
/* Graphql-ReactTemplate Not a pure module */
