// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Input from "@jsiebern/bs-material-ui/src/MaterialUi_Input.bs.js";
import * as Core from "@material-ui/core";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as MaterialUi_IconButton from "@jsiebern/bs-material-ui/src/MaterialUi_IconButton.bs.js";
import * as Selectors$ReactTemplate from "../models/Selectors.bs.js";
import * as SearchJs from "react-feather/dist/icons/search.js";

function SearchInput(Props) {
  var className = Props.className;
  var iconClassName = Props.iconClassName;
  var classes = Props.classes;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var globalSearchTerm = Curry._1(AppCore$ReactTemplate.useSelector, Selectors$ReactTemplate.getSearchTerm);
  var match = React.useState((function () {
          return "";
        }));
  var setSearchTerm = match[1];
  var searchTerm = match[0];
  React.useEffect((function () {
          Curry._1(setSearchTerm, (function (param) {
                  return globalSearchTerm;
                }));
          return undefined;
        }), /* array */[globalSearchTerm]);
  var handleSearchChange = function (e) {
    var value = Utils$ReactTemplate.getInputValue(e);
    return Curry._1(setSearchTerm, (function (param) {
                  return value;
                }));
  };
  var handleSearch = function (param) {
    return Curry._1(dispatch, /* EnteredSearchTerm */Block.__(3, [searchTerm]));
  };
  var handleKeyDown = function (e) {
    var match = Utils$ReactTemplate.onKeyDown(e);
    if (match !== 0) {
      return /* () */0;
    } else {
      return Curry._1(dispatch, /* EnteredSearchTerm */Block.__(3, [searchTerm]));
    }
  };
  return React.createElement(Core.Input, MaterialUi_Input.makeProps(undefined, undefined, className, undefined, undefined, undefined, Caml_option.some(React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(SearchJs.default, { })), iconClassName, undefined, undefined, undefined, undefined, undefined, /* Small */311976103, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleSearch, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Button */242538002, undefined, undefined, /* () */0))), undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleSearchChange, "Search...", undefined, undefined, undefined, undefined, undefined, "search", searchTerm, undefined, undefined, undefined, undefined, undefined, undefined, handleKeyDown, undefined, undefined, undefined, classes, undefined, /* () */0));
}

var make = SearchInput;

export {
  make ,
  
}
/* react Not a pure module */
