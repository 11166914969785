// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";

function LibraryGrid(Props) {
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* grid */-999565626),
                    /* :: */[
                      Css.gridGap(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                      /* :: */[
                        Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                        /* :: */[
                          Css.marginTop(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var make = LibraryGrid;

export {
  make ,
  
}
/* Css Not a pure module */
