// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as Spinner$ReactTemplate from "../components/Spinner.bs.js";
import * as Query$ReasonApolloHooks from "reason-apollo-hooks/src/Query.bs.js";
import * as RouterLink$ReactTemplate from "../components/RouterLink.bs.js";
import * as LibraryTypes$ReactTemplate from "../types/LibraryTypes.bs.js";
import * as MyEpisodeCard$ReactTemplate from "../components/MyEpisodeCard.bs.js";
import * as PageContainer$ReactTemplate from "../components/PageContainer.bs.js";
import * as LibraryQueries$ReactTemplate from "../graphql/LibraryQueries.bs.js";

var MyEpisodesQuery = Query$ReasonApolloHooks.Make([
      LibraryQueries$ReactTemplate.GetMyEpisodes[1],
      LibraryQueries$ReactTemplate.GetMyEpisodes[2]
    ]);

function str(prim) {
  return prim;
}

function MyEpisodesPage(Props) {
  var podcastId = Props.podcastId;
  var userId = Props.userId;
  var getMyEpisodes = LibraryQueries$ReactTemplate.GetMyEpisodes[/* make */3](userId, podcastId, /* () */0);
  var match = Curry._8(MyEpisodesQuery[/* use */0], Caml_option.some(getMyEpisodes.variables), undefined, undefined, /* NetworkOnly */2, undefined, undefined, undefined, /* () */0);
  var simple = match[0];
  var tmp;
  if (typeof simple === "number") {
    tmp = simple === 0 ? React.createElement(Spinner$ReactTemplate.make, { }) : null;
  } else if (simple.tag) {
    tmp = React.createElement("div", undefined, "Error");
  } else {
    var response = simple[0];
    if (response.my_episodes.length === 0) {
      ReasonReactRouter.replace("/my-library");
    }
    var podcast = Belt_Array.get(response.podcasts, 0);
    var podcastItunesId = Belt_Option.mapWithDefault(podcast, undefined, (function (data) {
            return data.itunesId;
          }));
    tmp = React.createElement(PageContainer$ReactTemplate.make, {
          children: null
        }, React.createElement("div", undefined, podcast !== undefined ? React.createElement("h3", undefined, Caml_option.valFromOption(podcast).title) : null, React.createElement(RouterLink$ReactTemplate.make, {
                  href: "/my-library",
                  children: "Back"
                })), Belt_Array.map(Belt_Array.map(response.my_episodes, LibraryTypes$ReactTemplate.toMyEpisode), (function (episode) {
                return React.createElement(MyEpisodeCard$ReactTemplate.make, {
                            episode: episode,
                            userId: userId,
                            podcastItunesId: podcastItunesId,
                            key: episode[/* id */0]
                          });
              })));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("h1", undefined, "My episodes"), tmp);
}

var make = MyEpisodesPage;

export {
  MyEpisodesQuery ,
  str ,
  make ,
  
}
/* MyEpisodesQuery Not a pure module */
