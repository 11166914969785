// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";

function UserLink(Props) {
  var onClick = Props.onClick;
  var children = Props.children;
  var css = Css.style(/* :: */[
        Css.textDecoration(/* underline */131142924),
        /* :: */[
          Css.textTransform(/* none */-922086728),
          /* :: */[
            Css.padding(Css.px(0)),
            /* :: */[
              Css.hover(/* :: */[
                    Css.background(/* transparent */582626130),
                    /* :: */[
                      Css.textDecoration(/* underline */131142924),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]);
  return React.createElement(Core.Button, MaterialUi_Button.makeProps(Caml_option.some(children), undefined, /* Primary */-791844958, /* `String */[
                  -976970511,
                  "button"
                ], undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onClick, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                  /* TextPrimary */Block.__(3, [css]),
                  /* [] */0
                ], undefined, /* () */0));
}

var make = UserLink;

export {
  make ,
  
}
/* Css Not a pure module */
