// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as ReactNetlifyIdentity from "bs-react-netlify-identity/src/ReactNetlifyIdentity.bs.js";

function getProviderClass(provider) {
  switch (provider) {
    case 0 : 
        return "providerBitbucket";
    case 1 : 
        return "providerGitHub";
    case 2 : 
        return "providerGitLab";
    case 3 : 
        return "providerGoogle";
    
  }
}

function ProviderButton(Props) {
  var provider = Props.provider;
  var match = Props.className;
  var className = match !== undefined ? match : "";
  var identity = ReactNetlifyIdentity.useIdentityContextSimple(/* () */0);
  var handleClick = function (param) {
    return Curry._1(identity[/* loginProvider */10], provider);
  };
  return React.createElement(Core.Button, MaterialUi_Button.makeProps(Caml_option.some("Continue with " + ReactNetlifyIdentity.providerToString(provider)), getProviderClass(provider), undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleClick, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* :: */[
                  /* Root */Block.__(0, ["providerButton " + className]),
                  /* [] */0
                ], undefined, /* () */0));
}

var make = ProviderButton;

export {
  getProviderClass ,
  make ,
  
}
/* react Not a pure module */
