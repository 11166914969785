// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";

function useReducerSafe(reducer, initState) {
  var match = React.useReducer(Curry.__2(reducer), initState);
  var dispatch = match[1];
  var mounted = React.useRef(false);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                    return /* () */0;
                  });
        }), ([]));
  var dispatchSafe = function (action) {
    if (mounted.current) {
      return Curry._1(dispatch, action);
    } else {
      return 0;
    }
  };
  return /* tuple */[
          match[0],
          dispatchSafe
        ];
}

function useStateSafe(initState) {
  var match = React.useState((function () {
          return Curry._1(initState, /* () */0);
        }));
  var setState = match[1];
  var mounted = React.useRef(false);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                    return /* () */0;
                  });
        }), ([]));
  var setStateSafe = function (updateState) {
    if (mounted.current) {
      return Curry._1(setState, updateState);
    } else {
      return 0;
    }
  };
  return /* tuple */[
          match[0],
          setStateSafe
        ];
}

export {
  useReducerSafe ,
  useStateSafe ,
  
}
/* react Not a pure module */
