// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as AppModel$ReactTemplate from "./AppModel.bs.js";
import * as Elmish$ReasonReactElmish from "reason-react-elmish/src/Elmish.bs.js";

var include = Elmish$ReasonReactElmish.Make(/* module */[
      /* update */AppModel$ReactTemplate.update,
      /* initialModel */AppModel$ReactTemplate.initialState,
      /* storeEnhancer */undefined
    ]);

var Provider = include[0];

var useSelector = include[1];

var useDispatch = include[2];

var useStore = include[3];

var effectManager = include[4];

var initModel = include[5];

var initEffect = include[6];

var storeCreator = include[7];

var modelStore = include[8];

var customReducer = include[9];

var dispatch = include[10];

var useRunEffects = include[11];

var EffectRunner = include[12];

var ElmishProvider = include[13];

export {
  Provider ,
  useSelector ,
  useDispatch ,
  useStore ,
  effectManager ,
  initModel ,
  initEffect ,
  storeCreator ,
  modelStore ,
  customReducer ,
  dispatch ,
  useRunEffects ,
  EffectRunner ,
  ElmishProvider ,
  
}
/* include Not a pure module */
