// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as LibraryCache$ReactTemplate from "../graphql/LibraryCache.bs.js";
import * as Mutation$ReasonApolloHooks from "reason-apollo-hooks/src/Mutation.bs.js";
import * as LibraryMutations$ReactTemplate from "../graphql/LibraryMutations.bs.js";

var RemovePodcastMutation = Mutation$ReasonApolloHooks.Make([
      LibraryMutations$ReactTemplate.RemovePodcast[1],
      LibraryMutations$ReactTemplate.RemovePodcast[2]
    ]);

function useRemovePodcast(userId, podcastId, refetchQueries, param) {
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var match = Curry._6(RemovePodcastMutation[/* use */0], undefined, undefined, Belt_Option.map(refetchQueries, (function (queries, param) {
              return queries;
            })), undefined, (function (client, mutationResult) {
          Belt_Option.map(mutationResult.data, (function (result) {
                  return LibraryCache$ReactTemplate.removePodcastFromCache(userId, client, result);
                }));
          return /* () */0;
        }), /* () */0);
  var removePodcastMutation = match[0];
  var handleRemove = function (param) {
    var mutation = LibraryMutations$ReactTemplate.RemovePodcast[/* make */3](userId, podcastId, /* () */0);
    return Curry._5(removePodcastMutation, Caml_option.some(mutation.variables), undefined, undefined, undefined, /* () */0).then((function (param) {
                    return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                        /* text */"Removed from library",
                                        /* type_ : Success */2
                                      ]])));
                  })).catch((function (param) {
                  return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                      /* text */"Failed to remove",
                                      /* type_ : Error */3
                                    ]])));
                }));
  };
  return /* tuple */[
          handleRemove,
          match[1]
        ];
}

export {
  RemovePodcastMutation ,
  useRemovePodcast ,
  
}
/* RemovePodcastMutation Not a pure module */
