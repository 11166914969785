// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Core from "@material-ui/core";

function useIsDesktop(param) {
  return Core.useMediaQuery("(min-width: 600px)");
}

export {
  useIsDesktop ,
  
}
/* @material-ui/core Not a pure module */
