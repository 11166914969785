// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as LibraryCache$ReactTemplate from "../graphql/LibraryCache.bs.js";
import * as Mutation$ReasonApolloHooks from "reason-apollo-hooks/src/Mutation.bs.js";
import * as LibraryMutations$ReactTemplate from "../graphql/LibraryMutations.bs.js";

var SaveEpisodeMutation = Mutation$ReasonApolloHooks.Make([
      LibraryMutations$ReactTemplate.SaveEpisode[1],
      LibraryMutations$ReactTemplate.SaveEpisode[2]
    ]);

function useSaveEpisode(userId, episode) {
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var match = React.useState((function () {
          return /* NotCalled */0;
        }));
  var setFetchResult = match[1];
  var fetchEpisodeResult = match[0];
  var match$1 = Curry._6(SaveEpisodeMutation[/* use */0], undefined, undefined, undefined, undefined, (function (client, mutationResult) {
          Belt_Option.map(mutationResult.data, (function (result) {
                  return LibraryCache$ReactTemplate.addEpisodeToCache(userId, client, result);
                }));
          return /* () */0;
        }), /* () */0);
  var simple = match$1[1];
  var saveEpisodeMutation = match$1[0];
  var callMutation = function (episodeInfo) {
    var mutation = LibraryMutations$ReactTemplate.makeSaveEpisodeMutation(userId, episode, /* record */[
          /* status : NotListened */0,
          /* tags */""
        ], episodeInfo);
    return Curry._5(saveEpisodeMutation, Caml_option.some(mutation.variables), undefined, undefined, undefined, /* () */0);
  };
  var handleSave = function (param) {
    Curry._1(setFetchResult, (function (param) {
            return /* Fetching */1;
          }));
    return LibraryMutations$ReactTemplate.getEpisodeInsertInfo(episode).then((function (episodeInfo) {
                    Curry._1(setFetchResult, (function (param) {
                            return /* Success */2;
                          }));
                    return callMutation(episodeInfo).then((function (param) {
                                    return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                                        /* text */"Saved to library",
                                                        /* type_ : Success */2
                                                      ]])));
                                  })).catch((function (param) {
                                  return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                                      /* text */"Failed to save",
                                                      /* type_ : Error */3
                                                    ]])));
                                }));
                  })).catch((function (param) {
                  Curry._1(setFetchResult, (function (param) {
                          return /* Failure */3;
                        }));
                  return Promise.resolve(Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                                      /* text */"Failed to save",
                                      /* type_ : Error */3
                                    ]])));
                }));
  };
  var mutationResult;
  var exit = 0;
  if (typeof simple === "number") {
    switch (simple) {
      case 0 : 
          mutationResult = /* Loading */0;
          break;
      case 1 : 
          exit = 1;
          break;
      case 2 : 
          if (fetchEpisodeResult >= 3) {
            exit = 1;
          } else {
            switch (fetchEpisodeResult) {
              case 1 : 
                  exit = 1;
                  break;
              case 0 : 
              case 2 : 
                  mutationResult = /* NoData */2;
                  break;
              
            }
          }
          break;
      
    }
  } else if (simple.tag || fetchEpisodeResult >= 3) {
    exit = 1;
  } else {
    switch (fetchEpisodeResult) {
      case 0 : 
          mutationResult = /* NoData */2;
          break;
      case 1 : 
          exit = 1;
          break;
      case 2 : 
          mutationResult = /* Data */[simple[0]];
          break;
      
    }
  }
  if (exit === 1) {
    if (fetchEpisodeResult !== 0) {
      switch (fetchEpisodeResult - 1 | 0) {
        case 0 : 
            mutationResult = /* Loading */0;
            break;
        case 1 : 
            mutationResult = typeof simple === "number" ? /* NoData */2 : /* Error */1;
            break;
        case 2 : 
            mutationResult = /* Error */1;
            break;
        
      }
    } else {
      mutationResult = typeof simple === "number" ? /* NoData */2 : /* Error */1;
    }
  }
  return /* tuple */[
          handleSave,
          mutationResult
        ];
}

export {
  SaveEpisodeMutation ,
  useSaveEpisode ,
  
}
/* SaveEpisodeMutation Not a pure module */
