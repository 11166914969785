// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function toMyEpisode(data) {
  var episode = data.episode;
  return /* record */[
          /* id */episode.id,
          /* title */episode.title,
          /* description */episode.description,
          /* pubDate */episode.pubDate,
          /* lengthSec */episode.lengthSec,
          /* itunesId */episode.itunesId,
          /* tags */data.tags,
          /* status */data.status,
          /* podcastId */episode.podcastId,
          /* image */episode.podcast.image,
          /* podcastTitle */episode.podcast.title,
          /* publisher */episode.podcast.publisher,
          /* addedDate */Belt_Option.flatMap(data.addedDate, Js_json.decodeString)
        ];
}

function toMyPodcast(data) {
  var podcast = data.podcast;
  return /* record */[
          /* id */data.podcastId,
          /* title */podcast.title,
          /* description */podcast.description,
          /* itunesId */podcast.itunesId,
          /* publisher */podcast.publisher,
          /* image */podcast.image,
          /* addedDate */Js_json.decodeString(data.addedDate)
        ];
}

export {
  toMyEpisode ,
  toMyPodcast ,
  
}
/* No side effect */
