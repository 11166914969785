// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ApolloClient from "reason-apollo/src/ApolloClient.bs.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as Utils$ReasonApolloHooks from "reason-apollo-hooks/src/Utils.bs.js";
import * as LibraryQueries$ReactTemplate from "./LibraryQueries.bs.js";

var GetMyLibrarySavedIdsReadQuery = ApolloClient.ReadQuery([
      LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[1],
      LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[2]
    ]);

var GetMyLibrarySavedIdsWriteQuery = ApolloClient.WriteQuery([
      LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[1],
      LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[2]
    ]);

function mergeIdsCache(cache, myPodcasts, myEpisodes, param) {
  return {
          my_podcasts: Belt_Option.getWithDefault(myPodcasts, cache.my_podcasts),
          my_episodes: Belt_Option.getWithDefault(myEpisodes, cache.my_episodes)
        };
}

function updateMyLibrarySavedIds(client, updateCache, userId) {
  var fetchMyLibraryIds = LibraryQueries$ReactTemplate.GetMyLibrarySavedIds[/* make */3](userId, /* () */0);
  var options = Utils$ReasonApolloHooks.toReadQueryOptions(fetchMyLibraryIds);
  var exit = 0;
  var cachedResponse;
  try {
    cachedResponse = client.readQuery(options);
    exit = 1;
  }
  catch (exn){
    return /* () */0;
  }
  if (exit === 1) {
    if (cachedResponse == null) {
      return /* () */0;
    } else {
      client.writeQuery(Utils$ReactTemplate.toWriteQueryOptions(fetchMyLibraryIds, Curry._1(updateCache, cachedResponse)));
      return /* () */0;
    }
  }
  
}

function getSavedEpisodeId(result) {
  return Belt_Option.flatMap(Belt_Option.flatMap(result.insert_episodes, (function (result) {
                    return Belt_Array.get(result.returning, 0);
                  })), (function (result) {
                return Belt_Array.get(result.myEpisodes, 0);
              }));
}

function addEpisodeToCache(userId, client, result) {
  var match = getSavedEpisodeId(result);
  if (match !== undefined) {
    var idObj = Caml_option.valFromOption(match);
    var updateCache = function (cache) {
      var myEpisodes = Belt_Array.concat(cache.my_episodes, /* array */[idObj]);
      return mergeIdsCache(cache, undefined, myEpisodes, /* () */0);
    };
    return updateMyLibrarySavedIds(client, updateCache, userId);
  } else {
    return /* () */0;
  }
}

function getRemovedEpisodeId(result) {
  return Belt_Option.flatMap(result.delete_my_episodes, (function (result) {
                return Belt_Array.get(result.returning, 0);
              }));
}

function removeEpisodeFromCache(userId, client, result) {
  var match = getRemovedEpisodeId(result);
  if (match !== undefined) {
    var idObj = Caml_option.valFromOption(match);
    var updateCache = function (cache) {
      var myEpisodes = Belt_Array.keep(cache.my_episodes, (function (obj) {
              return obj.episodeId !== idObj.episodeId;
            }));
      return mergeIdsCache(cache, undefined, myEpisodes, /* () */0);
    };
    return updateMyLibrarySavedIds(client, updateCache, userId);
  } else {
    return /* () */0;
  }
}

function getRemovedPodcastId(result) {
  return Belt_Option.flatMap(result.delete_my_podcasts, (function (result) {
                return Belt_Array.get(result.returning, 0);
              }));
}

function removePodcastFromCache(userId, client, result) {
  var match = getRemovedPodcastId(result);
  if (match !== undefined) {
    var idObj = Caml_option.valFromOption(match);
    var updateIdsCache = function (cache) {
      var myPodcasts = Belt_Array.keep(cache.my_podcasts, (function (obj) {
              return obj.podcastId !== idObj.podcastId;
            }));
      return mergeIdsCache(cache, myPodcasts, undefined, /* () */0);
    };
    return updateMyLibrarySavedIds(client, updateIdsCache, userId);
  } else {
    return /* () */0;
  }
}

function getSavedPodcastId(result) {
  return Belt_Option.flatMap(result.insert_my_podcasts, (function (result) {
                return Belt_Array.get(result.returning, 0);
              }));
}

function addPodcastToCache(userId, client, result) {
  var match = getSavedPodcastId(result);
  if (match !== undefined) {
    var idObj = Caml_option.valFromOption(match);
    var updateCache = function (cache) {
      var myPodcasts = Belt_Array.concat(cache.my_podcasts, /* array */[idObj]);
      return mergeIdsCache(cache, myPodcasts, undefined, /* () */0);
    };
    return updateMyLibrarySavedIds(client, updateCache, userId);
  } else {
    return /* () */0;
  }
}

export {
  GetMyLibrarySavedIdsReadQuery ,
  GetMyLibrarySavedIdsWriteQuery ,
  mergeIdsCache ,
  updateMyLibrarySavedIds ,
  getSavedEpisodeId ,
  addEpisodeToCache ,
  getRemovedEpisodeId ,
  removeEpisodeFromCache ,
  getRemovedPodcastId ,
  removePodcastFromCache ,
  getSavedPodcastId ,
  addPodcastToCache ,
  
}
/* GetMyLibrarySavedIdsReadQuery Not a pure module */
