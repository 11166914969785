// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Card from "@jsiebern/bs-material-ui/src/MaterialUi_Card.bs.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Cards$ReactTemplate from "./Cards.bs.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as MaterialUi_Typography from "@jsiebern/bs-material-ui/src/MaterialUi_Typography.bs.js";
import * as Utils$ReasonApolloHooks from "reason-apollo-hooks/src/Utils.bs.js";
import * as RouterLink$ReactTemplate from "./RouterLink.bs.js";
import * as ActionButton$ReactTemplate from "./ActionButton.bs.js";
import * as LibraryQueries$ReactTemplate from "../graphql/LibraryQueries.bs.js";
import * as UseRemovePodcast$ReactTemplate from "../hooks/UseRemovePodcast.bs.js";

function str(prim) {
  return prim;
}

function MyPodcastCard(Props) {
  var id = Props.id;
  var match = Props.numberOfSavedEpisodes;
  var numberOfSavedEpisodes = match !== undefined ? match : 0;
  var description = Props.description;
  var title = Props.title;
  var publisher = Props.publisher;
  var itunesId = Props.itunesId;
  var image = Props.image;
  var userId = Props.userId;
  var addedDate = Props.addedDate;
  var hasSavedEpisodes = numberOfSavedEpisodes > 0;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var myPodcastsQuery = LibraryQueries$ReactTemplate.GetAllPodcasts[/* make */3](userId, /* () */0);
  var refetchQueries = /* array */[Utils$ReasonApolloHooks.toQueryObj(myPodcastsQuery)];
  var match$1 = UseRemovePodcast$ReactTemplate.useRemovePodcast(userId, id, refetchQueries, /* () */0);
  var onRemove = match$1[0];
  var setEpisodeQueryPodcast = function (param) {
    var podcastSearch = /* record */[
      /* id */id,
      /* title */title
    ];
    return Curry._1(dispatch, /* SetEpisodeQueryPodcast */Block.__(5, [podcastSearch]));
  };
  var info = React.createElement(React.Fragment, undefined, React.createElement(Cards$ReactTemplate.Publisher[/* make */0], {
            publisher: publisher
          }), Belt_Option.mapWithDefault(addedDate, null, (function (date) {
              return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some("Added: " + Utils$ReactTemplate.formatDate(date)), undefined, undefined, undefined, undefined, true, undefined, undefined, /* Subtitle2 */386038010, undefined, undefined, undefined, undefined, undefined, /* () */0));
            })), hasSavedEpisodes ? React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some("Saved episodes: " + String(numberOfSavedEpisodes)), undefined, undefined, undefined, undefined, true, undefined, undefined, /* Subtitle1 */386038009, undefined, undefined, undefined, undefined, undefined, /* () */0)) : null);
  var match$2 = !hasSavedEpisodes;
  return React.createElement(Core.Card, MaterialUi_Card.makeProps(Css.style(/* :: */[
                      Css.position(/* relative */903134412),
                      /* [] */0
                    ]), undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), hasSavedEpisodes ? React.createElement(RouterLink$ReactTemplate.make, {
                    href: "/my-library/" + (id + "/episodes"),
                    children: "Show episodes"
                  }) : null, React.createElement(Cards$ReactTemplate.PodcastCardContent[/* make */0], {
                  title: title,
                  image: image,
                  description: description,
                  onFilter: setEpisodeQueryPodcast,
                  info: info
                }), React.createElement(Cards$ReactTemplate.CardActions[/* make */0], {
                  children: null
                }, React.createElement(Core.Button, MaterialUi_Button.makeProps("Open in itunes", undefined, /* Secondary */67972948, undefined, undefined, undefined, undefined, undefined, undefined, Utils$ReactTemplate.makePodcastItunesUrl(itunesId), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), match$2 ? React.createElement(ActionButton$ReactTemplate.make, {
                        disabled: match$1[1] === /* Loading */0,
                        onClick: (function (param) {
                            Curry._1(onRemove, /* () */0);
                            return /* () */0;
                          }),
                        action: /* Remove */1
                      }) : null));
}

var make = MyPodcastCard;

export {
  str ,
  make ,
  
}
/* Css Not a pure module */
