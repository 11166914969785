// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Axios from "bs-axios/src/axios.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Axios$1 from "axios";
import * as ApolloLinks from "reason-apollo/src/ApolloLinks.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonApollo from "reason-apollo/src/ReasonApollo.bs.js";
import * as Caml_exceptions from "bs-platform/lib/es6/caml_exceptions.js";
import * as ApolloInMemoryCache from "reason-apollo/src/ApolloInMemoryCache.bs.js";

var fragmentsData = (require('../../fragmentTypes.json'));

var Graphql_error = Caml_exceptions.create("Graphql-ReactTemplate.Graphql_error");

function makeApolloClient(param) {
  var fragmentMatcher = ApolloInMemoryCache.createIntrospectionFragmentMatcher(fragmentsData);
  var inMemoryCache = ApolloInMemoryCache.createInMemoryCache(undefined, Caml_option.some(fragmentMatcher), /* () */0);
  var httpLink = ApolloLinks.createHttpLink(process.env.REACT_APP_GRAPHQL_URL, undefined, undefined, undefined, undefined, undefined, /* () */0);
  return ReasonApollo.createApolloClient(httpLink, inMemoryCache, undefined, undefined, undefined, undefined, /* () */0);
}

function sendQuery(q) {
  return Axios$1.post(process.env.REACT_APP_GRAPHQL_URL, {
                  query: q.query,
                  variables: q.variables
                }, {
                  headers: Axios.$$Headers[/* fromObj */0]({
                        "content-type": "application/json"
                      }),
                  withCredentials: true
                }).then((function (resp) {
                  return Promise.resolve(Curry._1(q.parse, resp.data["data"]));
                })).catch((function (error) {
                console.log(error);
                return Promise.reject([
                            Graphql_error,
                            "Request failed: " + error.response
                          ]);
              }));
}

export {
  fragmentsData ,
  Graphql_error ,
  makeApolloClient ,
  sendQuery ,
  
}
/* fragmentsData Not a pure module */
