// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_ThemeOptions from "@jsiebern/bs-material-ui/src/MaterialUi_ThemeOptions.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as MaterialUi_ThemeProvider from "@jsiebern/bs-material-ui/src/MaterialUi_ThemeProvider.bs.js";

var theme = Styles.createMuiTheme(MaterialUi_ThemeOptions.make(undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.Overrides[/* make */0](Caml_option.some(MaterialUi_ThemeOptions.AppBarClassKey[/* make */0](undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                          backgroundColor: "#34495e !important",
                          color: "#fff",
                          boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.14), 0px 1px 12px 0px rgba(0,0,0,0.12)"
                        }, /* () */0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.IconButtonClassKey[/* make */0](undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                          color: "#1a5073",
                          padding: "8px"
                        }, undefined, /* () */0)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.LinkClassKey[/* make */0](undefined, undefined, Caml_option.some(ReactDOMRe.Style[/* unsafeAddProp */1]({
                                  textDecoration: "none"
                                }, "&:hover", {
                                  color: "#29335C"
                                })), undefined, undefined, undefined, /* () */0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.TabClassKey[/* make */0](undefined, undefined, undefined, {
                          borderBottom: "2px solid #ddd"
                        }, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), Caml_option.some(MaterialUi_ThemeOptions.PaletteOptions[/* make */0](undefined, Caml_option.some(MaterialUi_ThemeOptions.TypeBackground[/* make */0]("#f3f3f3", undefined, /* () */0)), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.Primary[/* make */0](undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#20638f", /* () */0)), Caml_option.some(MaterialUi_ThemeOptions.Secondary[/* make */0](undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#669BBC", /* () */0)), undefined, undefined, undefined, /* () */0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));

function ThemeProvider(Props) {
  var children = Props.children;
  return React.createElement(MaterialUi_ThemeProvider.make, {
              children: children,
              theme: theme
            });
}

var make = ThemeProvider;

export {
  theme ,
  make ,
  
}
/* theme Not a pure module */
