// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function addToDict(dict, keyValue) {
  var match = keyValue.split("=");
  if (match.length !== 2) {
    return dict;
  } else {
    var key = match[0];
    var exit = 0;
    if (key === "") {
      var match$1 = match[1];
      if (match$1 === "") {
        return dict;
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var encodedValue = match[1];
      var value = decodeURIComponent(encodedValue);
      var match$2 = Js_dict.get(dict, key);
      if (match$2 !== undefined) {
        var match$3 = match$2;
        if (match$3.tag) {
          var dictVal = /* array */[value].concat(match$3[0]);
          dict[key] = /* Multiple */Block.__(1, [dictVal]);
        } else {
          dict[key] = /* Multiple */Block.__(1, [/* array */[
                match$3[0],
                value
              ]]);
        }
      } else {
        dict[key] = /* Single */Block.__(0, [value]);
      }
      return dict;
    }
    
  }
}

function parse(query) {
  var queryObj = { };
  var pairs = query.split("&");
  return $$Array.fold_left(addToDict, queryObj, pairs);
}

var Query = /* module */[
  /* addToDict */addToDict,
  /* parse */parse
];

function add(key, value, params) {
  return Belt_Array.concat(params, /* array */[/* tuple */[
                key,
                value
              ]]);
}

function addOption(key, valueOption, params) {
  if (valueOption !== undefined) {
    return add(key, valueOption, params);
  } else {
    return params;
  }
}

function encodeParam(param) {
  return encodeURIComponent(param[0]) + ("=" + encodeURIComponent(param[1]));
}

function buildUrl(path, params) {
  var params$1 = Belt_Array.map(params, encodeParam).join("&");
  if (params$1 === "") {
    return path;
  } else {
    return "" + (String(path) + ("?" + (String(params$1) + "")));
  }
}

var Params = /* module */[
  /* add */add,
  /* addOption */addOption,
  /* encodeParam */encodeParam,
  /* buildUrl */buildUrl
];

export {
  Query ,
  Params ,
  
}
/* No side effect */
