// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LoaderJs from "react-feather/dist/icons/loader.js";

function Spinner(Props) {
  return React.createElement(LoaderJs.default, {
              className: "spinner"
            });
}

var make = Spinner;

export {
  make ,
  
}
/* react Not a pure module */
