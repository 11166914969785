// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Cards$ReactTemplate from "./Cards.bs.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as MaterialUi_Typography from "@jsiebern/bs-material-ui/src/MaterialUi_Typography.bs.js";
import * as ActionButton$ReactTemplate from "./ActionButton.bs.js";
import * as UserIdentity$ReactTemplate from "../UserIdentity.bs.js";
import * as UseSavePodcast$ReactTemplate from "../hooks/UseSavePodcast.bs.js";
import * as UseRemovePodcast$ReactTemplate from "../hooks/UseRemovePodcast.bs.js";

function str(prim) {
  return prim;
}

function PodcastCard$PodcastLoggedInButton(Props) {
  var userId = Props.userId;
  var podcast = Props.podcast;
  var isSaved = Props.isSaved;
  var match = UseSavePodcast$ReactTemplate.useSavePodcast(userId, podcast);
  var onSave = match[0];
  var match$1 = UseRemovePodcast$ReactTemplate.useRemovePodcast(userId, podcast[/* id */0], undefined, /* () */0);
  var onRemove = match$1[0];
  if (isSaved) {
    return React.createElement(ActionButton$ReactTemplate.make, {
                disabled: match$1[1] === /* Loading */0,
                onClick: (function (param) {
                    Curry._1(onRemove, /* () */0);
                    return /* () */0;
                  }),
                action: /* Remove */1
              });
  } else {
    return React.createElement(ActionButton$ReactTemplate.make, {
                disabled: match[1] === /* Loading */0,
                onClick: (function (param) {
                    Curry._1(onSave, /* () */0);
                    return /* () */0;
                  }),
                action: /* Save */0
              });
  }
}

var PodcastLoggedInButton = /* module */[/* make */PodcastCard$PodcastLoggedInButton];

function PodcastCard(Props) {
  var podcast = Props.podcast;
  var isSaved = Props.isSaved;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var identity = Curry._1(UserIdentity$ReactTemplate.Context[/* useIdentityContext */0], /* () */0);
  var user = UserIdentity$ReactTemplate.getLoggedInUser(identity);
  var setEpisodeQueryPodcast = function (param) {
    var podcastSearch_000 = /* id */podcast[/* id */0];
    var podcastSearch_001 = /* title */podcast[/* title */2];
    var podcastSearch = /* record */[
      podcastSearch_000,
      podcastSearch_001
    ];
    return Curry._1(dispatch, /* SetEpisodeQueryPodcast */Block.__(5, [podcastSearch]));
  };
  var info = React.createElement(React.Fragment, undefined, React.createElement(Cards$ReactTemplate.Publisher[/* make */0], {
            publisher: podcast[/* publisher */3]
          }), React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(String(podcast[/* totalEpisodes */7]) + (" episodes, " + Utils$ReactTemplate.formatListennotesDate(podcast[/* latestPubDate */5]))), undefined, undefined, undefined, undefined, true, undefined, undefined, /* Subtitle2 */386038010, undefined, undefined, undefined, undefined, undefined, /* () */0)));
  return React.createElement(Cards$ReactTemplate.SearchCard[/* make */0], {
              isSaved: isSaved,
              children: null
            }, React.createElement(Cards$ReactTemplate.PodcastCardContent[/* make */0], {
                  title: podcast[/* title */2],
                  image: podcast[/* image */8],
                  description: podcast[/* description */1],
                  onFilter: setEpisodeQueryPodcast,
                  info: info
                }), React.createElement(Cards$ReactTemplate.CardActions[/* make */0], {
                  children: null
                }, React.createElement(Core.Button, MaterialUi_Button.makeProps("Open in itunes", undefined, /* Secondary */67972948, undefined, undefined, undefined, undefined, undefined, undefined, Utils$ReactTemplate.makePodcastItunesUrl(String(podcast[/* podcastItunesId */4])), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), user ? React.createElement(PodcastCard$PodcastLoggedInButton, {
                        userId: user[0],
                        podcast: podcast,
                        isSaved: isSaved
                      }) : React.createElement(ActionButton$ReactTemplate.make, {
                        onClick: (function (param) {
                            Curry._1(dispatch, /* OnUnauthorizedAccess */0);
                            return /* () */0;
                          }),
                        action: /* Save */0
                      })));
}

var make = PodcastCard;

export {
  str ,
  PodcastLoggedInButton ,
  make ,
  
}
/* react Not a pure module */
