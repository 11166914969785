// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUi_Card from "@jsiebern/bs-material-ui/src/MaterialUi_Card.bs.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Cards$ReactTemplate from "./Cards.bs.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as Utils$ReasonApolloHooks from "reason-apollo-hooks/src/Utils.bs.js";
import * as LibraryQueries$ReactTemplate from "../graphql/LibraryQueries.bs.js";
import * as UseRemoveEpisode$ReactTemplate from "../hooks/UseRemoveEpisode.bs.js";

function str(prim) {
  return prim;
}

function MyEpisodeCard(Props) {
  var episode = Props.episode;
  var userId = Props.userId;
  var podcastItunesId = Props.podcastItunesId;
  var myEpisodesQuery = LibraryQueries$ReactTemplate.GetAllEpisodes[/* make */3](userId, /* () */0);
  var refetchQueries = /* array */[Utils$ReasonApolloHooks.toQueryObj(myEpisodesQuery)];
  var match = UseRemoveEpisode$ReactTemplate.useRemoveEpisode(userId, episode[/* id */0], refetchQueries, /* () */0);
  var onRemove = match[0];
  var tmp = {
    title: episode[/* title */1],
    image: episode[/* image */9],
    description: episode[/* description */2],
    lengthSec: episode[/* lengthSec */4],
    pubDate: episode[/* pubDate */3],
    podcastTitle: episode[/* podcastTitle */10]
  };
  var tmp$1 = episode[/* addedDate */12];
  if (tmp$1 !== undefined) {
    tmp.addedDate = Caml_option.valFromOption(tmp$1);
  }
  var match$1 = episode[/* itunesId */5];
  return React.createElement(Core.Card, MaterialUi_Card.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(Cards$ReactTemplate.EpisodeCardContent[/* make */0], tmp), React.createElement(Cards$ReactTemplate.CardActions[/* make */0], {
                  children: null
                }, match$1 !== undefined && podcastItunesId !== undefined ? React.createElement(Core.Button, MaterialUi_Button.makeProps("Open in itunes", undefined, /* Secondary */67972948, undefined, undefined, undefined, undefined, undefined, undefined, Utils$ReactTemplate.makeEpisodeItunesUrl(podcastItunesId, match$1), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)) : null, React.createElement(Core.Button, MaterialUi_Button.makeProps("Remove", undefined, /* Primary */-791844958, undefined, match[1] === /* Loading */0, undefined, undefined, undefined, undefined, undefined, /* Small */311976103, undefined, /* Contained */-515484397, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                            Curry._1(onRemove, /* () */0);
                            return /* () */0;
                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))));
}

var make = MyEpisodeCard;

export {
  str ,
  make ,
  
}
/* react Not a pure module */
