// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as GraphqlTag from "graphql-tag";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

var setWindowLocation = ( function (href) { window.location.href = href; } );

var itunesBaseUrl = "https://podcasts.apple.com/us/podcast/";

function makePodcastItunesUrl(podcastItunesId) {
  return itunesBaseUrl + ("id" + podcastItunesId);
}

function makeEpisodeItunesUrl(podcastItunesId, episodeItunesId) {
  return itunesBaseUrl + (episodeItunesId + ("/id" + (podcastItunesId + ("?i=" + episodeItunesId))));
}

function truncateDescription(description) {
  var match = description.length < 200;
  if (match) {
    return description;
  } else {
    return description + "...";
  }
}

function toWriteQueryOptions(query, data) {
  return {
          query: GraphqlTag.default(query.query),
          variables: Js_null_undefined.fromOption(Caml_option.some(query.variables)),
          data: data
        };
}

function hasMutationData(result) {
  if (typeof result === "number" || result.tag !== 1) {
    return false;
  } else {
    return true;
  }
}

function toApiGenres(arr) {
  return arr.join(",");
}

function formatListennotesDate(dateStr) {
  return new Date(dateStr.replace((/-/g), "/")).toLocaleDateString();
}

function formatDate(dateStr) {
  return new Date(dateStr).toLocaleDateString();
}

function fromBigInt(value) {
  return Belt_Option.mapWithDefault(Js_json.decodeNumber(value), 0, (function (prim) {
                return prim | 0;
              }));
}

function makePositive(number) {
  var match = number < 0;
  if (match) {
    return 0;
  } else {
    return number;
  }
}

function getInputValue(e) {
  return e.target.value;
}

function onKeyDown($$event) {
  var key = $$event.which;
  if (key !== 13) {
    if (key !== 27) {
      return /* Other */2;
    } else {
      return /* Escape */1;
    }
  } else {
    return /* Enter */0;
  }
}

function px_of_int(x) {
  return String(x) + "px";
}

function spacingPx(ind, theme) {
  return Curry._1(theme.spacing, ind);
}

function spacing(ind, theme) {
  var x = Curry._1(theme.spacing, ind);
  return String(x) + "px";
}

function spacingTransform(ind, transform, theme) {
  var x = Curry._1(transform, Curry._1(theme.spacing, ind));
  return String(x) + "px";
}

function getPalette(theme) {
  return theme.palette;
}

function getPrimaryColor(theme) {
  return theme.palette.primary;
}

function getSecondaryColor(theme) {
  return theme.palette.secondary;
}

function fromMuiColor(param) {
  return param.replace("#", "");
}

function getBreakpoint(breakpoint, theme) {
  var muiBreakpoint = typeof breakpoint === "number" ? (
      breakpoint !== 17239 ? (
          breakpoint >= 19700 ? (
              breakpoint >= 19707 ? "xs" : "xl"
            ) : (
              breakpoint >= 18586 ? "sm" : "lg"
            )
        ) : "md"
    ) : breakpoint[1];
  return Curry._1(theme.breakpoints.up, muiBreakpoint).replace("@media", "");
}

var maxDescrptionTextLength = 200;

export {
  setWindowLocation ,
  itunesBaseUrl ,
  makePodcastItunesUrl ,
  makeEpisodeItunesUrl ,
  maxDescrptionTextLength ,
  truncateDescription ,
  toWriteQueryOptions ,
  hasMutationData ,
  toApiGenres ,
  formatListennotesDate ,
  formatDate ,
  fromBigInt ,
  makePositive ,
  getInputValue ,
  onKeyDown ,
  px_of_int ,
  spacingPx ,
  spacing ,
  spacingTransform ,
  getPalette ,
  getPrimaryColor ,
  getSecondaryColor ,
  fromMuiColor ,
  getBreakpoint ,
  
}
/* setWindowLocation Not a pure module */
