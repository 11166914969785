// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as Routing$ReactTemplate from "../utils/Routing.bs.js";
import * as SearchQuery$ReactTemplate from "../types/SearchQuery.bs.js";
import * as AppNotifications$ReactTemplate from "./AppNotifications.bs.js";

function createInitModel(param) {
  return /* record */[
          /* search */SearchQuery$ReactTemplate.make(undefined, /* () */0),
          /* searchPodcast */undefined,
          /* notifications */AppNotifications$ReactTemplate.init(/* () */0),
          /* showIdentityModal */false
        ];
}

function updateSearchModel(model, search) {
  return /* record */[
          /* search */search,
          /* searchPodcast */model[/* searchPodcast */1],
          /* notifications */model[/* notifications */2],
          /* showIdentityModal */model[/* showIdentityModal */3]
        ];
}

function updateSearchTerm(searchTerm, searchQuery) {
  return /* record */[
          /* searchTerm */searchTerm,
          /* searchType */searchQuery[/* searchType */1],
          /* language */searchQuery[/* language */2],
          /* podcastId */searchQuery[/* podcastId */3],
          /* genreIds */searchQuery[/* genreIds */4]
        ];
}

function updateSearchType(searchType, searchQuery) {
  return /* record */[
          /* searchTerm */searchQuery[/* searchTerm */0],
          /* searchType */searchType,
          /* language */searchQuery[/* language */2],
          /* podcastId */searchQuery[/* podcastId */3],
          /* genreIds */searchQuery[/* genreIds */4]
        ];
}

function updateNotifications(model, notifications) {
  return /* record */[
          /* search */model[/* search */0],
          /* searchPodcast */model[/* searchPodcast */1],
          /* notifications */notifications,
          /* showIdentityModal */model[/* showIdentityModal */3]
        ];
}

function pushSearchQuery(model, param) {
  Routing$ReactTemplate.pushRoute(/* Search */Block.__(1, [model[/* search */0]]));
  return undefined;
}

function update(model, message) {
  if (typeof message === "number") {
    if (message === 0) {
      return /* tuple */[
              /* record */[
                /* search */model[/* search */0],
                /* searchPodcast */model[/* searchPodcast */1],
                /* notifications */model[/* notifications */2],
                /* showIdentityModal */true
              ],
              undefined
            ];
    } else {
      var init = model[/* search */0];
      var model$1 = updateSearchModel(/* record */[
            /* search */model[/* search */0],
            /* searchPodcast */undefined,
            /* notifications */model[/* notifications */2],
            /* showIdentityModal */model[/* showIdentityModal */3]
          ], /* record */[
            /* searchTerm */init[/* searchTerm */0],
            /* searchType */init[/* searchType */1],
            /* language */init[/* language */2],
            /* podcastId */undefined,
            /* genreIds */init[/* genreIds */4]
          ]);
      return /* tuple */[
              model$1,
              (function (param) {
                  return pushSearchQuery(model$1, param);
                })
            ];
    }
  } else {
    switch (message.tag | 0) {
      case 0 : 
          return /* tuple */[
                  updateNotifications(model, AppNotifications$ReactTemplate.add(message[0], model[/* notifications */2])),
                  undefined
                ];
      case 1 : 
          return /* tuple */[
                  /* record */[
                    /* search */model[/* search */0],
                    /* searchPodcast */model[/* searchPodcast */1],
                    /* notifications */AppNotifications$ReactTemplate.remove(message[0], model[/* notifications */2]),
                    /* showIdentityModal */model[/* showIdentityModal */3]
                  ],
                  undefined
                ];
      case 2 : 
          return /* tuple */[
                  /* record */[
                    /* search */model[/* search */0],
                    /* searchPodcast */model[/* searchPodcast */1],
                    /* notifications */model[/* notifications */2],
                    /* showIdentityModal */message[0]
                  ],
                  undefined
                ];
      case 3 : 
          var model$2 = updateSearchModel(model, updateSearchTerm(message[0], model[/* search */0]));
          return /* tuple */[
                  model$2,
                  (function (param) {
                      return pushSearchQuery(model$2, param);
                    })
                ];
      case 4 : 
          var model$3 = updateSearchModel(model, updateSearchType(message[0], model[/* search */0]));
          return /* tuple */[
                  model$3,
                  (function (param) {
                      return pushSearchQuery(model$3, param);
                    })
                ];
      case 5 : 
          var podcast = message[0];
          var init$1 = model[/* search */0];
          var model$4 = updateSearchModel(/* record */[
                /* search */model[/* search */0],
                /* searchPodcast */podcast,
                /* notifications */model[/* notifications */2],
                /* showIdentityModal */model[/* showIdentityModal */3]
              ], updateSearchType(/* Episode */0, /* record */[
                    /* searchTerm */init$1[/* searchTerm */0],
                    /* searchType */init$1[/* searchType */1],
                    /* language */init$1[/* language */2],
                    /* podcastId */podcast[/* id */0],
                    /* genreIds */init$1[/* genreIds */4]
                  ]));
          return /* tuple */[
                  model$4,
                  (function (param) {
                      return pushSearchQuery(model$4, param);
                    })
                ];
      case 6 : 
          var model_000 = /* search */message[0];
          var model_001 = /* searchPodcast */model[/* searchPodcast */1];
          var model_002 = /* notifications */model[/* notifications */2];
          var model_003 = /* showIdentityModal */model[/* showIdentityModal */3];
          var model$5 = /* record */[
            model_000,
            model_001,
            model_002,
            model_003
          ];
          return /* tuple */[
                  model$5,
                  undefined
                ];
      
    }
  }
}

function initEffect(dispatch) {
  var route = Routing$ReactTemplate.urlToRoute(ReasonReactRouter.dangerouslyGetInitialUrl(/* () */0));
  if (typeof route !== "number") {
    if (route.tag === 1) {
      Curry._1(dispatch, /* SetSearchModelFromQuery */Block.__(6, [route[0]]));
    }
    
  }
  return undefined;
}

var initialState_000 = createInitModel(/* () */0);

var initialState_001 = initEffect;

var initialState = /* tuple */[
  initialState_000,
  initialState_001
];

export {
  createInitModel ,
  updateSearchModel ,
  updateSearchTerm ,
  updateSearchType ,
  updateNotifications ,
  pushSearchQuery ,
  update ,
  initEffect ,
  initialState ,
  
}
/* initialState Not a pure module */
