// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as ItunesEpisode$ReactTemplate from "../graphql/ItunesEpisode.bs.js";

function createItunesLinkHref(podcastItunesId, itunesData) {
  if (typeof itunesData === "number") {
    return "#";
  } else {
    return Utils$ReactTemplate.makeEpisodeItunesUrl(String(podcastItunesId), itunesData[0]);
  }
}

function openItunesLink(podcastItunesId, episodeItunesId) {
  return Curry._1(Utils$ReactTemplate.setWindowLocation, Utils$ReactTemplate.makeEpisodeItunesUrl(String(podcastItunesId), episodeItunesId));
}

function EpisodeItunesLink(Props) {
  var podcastItunesId = Props.podcastItunesId;
  var episodeName = Props.episodeName;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var match = React.useState((function () {
          return /* Unknown */0;
        }));
  var setItunesId = match[1];
  var itunesId = match[0];
  var handleFetchError = function (param) {
    Curry._1(setItunesId, (function (param) {
            return /* Error */2;
          }));
    return Curry._1(dispatch, /* ShowNotification */Block.__(0, [/* record */[
                    /* text */"Couldn't retrieve itunes id :(",
                    /* type_ : Error */3
                  ]]));
  };
  var handleFetchSuccess = function (episodeItunesId) {
    Curry._1(setItunesId, (function (param) {
            return /* Loaded */[episodeItunesId];
          }));
    return openItunesLink(podcastItunesId, episodeItunesId);
  };
  var getItunesId = function (param) {
    Curry._1(setItunesId, (function (param) {
            return /* Fetching */1;
          }));
    return ItunesEpisode$ReactTemplate.getItunesId(String(podcastItunesId), episodeName).then((function (id) {
                    return Promise.resolve(handleFetchSuccess(id));
                  })).catch((function (param) {
                  return Promise.resolve(handleFetchError(/* () */0));
                }));
  };
  var handleItunesLinkClick = function (param) {
    if (typeof itunesId === "number") {
      getItunesId(/* () */0);
      return /* () */0;
    } else {
      return openItunesLink(podcastItunesId, itunesId[0]);
    }
  };
  return React.createElement(Core.Button, MaterialUi_Button.makeProps("Open in itunes", undefined, /* Secondary */67972948, undefined, itunesId === /* Fetching */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                    handleItunesLinkClick(/* () */0);
                    return /* () */0;
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var make = EpisodeItunesLink;

export {
  createItunesLinkHref ,
  openItunesLink ,
  make ,
  
}
/* react Not a pure module */
