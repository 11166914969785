// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as EpisodeStatus$ReactTemplate from "../types/EpisodeStatus.bs.js";

var ppx_printed_query = "query ($userId: String!, $podcastId: String!)  {\nmy_episodes(where: {episode: {podcast: {id: {_eq: $podcastId}}}, _and: {userId: {_eq: $userId}}})  {\nepisodeId  \nstatus  \ntags  \naddedDate  \nepisode  {\nid  \npodcastId  \nitunesId  \ntitle  \ndescription  \npubDate  \nlengthSec  \npodcast  {\nitunesId  \ntitle  \nimage  \npublisher  \n}\n\n}\n\n}\n\npodcasts(limit: 1, where: {id: {_eq: $podcastId}})  {\ntitle  \ndescription  \nitunesId  \nimage  \n}\n\n}\n";

function parse(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var value$1 = Caml_option.valFromOption(match);
    var match$1 = Js_dict.get(value$1, "my_episodes");
    var tmp;
    if (match$1 !== undefined) {
      var value$2 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeArray(value$2);
      tmp = match$2 !== undefined ? match$2.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var value$1 = Caml_option.valFromOption(match);
                  var match$1 = Js_dict.get(value$1, "episodeId");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$2 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$2);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field episodeId on type my_episodes is missing");
                  }
                  var match$3 = Js_dict.get(value$1, "status");
                  var tmp$1;
                  if (match$3 !== undefined) {
                    var value$3 = Caml_option.valFromOption(match$3);
                    var match$4 = Js_json.decodeString(value$3);
                    tmp$1 = EpisodeStatus$ReactTemplate.decode(match$4 !== undefined ? match$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3)));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field status on type my_episodes is missing");
                  }
                  var match$5 = Js_dict.get(value$1, "tags");
                  var tmp$2;
                  if (match$5 !== undefined) {
                    var value$4 = Caml_option.valFromOption(match$5);
                    var match$6 = Js_json.decodeString(value$4);
                    tmp$2 = match$6 !== undefined ? match$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field tags on type my_episodes is missing");
                  }
                  var match$7 = Js_dict.get(value$1, "addedDate");
                  var tmp$3;
                  if (match$7 !== undefined) {
                    var value$5 = Caml_option.valFromOption(match$7);
                    var match$8 = Js_json.decodeNull(value$5);
                    tmp$3 = match$8 !== undefined ? undefined : Caml_option.some(value$5);
                  } else {
                    tmp$3 = undefined;
                  }
                  var match$9 = Js_dict.get(value$1, "episode");
                  var tmp$4;
                  if (match$9 !== undefined) {
                    var match$10 = Js_json.decodeObject(Caml_option.valFromOption(match$9));
                    if (match$10 !== undefined) {
                      var value$6 = Caml_option.valFromOption(match$10);
                      var match$11 = Js_dict.get(value$6, "id");
                      var tmp$5;
                      if (match$11 !== undefined) {
                        var value$7 = Caml_option.valFromOption(match$11);
                        var match$12 = Js_json.decodeString(value$7);
                        tmp$5 = match$12 !== undefined ? match$12 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                      } else {
                        tmp$5 = Js_exn.raiseError("graphql_ppx: Field id on type episodes is missing");
                      }
                      var match$13 = Js_dict.get(value$6, "podcastId");
                      var tmp$6;
                      if (match$13 !== undefined) {
                        var value$8 = Caml_option.valFromOption(match$13);
                        var match$14 = Js_json.decodeString(value$8);
                        tmp$6 = match$14 !== undefined ? match$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
                      } else {
                        tmp$6 = Js_exn.raiseError("graphql_ppx: Field podcastId on type episodes is missing");
                      }
                      var match$15 = Js_dict.get(value$6, "itunesId");
                      var tmp$7;
                      if (match$15 !== undefined) {
                        var value$9 = Caml_option.valFromOption(match$15);
                        var match$16 = Js_json.decodeNull(value$9);
                        if (match$16 !== undefined) {
                          tmp$7 = undefined;
                        } else {
                          var match$17 = Js_json.decodeString(value$9);
                          tmp$7 = match$17 !== undefined ? match$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                        }
                      } else {
                        tmp$7 = undefined;
                      }
                      var match$18 = Js_dict.get(value$6, "title");
                      var tmp$8;
                      if (match$18 !== undefined) {
                        var value$10 = Caml_option.valFromOption(match$18);
                        var match$19 = Js_json.decodeString(value$10);
                        tmp$8 = match$19 !== undefined ? match$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
                      } else {
                        tmp$8 = Js_exn.raiseError("graphql_ppx: Field title on type episodes is missing");
                      }
                      var match$20 = Js_dict.get(value$6, "description");
                      var tmp$9;
                      if (match$20 !== undefined) {
                        var value$11 = Caml_option.valFromOption(match$20);
                        var match$21 = Js_json.decodeString(value$11);
                        tmp$9 = match$21 !== undefined ? match$21 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$11));
                      } else {
                        tmp$9 = Js_exn.raiseError("graphql_ppx: Field description on type episodes is missing");
                      }
                      var match$22 = Js_dict.get(value$6, "pubDate");
                      var tmp$10;
                      if (match$22 !== undefined) {
                        var value$12 = Caml_option.valFromOption(match$22);
                        var match$23 = Js_json.decodeString(value$12);
                        tmp$10 = match$23 !== undefined ? match$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                      } else {
                        tmp$10 = Js_exn.raiseError("graphql_ppx: Field pubDate on type episodes is missing");
                      }
                      var match$24 = Js_dict.get(value$6, "lengthSec");
                      var tmp$11;
                      if (match$24 !== undefined) {
                        var value$13 = Caml_option.valFromOption(match$24);
                        var match$25 = Js_json.decodeNumber(value$13);
                        tmp$11 = match$25 !== undefined ? match$25 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$13));
                      } else {
                        tmp$11 = Js_exn.raiseError("graphql_ppx: Field lengthSec on type episodes is missing");
                      }
                      var match$26 = Js_dict.get(value$6, "podcast");
                      var tmp$12;
                      if (match$26 !== undefined) {
                        var match$27 = Js_json.decodeObject(Caml_option.valFromOption(match$26));
                        if (match$27 !== undefined) {
                          var value$14 = Caml_option.valFromOption(match$27);
                          var match$28 = Js_dict.get(value$14, "itunesId");
                          var tmp$13;
                          if (match$28 !== undefined) {
                            var value$15 = Caml_option.valFromOption(match$28);
                            var match$29 = Js_json.decodeString(value$15);
                            tmp$13 = match$29 !== undefined ? match$29 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                          } else {
                            tmp$13 = Js_exn.raiseError("graphql_ppx: Field itunesId on type podcasts is missing");
                          }
                          var match$30 = Js_dict.get(value$14, "title");
                          var tmp$14;
                          if (match$30 !== undefined) {
                            var value$16 = Caml_option.valFromOption(match$30);
                            var match$31 = Js_json.decodeString(value$16);
                            tmp$14 = match$31 !== undefined ? match$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
                          } else {
                            tmp$14 = Js_exn.raiseError("graphql_ppx: Field title on type podcasts is missing");
                          }
                          var match$32 = Js_dict.get(value$14, "image");
                          var tmp$15;
                          if (match$32 !== undefined) {
                            var value$17 = Caml_option.valFromOption(match$32);
                            var match$33 = Js_json.decodeString(value$17);
                            tmp$15 = match$33 !== undefined ? match$33 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                          } else {
                            tmp$15 = Js_exn.raiseError("graphql_ppx: Field image on type podcasts is missing");
                          }
                          var match$34 = Js_dict.get(value$14, "publisher");
                          var tmp$16;
                          if (match$34 !== undefined) {
                            var value$18 = Caml_option.valFromOption(match$34);
                            var match$35 = Js_json.decodeString(value$18);
                            tmp$16 = match$35 !== undefined ? match$35 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
                          } else {
                            tmp$16 = Js_exn.raiseError("graphql_ppx: Field publisher on type podcasts is missing");
                          }
                          tmp$12 = {
                            itunesId: tmp$13,
                            title: tmp$14,
                            image: tmp$15,
                            publisher: tmp$16
                          };
                        } else {
                          tmp$12 = Js_exn.raiseError("graphql_ppx: Object is not a value");
                        }
                      } else {
                        tmp$12 = Js_exn.raiseError("graphql_ppx: Field podcast on type episodes is missing");
                      }
                      tmp$4 = {
                        id: tmp$5,
                        podcastId: tmp$6,
                        itunesId: tmp$7,
                        title: tmp$8,
                        description: tmp$9,
                        pubDate: tmp$10,
                        lengthSec: tmp$11,
                        podcast: tmp$12
                      };
                    } else {
                      tmp$4 = Js_exn.raiseError("graphql_ppx: Object is not a value");
                    }
                  } else {
                    tmp$4 = Js_exn.raiseError("graphql_ppx: Field episode on type my_episodes is missing");
                  }
                  return {
                          episodeId: tmp,
                          status: tmp$1,
                          tags: tmp$2,
                          addedDate: tmp$3,
                          episode: tmp$4
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$2));
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field my_episodes on type query_root is missing");
    }
    var match$3 = Js_dict.get(value$1, "podcasts");
    var tmp$1;
    if (match$3 !== undefined) {
      var value$3 = Caml_option.valFromOption(match$3);
      var match$4 = Js_json.decodeArray(value$3);
      tmp$1 = match$4 !== undefined ? match$4.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var value$1 = Caml_option.valFromOption(match);
                  var match$1 = Js_dict.get(value$1, "title");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$2 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$2);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field title on type podcasts is missing");
                  }
                  var match$3 = Js_dict.get(value$1, "description");
                  var tmp$1;
                  if (match$3 !== undefined) {
                    var value$3 = Caml_option.valFromOption(match$3);
                    var match$4 = Js_json.decodeString(value$3);
                    tmp$1 = match$4 !== undefined ? match$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field description on type podcasts is missing");
                  }
                  var match$5 = Js_dict.get(value$1, "itunesId");
                  var tmp$2;
                  if (match$5 !== undefined) {
                    var value$4 = Caml_option.valFromOption(match$5);
                    var match$6 = Js_json.decodeString(value$4);
                    tmp$2 = match$6 !== undefined ? match$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field itunesId on type podcasts is missing");
                  }
                  var match$7 = Js_dict.get(value$1, "image");
                  var tmp$3;
                  if (match$7 !== undefined) {
                    var value$5 = Caml_option.valFromOption(match$7);
                    var match$8 = Js_json.decodeString(value$5);
                    tmp$3 = match$8 !== undefined ? match$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field image on type podcasts is missing");
                  }
                  return {
                          title: tmp,
                          description: tmp$1,
                          itunesId: tmp$2,
                          image: tmp$3
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$3));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field podcasts on type query_root is missing");
    }
    return {
            my_episodes: tmp,
            podcasts: tmp$1
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make(userId, podcastId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ]
              ]),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var userId = variables.userId;
  var podcastId = variables.podcastId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(/* array */[
                /* tuple */[
                  "userId",
                  userId
                ],
                /* tuple */[
                  "podcastId",
                  podcastId
                ]
              ]),
          parse: parse
        };
}

function ret_type(f) {
  return /* module */[];
}

var MT_Ret = /* module */[];

var GetMyEpisodes = /* module */[
  /* ppx_printed_query */ppx_printed_query,
  /* query */ppx_printed_query,
  /* parse */parse,
  /* make */make,
  /* makeWithVariables */makeWithVariables,
  /* ret_type */ret_type,
  /* MT_Ret */MT_Ret
];

var ppx_printed_query$1 = "query ($userId: String!)  {\nmy_episodes(where: {userId: {_eq: $userId}}, order_by: {addedDate: desc})  {\nepisodeId  \nstatus  \ntags  \naddedDate  \nepisode  {\nid  \npodcastId  \nitunesId  \ntitle  \ndescription  \npubDate  \nlengthSec  \npodcast  {\nitunesId  \ntitle  \nimage  \npublisher  \n}\n\n}\n\n}\n\n}\n";

function parse$1(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "my_episodes");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeArray(value$1);
      tmp = match$2 !== undefined ? match$2.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var value$1 = Caml_option.valFromOption(match);
                  var match$1 = Js_dict.get(value$1, "episodeId");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$2 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$2);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field episodeId on type my_episodes is missing");
                  }
                  var match$3 = Js_dict.get(value$1, "status");
                  var tmp$1;
                  if (match$3 !== undefined) {
                    var value$3 = Caml_option.valFromOption(match$3);
                    var match$4 = Js_json.decodeString(value$3);
                    tmp$1 = EpisodeStatus$ReactTemplate.decode(match$4 !== undefined ? match$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3)));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field status on type my_episodes is missing");
                  }
                  var match$5 = Js_dict.get(value$1, "tags");
                  var tmp$2;
                  if (match$5 !== undefined) {
                    var value$4 = Caml_option.valFromOption(match$5);
                    var match$6 = Js_json.decodeString(value$4);
                    tmp$2 = match$6 !== undefined ? match$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field tags on type my_episodes is missing");
                  }
                  var match$7 = Js_dict.get(value$1, "addedDate");
                  var tmp$3;
                  if (match$7 !== undefined) {
                    var value$5 = Caml_option.valFromOption(match$7);
                    var match$8 = Js_json.decodeNull(value$5);
                    tmp$3 = match$8 !== undefined ? undefined : Caml_option.some(value$5);
                  } else {
                    tmp$3 = undefined;
                  }
                  var match$9 = Js_dict.get(value$1, "episode");
                  var tmp$4;
                  if (match$9 !== undefined) {
                    var match$10 = Js_json.decodeObject(Caml_option.valFromOption(match$9));
                    if (match$10 !== undefined) {
                      var value$6 = Caml_option.valFromOption(match$10);
                      var match$11 = Js_dict.get(value$6, "id");
                      var tmp$5;
                      if (match$11 !== undefined) {
                        var value$7 = Caml_option.valFromOption(match$11);
                        var match$12 = Js_json.decodeString(value$7);
                        tmp$5 = match$12 !== undefined ? match$12 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                      } else {
                        tmp$5 = Js_exn.raiseError("graphql_ppx: Field id on type episodes is missing");
                      }
                      var match$13 = Js_dict.get(value$6, "podcastId");
                      var tmp$6;
                      if (match$13 !== undefined) {
                        var value$8 = Caml_option.valFromOption(match$13);
                        var match$14 = Js_json.decodeString(value$8);
                        tmp$6 = match$14 !== undefined ? match$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
                      } else {
                        tmp$6 = Js_exn.raiseError("graphql_ppx: Field podcastId on type episodes is missing");
                      }
                      var match$15 = Js_dict.get(value$6, "itunesId");
                      var tmp$7;
                      if (match$15 !== undefined) {
                        var value$9 = Caml_option.valFromOption(match$15);
                        var match$16 = Js_json.decodeNull(value$9);
                        if (match$16 !== undefined) {
                          tmp$7 = undefined;
                        } else {
                          var match$17 = Js_json.decodeString(value$9);
                          tmp$7 = match$17 !== undefined ? match$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                        }
                      } else {
                        tmp$7 = undefined;
                      }
                      var match$18 = Js_dict.get(value$6, "title");
                      var tmp$8;
                      if (match$18 !== undefined) {
                        var value$10 = Caml_option.valFromOption(match$18);
                        var match$19 = Js_json.decodeString(value$10);
                        tmp$8 = match$19 !== undefined ? match$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
                      } else {
                        tmp$8 = Js_exn.raiseError("graphql_ppx: Field title on type episodes is missing");
                      }
                      var match$20 = Js_dict.get(value$6, "description");
                      var tmp$9;
                      if (match$20 !== undefined) {
                        var value$11 = Caml_option.valFromOption(match$20);
                        var match$21 = Js_json.decodeString(value$11);
                        tmp$9 = match$21 !== undefined ? match$21 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$11));
                      } else {
                        tmp$9 = Js_exn.raiseError("graphql_ppx: Field description on type episodes is missing");
                      }
                      var match$22 = Js_dict.get(value$6, "pubDate");
                      var tmp$10;
                      if (match$22 !== undefined) {
                        var value$12 = Caml_option.valFromOption(match$22);
                        var match$23 = Js_json.decodeString(value$12);
                        tmp$10 = match$23 !== undefined ? match$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                      } else {
                        tmp$10 = Js_exn.raiseError("graphql_ppx: Field pubDate on type episodes is missing");
                      }
                      var match$24 = Js_dict.get(value$6, "lengthSec");
                      var tmp$11;
                      if (match$24 !== undefined) {
                        var value$13 = Caml_option.valFromOption(match$24);
                        var match$25 = Js_json.decodeNumber(value$13);
                        tmp$11 = match$25 !== undefined ? match$25 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$13));
                      } else {
                        tmp$11 = Js_exn.raiseError("graphql_ppx: Field lengthSec on type episodes is missing");
                      }
                      var match$26 = Js_dict.get(value$6, "podcast");
                      var tmp$12;
                      if (match$26 !== undefined) {
                        var match$27 = Js_json.decodeObject(Caml_option.valFromOption(match$26));
                        if (match$27 !== undefined) {
                          var value$14 = Caml_option.valFromOption(match$27);
                          var match$28 = Js_dict.get(value$14, "itunesId");
                          var tmp$13;
                          if (match$28 !== undefined) {
                            var value$15 = Caml_option.valFromOption(match$28);
                            var match$29 = Js_json.decodeString(value$15);
                            tmp$13 = match$29 !== undefined ? match$29 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                          } else {
                            tmp$13 = Js_exn.raiseError("graphql_ppx: Field itunesId on type podcasts is missing");
                          }
                          var match$30 = Js_dict.get(value$14, "title");
                          var tmp$14;
                          if (match$30 !== undefined) {
                            var value$16 = Caml_option.valFromOption(match$30);
                            var match$31 = Js_json.decodeString(value$16);
                            tmp$14 = match$31 !== undefined ? match$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
                          } else {
                            tmp$14 = Js_exn.raiseError("graphql_ppx: Field title on type podcasts is missing");
                          }
                          var match$32 = Js_dict.get(value$14, "image");
                          var tmp$15;
                          if (match$32 !== undefined) {
                            var value$17 = Caml_option.valFromOption(match$32);
                            var match$33 = Js_json.decodeString(value$17);
                            tmp$15 = match$33 !== undefined ? match$33 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                          } else {
                            tmp$15 = Js_exn.raiseError("graphql_ppx: Field image on type podcasts is missing");
                          }
                          var match$34 = Js_dict.get(value$14, "publisher");
                          var tmp$16;
                          if (match$34 !== undefined) {
                            var value$18 = Caml_option.valFromOption(match$34);
                            var match$35 = Js_json.decodeString(value$18);
                            tmp$16 = match$35 !== undefined ? match$35 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
                          } else {
                            tmp$16 = Js_exn.raiseError("graphql_ppx: Field publisher on type podcasts is missing");
                          }
                          tmp$12 = {
                            itunesId: tmp$13,
                            title: tmp$14,
                            image: tmp$15,
                            publisher: tmp$16
                          };
                        } else {
                          tmp$12 = Js_exn.raiseError("graphql_ppx: Object is not a value");
                        }
                      } else {
                        tmp$12 = Js_exn.raiseError("graphql_ppx: Field podcast on type episodes is missing");
                      }
                      tmp$4 = {
                        id: tmp$5,
                        podcastId: tmp$6,
                        itunesId: tmp$7,
                        title: tmp$8,
                        description: tmp$9,
                        pubDate: tmp$10,
                        lengthSec: tmp$11,
                        podcast: tmp$12
                      };
                    } else {
                      tmp$4 = Js_exn.raiseError("graphql_ppx: Object is not a value");
                    }
                  } else {
                    tmp$4 = Js_exn.raiseError("graphql_ppx: Field episode on type my_episodes is missing");
                  }
                  return {
                          episodeId: tmp,
                          status: tmp$1,
                          tags: tmp$2,
                          addedDate: tmp$3,
                          episode: tmp$4
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$1));
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field my_episodes on type query_root is missing");
    }
    return {
            my_episodes: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$1(userId, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var userId = variables.userId;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$1
        };
}

function ret_type$1(f) {
  return /* module */[];
}

var MT_Ret$1 = /* module */[];

var GetAllEpisodes = /* module */[
  /* ppx_printed_query */ppx_printed_query$1,
  /* query */ppx_printed_query$1,
  /* parse */parse$1,
  /* make */make$1,
  /* makeWithVariables */makeWithVariables$1,
  /* ret_type */ret_type$1,
  /* MT_Ret */MT_Ret$1
];

var ppx_printed_query$2 = "query ($userId: String!)  {\nmy_podcasts(where: {userId: {_eq: $userId}}, order_by: {addedDate: desc})  {\npodcastId  \naddedDate  \npodcast  {\ndescription  \nitunesId  \ntitle  \npublisher  \nimage  \n}\n\n}\n\n}\n";

function parse$2(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "my_podcasts");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeArray(value$1);
      tmp = match$2 !== undefined ? match$2.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var value$1 = Caml_option.valFromOption(match);
                  var match$1 = Js_dict.get(value$1, "podcastId");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$2 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$2);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field podcastId on type my_podcasts is missing");
                  }
                  var match$3 = Js_dict.get(value$1, "addedDate");
                  var match$4 = Js_dict.get(value$1, "podcast");
                  var tmp$1;
                  if (match$4 !== undefined) {
                    var match$5 = Js_json.decodeObject(Caml_option.valFromOption(match$4));
                    if (match$5 !== undefined) {
                      var value$3 = Caml_option.valFromOption(match$5);
                      var match$6 = Js_dict.get(value$3, "description");
                      var tmp$2;
                      if (match$6 !== undefined) {
                        var value$4 = Caml_option.valFromOption(match$6);
                        var match$7 = Js_json.decodeString(value$4);
                        tmp$2 = match$7 !== undefined ? match$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                      } else {
                        tmp$2 = Js_exn.raiseError("graphql_ppx: Field description on type podcasts is missing");
                      }
                      var match$8 = Js_dict.get(value$3, "itunesId");
                      var tmp$3;
                      if (match$8 !== undefined) {
                        var value$5 = Caml_option.valFromOption(match$8);
                        var match$9 = Js_json.decodeString(value$5);
                        tmp$3 = match$9 !== undefined ? match$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
                      } else {
                        tmp$3 = Js_exn.raiseError("graphql_ppx: Field itunesId on type podcasts is missing");
                      }
                      var match$10 = Js_dict.get(value$3, "title");
                      var tmp$4;
                      if (match$10 !== undefined) {
                        var value$6 = Caml_option.valFromOption(match$10);
                        var match$11 = Js_json.decodeString(value$6);
                        tmp$4 = match$11 !== undefined ? match$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                      } else {
                        tmp$4 = Js_exn.raiseError("graphql_ppx: Field title on type podcasts is missing");
                      }
                      var match$12 = Js_dict.get(value$3, "publisher");
                      var tmp$5;
                      if (match$12 !== undefined) {
                        var value$7 = Caml_option.valFromOption(match$12);
                        var match$13 = Js_json.decodeString(value$7);
                        tmp$5 = match$13 !== undefined ? match$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                      } else {
                        tmp$5 = Js_exn.raiseError("graphql_ppx: Field publisher on type podcasts is missing");
                      }
                      var match$14 = Js_dict.get(value$3, "image");
                      var tmp$6;
                      if (match$14 !== undefined) {
                        var value$8 = Caml_option.valFromOption(match$14);
                        var match$15 = Js_json.decodeString(value$8);
                        tmp$6 = match$15 !== undefined ? match$15 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
                      } else {
                        tmp$6 = Js_exn.raiseError("graphql_ppx: Field image on type podcasts is missing");
                      }
                      tmp$1 = {
                        description: tmp$2,
                        itunesId: tmp$3,
                        title: tmp$4,
                        publisher: tmp$5,
                        image: tmp$6
                      };
                    } else {
                      tmp$1 = Js_exn.raiseError("graphql_ppx: Object is not a value");
                    }
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field podcast on type my_podcasts is missing");
                  }
                  return {
                          podcastId: tmp,
                          addedDate: match$3 !== undefined ? Caml_option.valFromOption(match$3) : Js_exn.raiseError("graphql_ppx: Field addedDate on type my_podcasts is missing"),
                          podcast: tmp$1
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$1));
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field my_podcasts on type query_root is missing");
    }
    return {
            my_podcasts: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$2(userId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var userId = variables.userId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$2
        };
}

function ret_type$2(f) {
  return /* module */[];
}

var MT_Ret$2 = /* module */[];

var GetAllPodcasts = /* module */[
  /* ppx_printed_query */ppx_printed_query$2,
  /* query */ppx_printed_query$2,
  /* parse */parse$2,
  /* make */make$2,
  /* makeWithVariables */makeWithVariables$2,
  /* ret_type */ret_type$2,
  /* MT_Ret */MT_Ret$2
];

var ppx_printed_query$3 = "query ($userId: String!)  {\nget_my_episodes_grouped_by_podcasts(args: {userid: $userId})  {\ntitle  \nnumberOfEpisodes  \nlastEpisodeAddedDate  \npodcastAddedDate  \nid  \ndescription  \nitunesId  \npublisher  \n}\n\n}\n";

function parse$3(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var match$1 = Js_dict.get(Caml_option.valFromOption(match), "get_my_episodes_grouped_by_podcasts");
    var tmp;
    if (match$1 !== undefined) {
      var value$1 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeArray(value$1);
      tmp = match$2 !== undefined ? match$2.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var value$1 = Caml_option.valFromOption(match);
                  var match$1 = Js_dict.get(value$1, "title");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$2 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$2);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field title on type podcasts_from_my_episodes is missing");
                  }
                  var match$3 = Js_dict.get(value$1, "numberOfEpisodes");
                  var match$4 = Js_dict.get(value$1, "lastEpisodeAddedDate");
                  var tmp$1;
                  if (match$4 !== undefined) {
                    var value$3 = Caml_option.valFromOption(match$4);
                    var match$5 = Js_json.decodeNull(value$3);
                    tmp$1 = match$5 !== undefined ? undefined : Caml_option.some(value$3);
                  } else {
                    tmp$1 = undefined;
                  }
                  var match$6 = Js_dict.get(value$1, "podcastAddedDate");
                  var tmp$2;
                  if (match$6 !== undefined) {
                    var value$4 = Caml_option.valFromOption(match$6);
                    var match$7 = Js_json.decodeNull(value$4);
                    tmp$2 = match$7 !== undefined ? undefined : Caml_option.some(value$4);
                  } else {
                    tmp$2 = undefined;
                  }
                  var match$8 = Js_dict.get(value$1, "id");
                  var tmp$3;
                  if (match$8 !== undefined) {
                    var value$5 = Caml_option.valFromOption(match$8);
                    var match$9 = Js_json.decodeString(value$5);
                    tmp$3 = match$9 !== undefined ? match$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field id on type podcasts_from_my_episodes is missing");
                  }
                  var match$10 = Js_dict.get(value$1, "description");
                  var tmp$4;
                  if (match$10 !== undefined) {
                    var value$6 = Caml_option.valFromOption(match$10);
                    var match$11 = Js_json.decodeString(value$6);
                    tmp$4 = match$11 !== undefined ? match$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$4 = Js_exn.raiseError("graphql_ppx: Field description on type podcasts_from_my_episodes is missing");
                  }
                  var match$12 = Js_dict.get(value$1, "itunesId");
                  var tmp$5;
                  if (match$12 !== undefined) {
                    var value$7 = Caml_option.valFromOption(match$12);
                    var match$13 = Js_json.decodeString(value$7);
                    tmp$5 = match$13 !== undefined ? match$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                  } else {
                    tmp$5 = Js_exn.raiseError("graphql_ppx: Field itunesId on type podcasts_from_my_episodes is missing");
                  }
                  var match$14 = Js_dict.get(value$1, "publisher");
                  var tmp$6;
                  if (match$14 !== undefined) {
                    var value$8 = Caml_option.valFromOption(match$14);
                    var match$15 = Js_json.decodeString(value$8);
                    tmp$6 = match$15 !== undefined ? match$15 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
                  } else {
                    tmp$6 = Js_exn.raiseError("graphql_ppx: Field publisher on type podcasts_from_my_episodes is missing");
                  }
                  return {
                          title: tmp,
                          numberOfEpisodes: match$3 !== undefined ? Caml_option.valFromOption(match$3) : Js_exn.raiseError("graphql_ppx: Field numberOfEpisodes on type podcasts_from_my_episodes is missing"),
                          lastEpisodeAddedDate: tmp$1,
                          podcastAddedDate: tmp$2,
                          id: tmp$3,
                          description: tmp$4,
                          itunesId: tmp$5,
                          publisher: tmp$6
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$1));
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field get_my_episodes_grouped_by_podcasts on type query_root is missing");
    }
    return {
            get_my_episodes_grouped_by_podcasts: tmp
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$3(userId, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var userId = variables.userId;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$3
        };
}

function ret_type$3(f) {
  return /* module */[];
}

var MT_Ret$3 = /* module */[];

var GetMyLibrary = /* module */[
  /* ppx_printed_query */ppx_printed_query$3,
  /* query */ppx_printed_query$3,
  /* parse */parse$3,
  /* make */make$3,
  /* makeWithVariables */makeWithVariables$3,
  /* ret_type */ret_type$3,
  /* MT_Ret */MT_Ret$3
];

var ppx_printed_query$4 = "query ($userId: String!)  {\nmy_episodes(where: {userId: {_eq: $userId}})  {\nepisodeId  \n}\n\nmy_podcasts(where: {userId: {_eq: $userId}})  {\npodcastId  \n}\n\n}\n";

function parse$4(value) {
  var match = Js_json.decodeObject(value);
  if (match !== undefined) {
    var value$1 = Caml_option.valFromOption(match);
    var match$1 = Js_dict.get(value$1, "my_episodes");
    var tmp;
    if (match$1 !== undefined) {
      var value$2 = Caml_option.valFromOption(match$1);
      var match$2 = Js_json.decodeArray(value$2);
      tmp = match$2 !== undefined ? match$2.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var match$1 = Js_dict.get(Caml_option.valFromOption(match), "episodeId");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$1 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$1);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field episodeId on type my_episodes is missing");
                  }
                  return {
                          episodeId: tmp
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$2));
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field my_episodes on type query_root is missing");
    }
    var match$3 = Js_dict.get(value$1, "my_podcasts");
    var tmp$1;
    if (match$3 !== undefined) {
      var value$3 = Caml_option.valFromOption(match$3);
      var match$4 = Js_json.decodeArray(value$3);
      tmp$1 = match$4 !== undefined ? match$4.map((function (value) {
                var match = Js_json.decodeObject(value);
                if (match !== undefined) {
                  var match$1 = Js_dict.get(Caml_option.valFromOption(match), "podcastId");
                  var tmp;
                  if (match$1 !== undefined) {
                    var value$1 = Caml_option.valFromOption(match$1);
                    var match$2 = Js_json.decodeString(value$1);
                    tmp = match$2 !== undefined ? match$2 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$1));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field podcastId on type my_podcasts is missing");
                  }
                  return {
                          podcastId: tmp
                        };
                } else {
                  return Js_exn.raiseError("graphql_ppx: Object is not a value");
                }
              })) : Js_exn.raiseError("graphql_ppx: Expected array, got " + JSON.stringify(value$3));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field my_podcasts on type query_root is missing");
    }
    return {
            my_episodes: tmp,
            my_podcasts: tmp$1
          };
  } else {
    return Js_exn.raiseError("graphql_ppx: Object is not a value");
  }
}

function make$4(userId, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var userId = variables.userId;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray(/* array */[/* tuple */[
                  "userId",
                  userId
                ]]),
          parse: parse$4
        };
}

function ret_type$4(f) {
  return /* module */[];
}

var MT_Ret$4 = /* module */[];

var GetMyLibrarySavedIds = /* module */[
  /* ppx_printed_query */ppx_printed_query$4,
  /* query */ppx_printed_query$4,
  /* parse */parse$4,
  /* make */make$4,
  /* makeWithVariables */makeWithVariables$4,
  /* ret_type */ret_type$4,
  /* MT_Ret */MT_Ret$4
];

export {
  GetMyEpisodes ,
  GetAllEpisodes ,
  GetAllPodcasts ,
  GetMyLibrary ,
  GetMyLibrarySavedIds ,
  
}
/* No side effect */
