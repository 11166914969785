// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE


function fromString(str) {
  if (str === "episode") {
    return /* Episode */0;
  } else {
    return /* Podcast */1;
  }
}

function toString(type_) {
  if (type_) {
    return "podcast";
  } else {
    return "episode";
  }
}

export {
  fromString ,
  toString ,
  
}
/* No side effect */
