// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_AppBar from "@jsiebern/bs-material-ui/src/MaterialUi_AppBar.bs.js";
import * as MaterialUi_Button from "@jsiebern/bs-material-ui/src/MaterialUi_Button.bs.js";
import * as MaterialUi_Toolbar from "@jsiebern/bs-material-ui/src/MaterialUi_Toolbar.bs.js";
import * as Styles from "@material-ui/styles";
import * as Utils$ReactTemplate from "../utils/Utils.bs.js";
import * as MaterialUi_Container from "@jsiebern/bs-material-ui/src/MaterialUi_Container.bs.js";
import * as AppCore$ReactTemplate from "../models/AppCore.bs.js";
import * as MaterialUi_IconButton from "@jsiebern/bs-material-ui/src/MaterialUi_IconButton.bs.js";
import * as Routing$ReactTemplate from "../utils/Routing.bs.js";
import * as IconLink$ReactTemplate from "./IconLink.bs.js";
import * as UserMenu$ReactTemplate from "./identity/UserMenu.bs.js";
import * as RouterLink$ReactTemplate from "./RouterLink.bs.js";
import * as SearchInput$ReactTemplate from "./SearchInput.bs.js";
import * as MicJs from "react-feather/dist/icons/mic.js";
import * as UserJs from "react-feather/dist/icons/user.js";
import * as SearchJs from "react-feather/dist/icons/search.js";
import * as BookmarkJs from "react-feather/dist/icons/bookmark.js";

function isSearchPage(page) {
  if (typeof page === "number" || page.tag !== 1) {
    return false;
  } else {
    return true;
  }
}

function desktopSearchBar(theme) {
  return Css.style(/* :: */[
              Css.display(/* none */-922086728),
              /* :: */[
                Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.flexGrow(1.0),
                        /* :: */[
                          Css.flexShrink(1.0),
                          /* :: */[
                            Css.flexBasis(/* auto */-1065951377),
                            /* :: */[
                              Css.justifyContent(/* center */98248149),
                              /* :: */[
                                Css.alignItems(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]);
}

var appBarSearchInput = Css.style(/* :: */[
      Css.maxWidth(Css.px(400)),
      /* :: */[
        Css.fontSize(Css.rem(1.3)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* :: */[
            Css.left(Css.pct(50.0)),
            /* :: */[
              Css.transform(Css.translateX(Css.pct(-50.0))),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function hideDesktop(theme) {
  return Css.style(/* :: */[
              Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                    Css.display(/* none */-922086728),
                    /* [] */0
                  ]),
              /* [] */0
            ]);
}

var appBarIcons = Css.style(/* :: */[
      Css.marginLeft(/* auto */-1065951377),
      /* [] */0
    ]);

function appName(theme) {
  return Css.style(/* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    400
                  ]),
              /* :: */[
                Css.fontSize(Css.rem(1.5)),
                /* :: */[
                  Css.media(Utils$ReactTemplate.getBreakpoint(/* MD */17239, theme), /* :: */[
                        Css.fontSize(Css.rem(1.75)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.unsafe("color", "inherit"),
                          /* :: */[
                            Css.textDecoration(/* none */-922086728),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function appIcon(theme) {
  return Css.style(/* :: */[
              Css.marginLeft(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
              /* [] */0
            ]);
}

var colorInherit = Css.style(/* :: */[
      Css.unsafe("color", "inherit"),
      /* :: */[
        Css.hover(/* :: */[
              Css.unsafe("color", "inherit"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var searchRoot = Cn.make(/* :: */[
      colorInherit,
      /* :: */[
        Css.style(/* :: */[
              Css.maxWidth(Css.px(350)),
              /* :: */[
                Css.unsafe("color", "inherit"),
                /* :: */[
                  Css.after(/* :: */[
                        Css.unsafe("borderColor", "inherit"),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.before(/* :: */[
                          Css.unsafe("borderColor", "inherit"),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.hover(/* :: */[
                            Css.after(/* :: */[
                                  Css.unsafe("borderColor", "inherit!important"),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.before(/* :: */[
                                    Css.unsafe("borderColor", "inherit!important"),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var searchInput = Cn.make(/* :: */[
      colorInherit,
      /* :: */[
        Css.style(/* :: */[
              Css.unsafe("color", "inherit"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Styles$1 = /* module */[
  /* desktopSearchBar */desktopSearchBar,
  /* appBarSearchInput */appBarSearchInput,
  /* hideDesktop */hideDesktop,
  /* appBarIcons */appBarIcons,
  /* appName */appName,
  /* appIcon */appIcon,
  /* colorInherit */colorInherit,
  /* searchRoot */searchRoot,
  /* searchInput */searchInput
];

function AppBar(Props) {
  var isLoggedIn = Props.isLoggedIn;
  var activePage = Props.activePage;
  var dispatch = Curry._1(AppCore$ReactTemplate.useDispatch, /* () */0);
  var theme = Styles.useTheme();
  var match = React.useState((function () {
          return undefined;
        }));
  var setUserMenuAnchor = match[1];
  React.useEffect((function () {
          Curry._1(setUserMenuAnchor, (function (param) {
                  return undefined;
                }));
          return undefined;
        }), /* array */[isLoggedIn]);
  var match$1 = !isSearchPage(activePage);
  return React.createElement(Core.AppBar, MaterialUi_AppBar.makeProps(Caml_option.some(React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(React.createElement(Core.Toolbar, MaterialUi_Toolbar.makeProps(Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* () */0), React.createElement(RouterLink$ReactTemplate.make, {
                                          href: "/",
                                          className: appName(theme),
                                          variant: "h4",
                                          children: null,
                                          color: /* Inherit */-72987685
                                        }, "Podcast library", React.createElement(MicJs.default, {
                                              className: appIcon(theme)
                                            })), match$1 ? React.createElement("div", {
                                            className: desktopSearchBar(theme)
                                          }, React.createElement(SearchInput$ReactTemplate.make, {
                                                className: Cn.make(/* :: */[
                                                      appBarSearchInput,
                                                      /* :: */[
                                                        colorInherit,
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                iconClassName: colorInherit,
                                                classes: /* :: */[
                                                  /* Input */Block.__(8, [searchInput]),
                                                  /* :: */[
                                                    /* Root */Block.__(0, [searchRoot]),
                                                    /* [] */0
                                                  ]
                                                ]
                                              })) : null, React.createElement("div", {
                                          className: appBarIcons
                                        }, React.createElement(IconLink$ReactTemplate.make, {
                                              className: colorInherit,
                                              route: /* SearchEmpty */1,
                                              text: "Search",
                                              children: React.createElement(SearchJs.default, {
                                                    className: IconLink$ReactTemplate.Styles[/* iconBtn */2](theme)
                                                  })
                                            }), isLoggedIn ? React.createElement(React.Fragment, undefined, React.createElement(IconLink$ReactTemplate.make, {
                                                    className: colorInherit,
                                                    route: /* MyLibrary */0,
                                                    text: "Library",
                                                    children: React.createElement(BookmarkJs.default, {
                                                          className: IconLink$ReactTemplate.Styles[/* iconBtn */2](theme)
                                                        })
                                                  }), React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(UserJs.default, { })), undefined, /* Inherit */-72987685, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                                          var anchor = e.currentTarget;
                                                          return Curry._1(setUserMenuAnchor, (function (param) {
                                                                        return Caml_option.some(anchor);
                                                                      }));
                                                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))) : React.createElement(Core.Button, MaterialUi_Button.makeProps("Log in", colorInherit, /* Inherit */-72987685, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                      return Curry._1(dispatch, /* SetShowIdentityModal */Block.__(2, [true]));
                                                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)), React.createElement(UserMenu$ReactTemplate.make, {
                                              anchorEl: match[0],
                                              onLogout: (function (param) {
                                                  return Routing$ReactTemplate.pushRoute(/* SearchEmpty */1);
                                                }),
                                              onClose: (function (param, param$1) {
                                                  return Curry._1(setUserMenuAnchor, (function (param) {
                                                                return undefined;
                                                              }));
                                                })
                                            })))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), undefined, undefined, /* Static */982536398, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var make = AppBar;

export {
  isSearchPage ,
  Styles$1 as Styles,
  make ,
  
}
/* appBarSearchInput Not a pure module */
