// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as MaterialUi_Typography from "@jsiebern/bs-material-ui/src/MaterialUi_Typography.bs.js";

function PageTitle(Props) {
  var title = Props.title;
  return React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(title), Css.style(/* :: */[
                      Css.fontWeight(/* `num */[
                            5496390,
                            100
                          ]),
                      /* :: */[
                        Css.textAlign(Css.center),
                        /* [] */0
                      ]
                    ]), undefined, /* `String */[
                  -976970511,
                  "h1"
                ], undefined, true, undefined, undefined, /* H3 */16107, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var make = PageTitle;

export {
  make ,
  
}
/* Css Not a pure module */
