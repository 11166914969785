// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Utils$ReactTemplate from "../../utils/Utils.bs.js";

function submitButton(theme) {
  return Css.style(/* :: */[
              Css.marginTop(Css.px(Utils$ReactTemplate.spacingPx(1, theme))),
              /* [] */0
            ]);
}

function formElement(theme) {
  return Css.style(/* :: */[
              Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(2, theme))),
              /* [] */0
            ]);
}

var divider = Css.style(/* :: */[
      Css.flexGrow(1.0),
      /* :: */[
        Css.flexShrink(1.0),
        /* :: */[
          Css.flexBasis(/* auto */-1065951377),
          /* [] */0
        ]
      ]
    ]);

function providerSection(theme) {
  return Css.style(/* :: */[
              Css.marginTop(Css.px(Utils$ReactTemplate.spacingPx(3, theme))),
              /* :: */[
                Css.marginBottom(Css.px(Utils$ReactTemplate.spacingPx(3, theme))),
                /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var Styles = /* module */[
  /* submitButton */submitButton,
  /* formElement */formElement,
  /* divider */divider,
  /* providerSection */providerSection
];

export {
  Styles ,
  
}
/* divider Not a pure module */
