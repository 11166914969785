// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactNetlifyIdentity from "bs-react-netlify-identity/src/ReactNetlifyIdentity.bs.js";

function convertFromJs(jsObj) {
  return /* record */[/* fullName */jsObj.full_name];
}

function convertToJs(obj) {
  return {
          full_name: obj[/* fullName */0]
        };
}

var Context = ReactNetlifyIdentity.Make(/* module */[
      /* convertToJs */convertToJs,
      /* convertFromJs */convertFromJs
    ]);

function getLoggedInUser(identity) {
  if (identity[/* isLoggedIn */1]) {
    return Belt_Option.mapWithDefault(identity[/* user */0], /* Anonymous */0, (function (user) {
                  return /* LoggedIn */[user[/* id */2]];
                }));
  } else {
    return /* Anonymous */0;
  }
}

export {
  Context ,
  getLoggedInUser ,
  
}
/* Context Not a pure module */
