// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as MaterialUi_FormControl from "@jsiebern/bs-material-ui/src/MaterialUi_FormControl.bs.js";
import * as MaterialUi_FormHelperText from "@jsiebern/bs-material-ui/src/MaterialUi_FormHelperText.bs.js";
import * as IdentityDialogStyles$ReactTemplate from "./IdentityDialogStyles.bs.js";

function ErrorMessage(Props) {
  var message = Props.message;
  var theme = Styles.useTheme();
  return React.createElement(Core.FormControl, MaterialUi_FormControl.makeProps(Caml_option.some(React.createElement(Core.FormHelperText, MaterialUi_FormHelperText.makeProps(Caml_option.some(message.replace("invalid_grant:", "")), Css.style(/* :: */[
                                  Css.textAlign(/* center */98248149),
                                  /* :: */[
                                    Css.fontSize(Css.px(16)),
                                    /* [] */0
                                  ]
                                ]), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0))), IdentityDialogStyles$ReactTemplate.Styles[/* formElement */1](theme), undefined, undefined, true, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
}

var make = ErrorMessage;

export {
  make ,
  
}
/* Css Not a pure module */
